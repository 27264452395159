import {
  Card,
  CardHeader,
  Typography,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import React from 'react';
import { User } from 'src/@types/user';

interface UserIdentityCardProps {
  user: User;
}

const UserIdentityCard: React.FunctionComponent<UserIdentityCardProps> = ({ user }) => {
  return (
    <Card>
      <CardHeader title={<Typography variant="h6">Identity</Typography>} />
      <Divider />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell className="font-medium capitalize">Adhaar card</TableCell>
            <TableCell>{user?.identity?.adhaarcard}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="font-medium capitalize">Pancard</TableCell>
            <TableCell className="capitalize">{user?.identity?.pancard}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Card>
  );
};

export default UserIdentityCard;
