import { Box, Typography, Grid } from '@material-ui/core';
import toast from "react-hot-toast";
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { User } from 'src/@types/user';
import Breadcrumbs, { BreadcrumbsLink } from 'src/components/common/Breadcrumbs/Breadcrumbs';
import MainLayout from 'src/components/MainLayout';
import UserForm from 'src/containers/UserForm';
import { Gender } from 'src/enums/gender';
import withAuth from 'src/hocs/withAuth';
import UserService from 'src/services/user.service';

const links: BreadcrumbsLink[] = [
  {
    label: 'Dashboard',
    href: '/dashboard',
  },
  {
    label: 'Management',
    href: '/management/users',
  },
  {
    label: 'User',
    href: '/management/users',
  },
];

const CreateUser = () => {
  const history = useHistory();
  const [user] = useState<User>({
    id: '',
    firstName: '',
    mobile: '',
    dob: new Date(),
    gender: Gender.MALE,
    roles: [],
    address: { state: '', city: '', pincode: '', addressLine: '' },
    identity: { adhaarcard: '', pancard: '' },
  });

  const handleSave = async (data: User) => {
    const { user, error } = await UserService.createUser(data);
    if (error) {
      toast.error(error);
    } else if (user) {
      history.push(`/management/users/${user.id}`);
    }
  };

  return (
    <MainLayout>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={8}>
          <Breadcrumbs links={links} />
          <Typography variant="h5" color="textPrimary" className="font-bold mt-2">
            Create User
          </Typography>
          <Box marginTop={2}>
            <UserForm mode="create" user={user} onSave={handleSave} />
          </Box>
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default withAuth(CreateUser, '/login');
