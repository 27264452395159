import Joi from "joi";

export const campaignSchema = Joi.object({
  name: Joi.string().required().messages({
    "string.empty": "Name is required",
  }),
  description: Joi.string().allow("", null),
  brand: Joi.object({
    id: Joi.string()
      .required()
      .messages({ "string.empty": "Please select brand" }),
  })
    .unknown()
    .messages({ "object.base": "Please select brand" }),

  manager: Joi.object({
    id: Joi.string()
      .required()
      .messages({ "string.empty": "Please select manager" }),
  })
    .unknown()
    .messages({ "object.base": "Please select manager" }),
  file: Joi.object().instance(File).allow(null).messages({
    "object.base": "Please select file",
    "object.instance": "Please select file",
  }),
}).unknown();
