import { useEffect, useState } from "react";
import { CampaignBrand } from "src/@types";
import BrandService, { GetAllBrandOptions } from "src/services/brand.service";

export interface UseCampaignBrandOptions extends GetAllBrandOptions {}

export function useCampaignBrands(options: UseCampaignBrandOptions) {
  const [loading, setLoading] = useState(true);
  const [brands, setBrands] = useState<CampaignBrand[]>([]);
  const [count, setCount] = useState(0);

  useEffect(() => {
    const getAllCampaignBrand = async () => {
      setLoading(true);
      const { brands, count } = await BrandService.getAllCampaignBrand(options);
      setLoading(false);

      if (brands) {
        setBrands(brands);
        setCount(count ?? 0);
      }
    };

    getAllCampaignBrand();
  }, [options]);

  return { loading, brands, count };
}
