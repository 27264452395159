import axios from "axios";

const API = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  // withCredentials: true,
});

API.interceptors.request.use((config) => {
  const accessToken = localStorage.getItem("ACCESS_TOKEN");
  if (accessToken) {
    config.headers["authorization"] = `Bearer ${accessToken}`;
  }

  return config;
});

export default API;
