import {
  Card,
  CardHeader,
  Typography,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Link,
} from "@material-ui/core";
import React from "react";
import { Activity } from "src/@types";
import Map from "src/components/Map";
import { createGoogleMapLink } from "src/helpers/map";

interface LocationProps {
  activity: Activity;
}
const Location: React.FunctionComponent<LocationProps> = ({ activity }) => {
  return (
    <Card>
      <CardHeader
        title={<Typography variant="h6">Location</Typography>}
        action={
          activity.outlet.lat &&
          activity.outlet.lng && (
            <Link
              href={createGoogleMapLink(
                activity.outlet.lat,
                activity.outlet.lng
              )}
              rel="noopener noreferrer"
              target="_blank"
            >
              Open in Google Map
            </Link>
          )
        }
        classes={{
          action: "m-0 self-center",
        }}
      />
      <Divider />
      {activity.outlet.lat && activity.outlet.lng && (
        <Map lat={activity.outlet.lat} lng={activity.outlet.lng} />
      )}

      <Table>
        <TableBody>
          <TableRow>
            <TableCell className="font-medium capitalize">Town</TableCell>
            <TableCell>
              <Typography className="capitalize">
                {activity.outlet?.town}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="font-medium capitalize">State</TableCell>
            <TableCell>
              <Typography className="capitalize">
                {activity.outlet?.state}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="font-medium capitalize">Pincode</TableCell>
            <TableCell>
              <Typography className="capitalize">
                {activity.outlet?.pincode}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="font-medium capitalize">
              Full address
            </TableCell>
            <TableCell>
              <Typography className="capitalize">
                {activity.outlet?.fullAddress}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="font-medium capitalize">Landmark</TableCell>
            <TableCell>
              <Typography className="capitalize">
                {activity.outlet?.landmark}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Card>
  );
};

export default Location;
