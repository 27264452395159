import {
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
} from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { ActivityOutlet } from 'src/@types';
// import DisplayDate from 'src/components/DisplayDate';
// import { formatDateTime } from 'src/util/datetime';

export interface OutletDetailProps {
  outlet: ActivityOutlet;
}

const OutletDetail: FunctionComponent<OutletDetailProps> = ({ outlet }) => {
  return (
    <Card>
      <CardHeader title="Outlet info" />
      <Divider />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell className="font-medium capitalize">Outlet Name</TableCell>
            <TableCell>{outlet.outletName}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="font-medium capitalize">Retailer Name</TableCell>
            <TableCell>{outlet.retailerName}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="font-medium capitalize">Mobile</TableCell>
            <TableCell>{outlet.mobile}</TableCell>
          </TableRow>
          {/* <TableRow>
            <TableCell className="font-medium capitalize">
              Outlet type
            </TableCell>
            <TableCell>{outlet.outletType?.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="font-medium capitalize">
              Registered by
            </TableCell>
            <TableCell>{outlet.registeredBy}</TableCell>
          </TableRow> */}
          {/* <TableRow>
            <TableCell className="font-medium capitalize">
              Date of Registration
            </TableCell>
            <TableCell className="capitalize cursor-pointer">
              {outlet.registrationDate && (
                <Tooltip
                  title={formatDateTime(outlet.registrationDate)}
                  placement="top"
                >
                  <span>
                    <DisplayDate date={outlet.registrationDate} />
                  </span>
                </Tooltip>
              )}
            </TableCell>
          </TableRow> */}
          <TableRow>
            <TableCell className="font-medium capitalize">Retailer Alternate mobile</TableCell>
            <TableCell>{outlet.alternateMobile}</TableCell>
          </TableRow>
          {/* <TableRow>
            <TableCell className="font-medium capitalize">
              Whether mechanic
            </TableCell>
            <TableCell>{outlet.isMechanic ? "Yes" : "No"}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="font-medium capitalize">
              Smartphone present
            </TableCell>
            <TableCell>{outlet.hasSmartPhone ? "Yes" : "No"}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="font-medium capitalize">
              Retailer using mobile app
            </TableCell>
            <TableCell>{outlet.hasMobileApp ? "Yes" : "No"}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="font-medium capitalize">
              Board Available
            </TableCell>
            <TableCell>{outlet.isBoardAvailable ? "Yes" : "No"}</TableCell>
          </TableRow> */}
          {/* <TableRow>
            <TableCell className="font-medium capitalize">Last login</TableCell>
            <TableCell className="capitalize cursor-pointer">
              {outlet.lastLogin && (
                <Tooltip
                  title={formatDateTime(outlet.lastLogin)}
                  placement="top"
                >
                  <span>
                    <DisplayDate date={outlet.lastLogin} />
                  </span>
                </Tooltip>
              )}
            </TableCell>
          </TableRow> */}
        </TableBody>
      </Table>
    </Card>
  );
};

export default OutletDetail;
