import { MaterialType } from "src/@types";
import API from "src/helpers/api";
import { getErrorMessage } from "src/helpers/axios";
import qs from "qs";
import { QueryFilterType } from "src/@types";

interface GeAllMaterialTypeOptions {
  page?: number;
  size?: number;
  query?: string;
  filterType: QueryFilterType;
}

interface GetAllMaterialTypeResult {
  materialTypes?: MaterialType[];
  count?: number;
  error?: string;
}

interface GetAllMaterialTypeResponse {
  materialTypes: MaterialType[];
  count: number;
}

const getAllMaterialType = async ({
  page,
  size,
  query,
  filterType,
}: GeAllMaterialTypeOptions) => {
  const result: GetAllMaterialTypeResult = {};
  try {
    const queryString = qs.stringify({ page, size, query, filterType });
    const {
      data: { materialTypes, count },
    } = await API.get<GetAllMaterialTypeResponse>(
      `/material-types?${queryString}`,
      {}
    );
    result.materialTypes = materialTypes;
    result.count = count;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface GetMaterialTypeByIdResult {
  materialType?: MaterialType;
  error?: string;
}

interface GetMaterialTypeByIdResponse {
  materialType: MaterialType;
}

const getMaterialTypeById = async (materialTypeId: string) => {
  const result: GetMaterialTypeByIdResult = {};
  try {
    const {
      data: { materialType },
    } = await API.get<GetMaterialTypeByIdResponse>(
      `material-types/${materialTypeId}`
    );
    result.materialType = materialType;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface CreateMaterialTypeResponse {
  materialType: MaterialType;
  message: string;
}
interface CreateMaterialTypeResult {
  materialType?: MaterialType;
  message?: string;
  error?: string;
}

const createMaterialType = async (materialTypeData: MaterialType) => {
  const result: CreateMaterialTypeResult = {};
  try {
    const {
      data: { materialType, message },
    } = await API.post<CreateMaterialTypeResponse>(
      "material-types",
      materialTypeData
    );
    result.materialType = materialType;
    result.message = message;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface UpdateMaterialTypeResponse {
  materialType: MaterialType;
  message: string;
}

interface UpdateMaterialTypeResult {
  materialType?: MaterialType;
  message?: string;
  error?: string;
}

const updateMaterialType = async (materialTypeData: MaterialType) => {
  const result: UpdateMaterialTypeResult = {};
  try {
    // const materialTypeFormData: MaterialTypeFormData = transformToMaterialTypeFormData(materialTypeData);
    const {
      data: { materialType, message },
    } = await API.patch<UpdateMaterialTypeResponse>(
      `material-types/${materialTypeData.id}`,
      materialTypeData
    );
    result.materialType = materialType;
    result.message = message;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface DeleteMaterialTypeResult {
  message?: string;
  error?: string;
}
interface DeleteMaterialTypeResponse {
  message: string;
}

const deleteMaterialType = async (
  id: string
): Promise<DeleteMaterialTypeResult> => {
  const result: DeleteMaterialTypeResult = {};
  try {
    const {
      data: { message },
    } = await API.delete<DeleteMaterialTypeResponse>(
      `material-types/${id}`
    );
    result.message = message;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface DeactivateMaterialTypeResult {
  message?: string;
  error?: string;
}
interface DeactivateMaterialTypeResponse {
  message: string;
}

const deactivateMaterialType = async (
  id: string
): Promise<DeactivateMaterialTypeResult> => {
  const result: DeleteMaterialTypeResult = {};
  try {
    const {
      data: { message },
    } = await API.put<DeactivateMaterialTypeResponse>(
      `material-types/${id}/deactivate`
    );
    result.message = message;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface ActivateMaterialTypeResult {
  message?: string;
  error?: string;
}
interface ActivateMaterialTypeResponse {
  message: string;
}
const activateMaterialType = async (
  id: string
): Promise<ActivateMaterialTypeResult> => {
  const result: DeleteMaterialTypeResult = {};
  try {
    const {
      data: { message },
    } = await API.put<ActivateMaterialTypeResponse>(
      `material-types/${id}/activate`
    );
    result.message = message;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

const MaterialTypeService = {
  getAllMaterialType,
  getMaterialTypeById,
  createMaterialType,
  updateMaterialType,
  deleteMaterialType,
  deactivateMaterialType,
  activateMaterialType,
};

export default MaterialTypeService;
