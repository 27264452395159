import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@material-ui/core";
import React from "react";
import DialogTitle from "src/components/common/Dialog/DialogTitle";

interface DeleteActivityDialogProps {
  open: boolean;
  onClose?: () => void;
  onDelete: () => void;
}

const DeleteActivityDialog: React.FC<DeleteActivityDialogProps> = ({
  open,
  onClose,
  onDelete,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      PaperProps={{
        className: "w-4/12",
      }}
    >
      <DialogTitle title="Delete branding activity" onClose={onClose} />
      <DialogContent>
        <Typography>
          Are you sure you want to delete the activity? The changes can't be
          reverted back!!
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          Cancel
        </Button>
        <Button color="secondary" onClick={onDelete}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteActivityDialog;
