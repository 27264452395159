import toast from "react-hot-toast";
import React, { useCallback, useEffect, useState } from "react";
import { OutletType } from "src/@types";
import OutletTypeService from "src/services/outlet-type.service";
import OutletTypeFormDialog from "./OutletTypeFormDialog";

interface EditOutletTypeDialogProps {
  open: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
  outletTypeId: string;
}

const EditOutletTypeDialog: React.FC<EditOutletTypeDialogProps> = ({
  open,
  onClose,
  onSuccess,
  outletTypeId,
}) => {
  const [outletType, setOutletType] = useState<OutletType | null>(null);

  const handleSave = useCallback(async (data: OutletType) => {
    const { message, error } = await OutletTypeService.updateOutletType({
      ...data,
      id: outletTypeId,
    });
    if (error) {
      toast.error(error);
    } else if (message) {
      toast.success(message);
      onSuccess?.();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const getOutletTypeById = async () => {
      const { outletType } = await OutletTypeService.getOutletTypeById(
        outletTypeId
      );
      if (outletType) {
        setOutletType(outletType);
      }
    };
    getOutletTypeById();
    // eslint-disable-next-line
  }, [outletTypeId]);

  return (
    <>
      {outletType && (
        <OutletTypeFormDialog
          mode="edit"
          outletType={outletType}
          open={open}
          onClose={onClose}
          onSave={handleSave}
        />
      )}
    </>
  );
};

export default EditOutletTypeDialog;
