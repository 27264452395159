import { joiResolver } from "@hookform/resolvers/joi";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useForm, Controller, FieldError } from "react-hook-form";
import { Role, User } from "src/@types/user";
import AutocompleteController from "src/components/common/AutocompleteController";
import DatePickerController from "src/components/common/DatePickerController";
import { Gender } from "src/enums/gender";
import RoleService from "src/services/role.service";
import userSchema from "src/validation/user.schema";

interface UserFormProps {
  mode: "create" | "edit";
  user: User;
  onSave?: (user: User) => void;
}

const UserForm: React.FC<UserFormProps> = ({ mode, user, onSave }) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<User>({
    defaultValues: user,
    mode: "onSubmit",
    resolver: joiResolver(userSchema),
  });
  const [roles, setRoles] = useState<Role[]>([]);

  const onSubmit = (data: User) => {
    onSave?.(data);
  };

  const getAllRole = async () => {
    const { roles } = await RoleService.getAllRole();
    if (roles) {
      setRoles(roles);
    }
  };

  useEffect(() => {
    getAllRole();
  }, []);


  return (
    <Card>
      <CardContent>
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <Box marginBottom={2}>
            <Typography variant="h6">Basic details</Typography>
          </Box>
          <Box marginBottom={4}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Controller
                  control={control}
                  name="firstName"
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      label="Name"
                      className="w-full"
                      id="firstName"
                      error={!!errors.firstName}
                      helperText={errors.firstName?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Controller
                  control={control}
                  name="mobile"
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      label="Mobile"
                      className="w-full"
                      id="mobile"
                      error={!!errors.mobile}
                      helperText={errors.mobile?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Controller
                  control={control}
                  name="email"
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      label="Email"
                      className="w-full"
                      id="email"
                      error={!!errors.email}
                      helperText={errors.email?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <FormControl
                  variant="outlined"
                  className="w-full"
                  error={!!errors?.gender}
                >
                  <InputLabel id="gender-label">Gender</InputLabel>
                  <Controller
                    control={control}
                    defaultValue={user.gender}
                    name="gender"
                    render={() => (
                      <Select labelId="gender-label" label="Gender" id="gender">
                        <MenuItem value={Gender.MALE}>Male</MenuItem>
                        <MenuItem value={Gender.FEMALE}>Female</MenuItem>
                        <MenuItem value={Gender.OTHERS}>Others</MenuItem>
                      </Select>
                    )}
                  />
                  <FormHelperText>{errors?.gender?.message}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <DatePickerController
                  label="Date of birth"
                  className="w-full m-0"
                  error={!!errors?.dob}
                  helperText={errors?.dob?.message}
                  control={control}
                  id="dob"
                  name="dob"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <AutocompleteController
                  options={roles}
                  getOptionLabel={(role) => role.name}
                  getOptionSelected={(option, value) =>
                    option.id === value.id
                  }
                  label="Roles"
                  placeholder="Roles"
                  className="w-full"
                  control={control}
                  error={!!errors.roles}
                  helperText={
                    (errors?.roles as FieldError | undefined)?.message
                  }
                  name="roles"
                  id="roles"
                  defaultValue={user.roles}
                  multiple
                />
              </Grid>
            </Grid>
          </Box>
          <Divider />
          <Box marginY={2}>
            <Typography variant="h6">Address</Typography>
          </Box>
          <Box marginBottom={4}>
            <Grid container spacing={3}>
              {/* <Grid item sm={12}>
                <TextField
                  multiline
                  variant="outlined"
                  label="Address Line"
                  className="w-full"
                  id="addressLine"
                  name="address.addressLine"
                  error={!!errors.address?.addressLine}
                  helperText={errors.address?.addressLine?.message}
                  inputRef={register}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <TextField
                  variant="outlined"
                  label="City"
                  className="w-full"
                  id="city"
                  name="address.city"
                  error={!!errors.address?.city}
                  helperText={errors.address?.city?.message}
                  inputRef={register}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <TextField
                  variant="outlined"
                  label="State"
                  className="w-full"
                  id="state"
                  name="address.state"
                  error={!!errors.address?.state}
                  helperText={errors.address?.state?.message}
                  inputRef={register}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={4}>
                <TextField
                  variant="outlined"
                  label="Pincode"
                  className="w-full"
                  id="pincode"
                  name="address.pincode"
                  error={!!errors.address?.pincode}
                  helperText={errors.address?.pincode?.message}
                  inputRef={register}
                />
              </Grid> */}
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Controller
                  control={control}
                  name="address.agencyName"
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      label="Agency Name"
                      className="w-full"
                      id="agencyName"
                      error={!!errors.address?.agencyName}
                      helperText={errors.address?.agencyName?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Controller
                  control={control}
                  name="address.town"
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      label="Town"
                      className="w-full"
                      id="town"
                      error={!!errors.address?.town}
                      helperText={errors.address?.town?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
          <Divider />
          <Box marginY={2}>
            <Typography variant="h6">Identity</Typography>
          </Box>
          <Box marginBottom={4}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Controller
                  control={control}
                  name="identity.adhaarcard"
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      label="Adhaarcard"
                      className="w-full"
                      id="adhaarcard"
                      error={!!errors.identity?.adhaarcard}
                      helperText={errors.identity?.adhaarcard?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Controller
                  control={control}
                  name="identity.pancard"
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      label="Pancard"
                      className="w-full"
                      id="pancard"
                      error={!!errors.identity?.pancard}
                      helperText={errors.identity?.pancard?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
          <Grid container>
            <Button
              variant="contained"
              color="primary"
              size="large"
              disableElevation
              className="mb-5"
              type="submit"
              // disabled={!isValid}
            >
              {mode === "create" ? "Create user" : "Update user"}
            </Button>
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
};

export default UserForm;
