import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextField,
} from "@material-ui/core";
import toast from "react-hot-toast";
import React, { FunctionComponent, useEffect } from "react";
import { useState } from "react";
import { ActivityBoard, Attachment, MaterialType } from "src/@types";
import AttachmentCard from "src/components/AttachmentCard";
import FileUploadButton from "src/components/common/FileUploadButton";
import { MB_SIZE } from "src/config/file.constants";
import { createAttachment } from "src/helpers/attachment";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { boardSchema } from "src/validation/board.schema";
import { useFormValidation } from "src/hooks/useFormValidation";
import styled from "styled-components";
import { ActivityBoardStatusEnum } from "src/enums/activity-board-status.enum";
import CanView from "src/components/RoleBasedAccess/CanView";
import { ROLE } from "src/enums/role";

const StyledTableRow = styled(TableRow)`
  .width-text-box,
  .height-text-box,
  .quantity-text-box {
    min-width: 80px;
    max-width: 100px;
  }
`;
export interface EditableBoardRowProps {
  mode?: "create" | "edit" | "view";
  onSave?: (board: ActivityBoard) => void;
  defaultBoardValue: ActivityBoard;
  onEdit?: () => void;
  onDelete?: () => void;
  materials: MaterialType[];
}

const EditableBoardRow: FunctionComponent<EditableBoardRowProps> = ({
  mode,
  defaultBoardValue,
  onSave,
  onEdit,
  onDelete,
  materials,
}) => {
  
  const [board, setBoard] = useState<ActivityBoard>({
    ...defaultBoardValue,
  });
  const { errors, validate, clearErrors } = useFormValidation({
    schema: boardSchema,
    data: board,
  });

  const isEditing = mode === "edit" || mode === "create";

  const removeReceePhoto = (index: number) => {
    setBoard((board) => {
      const deletedPhotos = board.receePhotos.splice(index, 1);
      return {
        ...board,
        receePhotos: [...board.receePhotos],
        deletedReceePhotos: [...board.deletedReceePhotos, ...deletedPhotos],
      };
    });
  };

  const addNewReceePhoto = (attachment: Attachment) => {
    setBoard((board) => ({
      ...board,
      newReceePhotos: [...board.newReceePhotos, attachment],
    }));
  };

  const removeNewReceePhoto = (index: number) => {
    setBoard((board) => {
      board.newReceePhotos.splice(index, 1);
      return {
        ...board,
        newReceePhotos: [...board.newReceePhotos],
      };
    });
  };

  const removeInstallationPhoto = (index: number) => {
    setBoard((board) => {
      const deletedPhotos = board.installationPhotos.splice(index, 1);
      return {
        ...board,
        installationPhotos: [...board.installationPhotos],
        deletedInstallationPhotos: [
          ...board.deletedInstallationPhotos,
          ...deletedPhotos,
        ],
      };
    });
  };

  const addNewInstallationPhoto = (attachment: Attachment) => {
    setBoard((board) => ({
      ...board,
      newInstallationPhotos: [...board.newInstallationPhotos, attachment],
    }));
  };

  const removeNewInstallationPhoto = (index: number) => {
    setBoard((board) => {
      board.newInstallationPhotos.splice(index, 1);
      return {
        ...board,
        newInstallationPhotos: [...board.newInstallationPhotos],
      };
    });
  };

  const handleReceePhotoSelect = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      if (file.size > MB_SIZE) {
        toast.error("File size is too large");
      } else {
        const attachment = await createAttachment(file);
        addNewReceePhoto(attachment);
      }
    }
  };

  const handleInstallationPhotoSelect = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      if (file.size > MB_SIZE) {
        toast.error("File size is too large");
      } else {
        const attachment = await createAttachment(file);
        addNewInstallationPhoto(attachment);
      }
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type } = event.target;

    setBoard((board) => ({
      ...board,
      [name]: type === "number" ? +value : value,
    }));
  };

  const handleSelectChange = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    const { name, value } = event.target;
    if (name && value) {
      setBoard((board) => ({
        ...board,
        materialTypeId: value as string,
        materialType:
          materials.find((material) => material.id === value) ?? null,
      }));
    }
  };

  const handleSelectStatusChange = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    const { name, value } = event.target;
    if (name && value) {
      setBoard((board) => ({
        ...board,
        status: value as ActivityBoardStatusEnum,
      }));
    }
  };

  const handleSubmit = () => {
    const isValid = validate();
    if (isValid) {
      onSave?.(board);
      clearErrors();
    }
  };

  useEffect(() => {
    setBoard({
      ...defaultBoardValue,
      newReceePhotos: defaultBoardValue.newReceePhotos ?? [],
      deletedReceePhotos: defaultBoardValue.deletedReceePhotos ?? [],
      newInstallationPhotos: defaultBoardValue.newInstallationPhotos ?? [],
      deletedInstallationPhotos:
        defaultBoardValue.deletedInstallationPhotos ?? [],
    });
  }, [defaultBoardValue]);

  if (!isEditing) {
    return (
      <TableRow>
        <TableCell>{board.materialType?.name}</TableCell>
        <TableCell>{board.width}</TableCell>
        <TableCell>{board.height}</TableCell>
        <TableCell>{board.quantity}</TableCell>
        <TableCell>{board.remark}</TableCell>
        <TableCell>
          <Grid container spacing={3} className="mb-3">
            {board.receePhotos?.map((photo, index) => (
              <Grid item key={photo.id}>
                <AttachmentCard attachment={photo} size="small" />
              </Grid>
            ))}
            {board.newReceePhotos?.map((photo, index) => (
              <Grid item key={photo.id}>
                <AttachmentCard attachment={photo} size="small" />
              </Grid>
            ))}
          </Grid>
        </TableCell>
        <TableCell>
          <Grid container spacing={3} className="mb-3">
            {board.installationPhotos?.map((photo, index) => (
              <Grid item key={photo.id}>
                <AttachmentCard attachment={photo} size="small" />
              </Grid>
            ))}
            {board.newInstallationPhotos?.map((photo, index) => (
              <Grid item key={photo.id}>
                <AttachmentCard attachment={photo} size="small" />
              </Grid>
            ))}
          </Grid>
        </TableCell>
        <CanView roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER]}>
          <TableCell className="capitalize">{board.status}</TableCell>
          <TableCell>
            <div className="flex justify-center">
              <IconButton onClick={onEdit}>
                <EditIcon />
              </IconButton>
              <IconButton onClick={onDelete}>
                <DeleteIcon />
              </IconButton>
            </div>
          </TableCell>
        </CanView>
      </TableRow>
    );
  }

  return (
    <StyledTableRow>
      <TableCell>
        <Select
          name="materialTypeId"
          value={board.materialTypeId}
          onChange={handleSelectChange}
          variant="outlined"
          inputProps={{
            className: "py-3",
          }}
          className="w-40"
        >
          {materials.map((material) => (
            <MenuItem value={material.id} key={material.id}>
              {material.name}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText error={!!errors.materialTypeId}>
          {errors.materialTypeId?.message}
        </FormHelperText>
      </TableCell>
      <TableCell>
        <TextField
          variant="outlined"
          name="width"
          className="w-full width-text-box"
          id="width"
          size="small"
          InputProps={{
            type: "number",
            inputProps: { min: 1.0, max: 100.0, step: 0.1 },
          }}
          value={board.width}
          onChange={handleInputChange}
          error={!!errors.width}
          helperText={errors.width?.message}
        />
      </TableCell>
      <TableCell>
        <TextField
          variant="outlined"
          name="height"
          // label="Height"
          className="w-full height-text-box"
          id="height"
          size="small"
          InputProps={{
            type: "number",
            inputProps: { min: 1.0, max: 100.0, step: 0.1 },
          }}
          value={board.height}
          onChange={handleInputChange}
          error={!!errors.height}
          helperText={errors.height?.message}
        />
      </TableCell>
      <TableCell>
        <TextField
          variant="outlined"
          name="quantity"
          className="w-full quantity-text-box"
          id="quantity"
          size="small"
          InputProps={{
            type: "number",
            inputProps: { min: 1, max: 100, step: 1 },
          }}
          value={board.quantity}
          onChange={handleInputChange}
          error={!!errors.quantity}
          helperText={errors.quantity?.message}
        />
      </TableCell>
      <TableCell>
        <TextField
          variant="outlined"
          name="remark"
          // label="Remark"
          className="w-full"
          id="remark"
          size="small"
          value={board.remark}
          onChange={handleInputChange}
          error={!!errors.remark}
          helperText={errors.remark?.message}
        />
      </TableCell>

      <TableCell>
        <Grid container spacing={3} className="mb-3">
          {board.receePhotos.map((receePhoto, index) => (
            <Grid item key={receePhoto.id}>
              <AttachmentCard
                attachment={receePhoto}
                onDelete={() => removeReceePhoto(index)}
                size="small"
                showDelete
              />
            </Grid>
          ))}
          {board.newReceePhotos.map((newReceePhoto, index) => (
            <Grid item key={newReceePhoto.id}>
              <AttachmentCard
                attachment={newReceePhoto}
                onDelete={() => removeNewReceePhoto(index)}
                size="small"
                showDelete
              />
            </Grid>
          ))}
          {board.receePhotos.length + board.newReceePhotos.length < 2 && (
            <Grid item>
              <FileUploadButton
                variant="outlined"
                onChange={handleReceePhotoSelect}
                accept="image/png,image/jpeg"
                size="small"
                id="recee-file"
              >
                <AddIcon />
              </FileUploadButton>
            </Grid>
          )}
        </Grid>
        <FormHelperText error={!!errors.receePhotos}>
          {errors.receePhotos?.message}
        </FormHelperText>
      </TableCell>
      <TableCell>
        <Grid container spacing={3} className="mb-3">
          {board.installationPhotos.map((photo, index) => (
            <Grid item key={photo.id}>
              <AttachmentCard
                attachment={photo}
                onDelete={() => removeInstallationPhoto(index)}
                size="small"
                showDelete
              />
            </Grid>
          ))}
          {board.newInstallationPhotos.map((photo, index) => (
            <Grid item key={photo.id}>
              <AttachmentCard
                attachment={photo}
                onDelete={() => removeNewInstallationPhoto(index)}
                size="small"
                showDelete
              />
            </Grid>
          ))}
          {board.installationPhotos.length +
            board.newInstallationPhotos.length <
            2 && (
            <Grid item>
              <FileUploadButton
                variant="outlined"
                onChange={handleInstallationPhotoSelect}
                accept="image/png,image/jpeg"
                size="small"
                id="installation-file"
              >
                <AddIcon />
              </FileUploadButton>
            </Grid>
          )}
        </Grid>
        <FormHelperText error={!!errors.installationPhotos}>
          {errors.installationPhotos?.message}
        </FormHelperText>
      </TableCell>
      <TableCell>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <FormControl
            variant="outlined"
            className="w-full"
            error={!!errors?.status}
          >
            <InputLabel id="status-label">Status</InputLabel>
            <Select
              labelId="status-label"
              label="Status"
              id="status"
              onChange={handleSelectStatusChange}
              name="status"
              value={board.status}
              className="w-32"
            >
              <MenuItem value={ActivityBoardStatusEnum.PENDING}>
                Pending
              </MenuItem>
              <MenuItem value={ActivityBoardStatusEnum.APPROVED}>
                Approved
              </MenuItem>
              <MenuItem value={ActivityBoardStatusEnum.REJECTED}>
                Rejected
              </MenuItem>
            </Select>
            <FormHelperText error={!!errors?.status}>
              {errors?.status?.message}
            </FormHelperText>
          </FormControl>
        </Grid>
      </TableCell>
      <TableCell>
        <div className="flex justify-center">
          <Button
            variant="outlined"
            color="primary"
            size="small"
            disableElevation
            className="mb-5"
            type="button"
            onClick={handleSubmit}
            // disabled={!isValid}
          >
            {mode === "create" ? "Add" : "Save"}
          </Button>
        </div>
      </TableCell>
    </StyledTableRow>
  );
};

export default EditableBoardRow;
