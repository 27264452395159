import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import Breadcrumbs, { BreadcrumbsLink } from 'src/components/common/Breadcrumbs/Breadcrumbs';
import MainLayout from 'src/components/MainLayout';
import { ROLE } from 'src/enums/role';
import withAuth from 'src/hocs/withAuth';
import withRoles from 'src/hocs/withRoles';
import { CampaignFilterOption } from 'src/@types';
//   import DeleteCampaignDialog from "src/containers/Campaign/DeleteCampaignDialog";
import CanView from 'src/components/RoleBasedAccess/CanView';
import { Link, useHistory, useParams } from 'react-router-dom';
import Pagination from 'src/components/common/Pagination';
import { useCampaigns } from 'src/hooks/useCampaigns';
import EditIcon from '@material-ui/icons/Edit';
import { getActivitiesRoute, getCampaignBrandsRoute, getCampaignsRoute } from 'src/helpers/routes';
import Empty from 'src/components/common/Empty';
import LoadingIndicator from 'src/components/common/LoadingIndicator';

const campaignBrandsRoute = getCampaignBrandsRoute();

const links: BreadcrumbsLink[] = [
  {
    label: 'Dashboard',
    href: '/dashboard',
  },
  {
    label: 'Branding',
    href: campaignBrandsRoute,
  },
  {
    label: 'Campaign Brands',
    href: campaignBrandsRoute,
  },
  {
    label: 'Campaigns',
  },
];

const Campaigns = () => {
  const history = useHistory();
  const { brandId } = useParams<{ brandId: string }>();
  const campaignsRoute = getCampaignsRoute({ brandId });

  const [filterOption, setFilterOption] = useState<CampaignFilterOption>({
    query: '',
    page: 1,
    size: 20,
    filterType: 'query',
    brandIds: brandId ? [brandId] : [],
  });
  const rowsPerPageOptions = [10, 20, 30, 40, 50];

  const { campaigns, count, loading } = useCampaigns(filterOption);

  const handleCampaignSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setFilterOption((options) => ({
      ...options,
      query: value,
      page: 1,
      size: 20,
      filterType: 'query',
    }));
  };

  const handleChangePage = (event: React.ChangeEvent<unknown> | undefined, newPage: number) => {
    setFilterOption({ ...filterOption, page: newPage });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    setFilterOption({
      ...filterOption,
      page: 1,
      size: parseInt(event.target.value as string, 10),
    });
  };

  return (
    <MainLayout>
      <Grid container justifyContent="center" spacing={3}>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Breadcrumbs links={links} />
              <Typography variant="h5" color="textPrimary" className="font-bold mt-2">
                All Campaigns
              </Typography>
            </Grid>
            <Grid item>
              <CanView roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN]}>
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  component={Link}
                  to={`${campaignsRoute}/new`}
                >
                  New Campaign
                </Button>
              </CanView>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <TextField
                fullWidth
                variant="outlined"
                label="Search campaign"
                placeholder="Search campaign by name"
                onChange={handleCampaignSearch}
              />
            </CardContent>
          </Card>
        </Grid>
        <LoadingIndicator isLoading={loading}>
          <Empty isEmpty={count === 0}>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                {campaigns.map((campaign) => (
                  <Grid item xs={12} sm={6} md={6} lg={4} key={campaign.id}>
                    <Link to={getActivitiesRoute({ brandId, campaignId: campaign.id })}>
                      <Card>
                        <CardHeader
                          title={campaign.name}
                          action={
                            <>
                              <CanView roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN]}>
                                <IconButton
                                  onClick={(event) => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    history.push(`${campaignsRoute}/${campaign.id}/edit`);
                                  }}
                                >
                                  <EditIcon />
                                </IconButton>
                              </CanView>
                            </>
                          }
                        />
                        <CardContent>
                          <Grid container spacing={1}>
                            <Grid item xs={12} className="text-indigo-600">
                              Total: {campaign.totalActivity}
                            </Grid>
                            <Grid item xs={12} className="text-red-600">
                              Recee pending : {campaign.noOfNew}
                            </Grid>
                            <Grid item xs={12} className="text-yellow-600">
                              Recee approval pending : {campaign.noOfReceeApprovalPending}
                            </Grid>
                            <Grid item xs={12} className="text-blue-600">
                              Recee Approved : {campaign.noOfReceeCompleted}
                            </Grid>
                            <Grid item xs={12} className="text-blue-600">
                              Installation Pending : {campaign.noOfReceeCompleted}
                            </Grid>
                            <Grid item xs={12} className="text-yellow-600">
                              Installation approval pending :{' '}
                              {campaign.noOfInstallationApprovalPending}
                            </Grid>
                            <Grid item xs={12} className="text-purple-600">
                              Installation Approved : {campaign.noOfInstallationCompleted}
                            </Grid>
                            <CanView
                              roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER, ROLE.CLIENT]}
                            >
                              <Grid item xs={12} className="text-green-600">
                                Client Approved : {campaign.noOfClientApproved}
                              </Grid>
                              <Grid item xs={12} className="text-green-600">
                                Client Rejected : {campaign.noOfClientRejected}
                              </Grid>
                            </CanView>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Link>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {count > 0 && (
                <Pagination
                  count={count}
                  rowsPerPage={filterOption.size}
                  page={filterOption.page}
                  onChange={handleChangePage}
                  rowsPerPageOptions={rowsPerPageOptions}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              )}
            </Grid>
          </Empty>
        </LoadingIndicator>
      </Grid>

      {/*
        {campaignId && (
          <DeleteCampaignDialog
            open={openDeleteDialog}
            onClose={handleDeleteDialogClose}
            onSuccess={handleDeleteBrandinTypeSuccess}
            campaignId={campaignId}
          />
        )} */}
    </MainLayout>
  );
};

export default withAuth(
  withRoles(Campaigns, [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER, ROLE.CLIENT]),
  '/login'
);
