import { createContext } from 'react';
import { PATTERN_DATE } from 'src/config/date.constant';

const DateUtilContext = createContext({ format: 'dd/MM/yyyy', displayFormat: PATTERN_DATE});

const { Provider, Consumer } = DateUtilContext;

export { Provider as DateUtilProvider };
export { Consumer as DateUtilConsumer };

export default DateUtilContext;
