import Joi from "joi";

const userValidationSchema = Joi.object({
  id: Joi.string().allow(""),
  firstName: Joi.string().required().max(50).messages({
    "string.empty": "Name should not be empty",
    "string.max": "Name must be less than or equal to 50 characters long",
  }),
  lastName: Joi.string().allow("", null),
  imageUrl: Joi.string().allow("", null),
  mobile: Joi.string()
    .required()
    .min(10)
    .max(10)
    .pattern(/^[1-9](\d){9}$/)
    .messages({
      "string.empty": "Mobile should not be empty",
      "string.min": "Mobile number length must be at least 10 characters long",
      "string.max":
        "Mobile number length must be less than or equal to 10 characters long",
      "string.pattern.base": "Invalid Mobile number",
    }),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .allow("", null)
    .optional()
    .messages({
      "string.email": "Invalid Email",
    }),
  dob: Joi.date().allow("", null).optional().messages({
    "date.empty": "Date of birth should not be empty",
  }),
  gender: Joi.string().required().messages({
    "string.empty": "Gender should not be empty",
  }),
  roles: Joi.array()
    .items(
      Joi.object().required().messages({
        "object.empty": "Roles should not be empty",
      })
    )
    .unique()
    .messages({
      "array.unique": "Roles contains a duplicate value",
      "array.includesRequiredUnknowns": "Roles should not be empty",
    }),
  identity: Joi.object({
    id: Joi.string().optional(),
    adhaarcard: Joi.string().allow("").messages({
      "string.empty": "Adhaard card should not be empty",
    }),
    pancard: Joi.string().allow("").messages({
      "string.empty": "Pancard should not be empty",
    }),
  }),
  address: Joi.object({
    id: Joi.string().optional(),
    state: Joi.string().allow("").messages({
      "string.empty": "State should not be empty",
    }),
    city: Joi.string().allow("").messages({
      "string.empty": "City should not be empty",
    }),
    addressLine: Joi.string().allow("").messages({
      "string.empty": "Address line should not be empty",
    }),
    pincode: Joi.string().allow("").messages({
      "string.empty": "Pincode should not be empty",
    }),
    town: Joi.string().allow(""),
    agencyName: Joi.string().allow(""),
  }),
}).unknown();

export default userValidationSchema;
