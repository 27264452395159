import {
  makeStyles,
  TableRow,
  TableCell,
  IconButton,
  Typography,
  Tooltip,
  Link as MaterialLink,
} from '@material-ui/core';
import React from 'react';
import { Activity } from 'src/@types';
import DisplayDate from 'src/components/DisplayDate';
import CanView from 'src/components/RoleBasedAccess/CanView';
import { ROLE } from 'src/enums/role';
import { Link, useParams } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { formatDateTime } from 'src/util/datetime';
import cx from 'classnames';
import Checkbox from '@material-ui/core/Checkbox';
import { getActivitiesRoute } from 'src/helpers/routes';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { createGoogleMapLink } from 'src/helpers/map';
import AttachmentGrid from 'src/components/AttachmentGrid';

interface ActvityTableRowProps {
  activity: Activity;
  index: number;
  selected?: boolean;
  onSelect?: () => void;
  onUnselect?: () => void;
  maxNoOfBoards: number;
}

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

const ActivityTableRow: React.FC<ActvityTableRowProps> = ({
  activity,
  selected,
  onSelect,
  onUnselect,
  maxNoOfBoards,
}) => {
  const classes = useRowStyles();
  const { brandId, campaignId } = useParams<{ brandId: string; campaignId: string }>();
  const activitiesRoute = getActivitiesRoute({ brandId, campaignId });

  const handlePropogation = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.stopPropagation();
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    if (checked) {
      onSelect?.();
    } else {
      onUnselect?.();
    }
  };

  const renderBoards = () => {
    const columns = [];

    for (let index = 0; index < maxNoOfBoards; index++) {
      if (index < activity.boards.length) {
        columns.push(<TableCell>{activity.boards[index].materialType?.name}</TableCell>);
        columns.push(
          <TableCell>
            <AttachmentGrid attachments={activity.boards[index]?.receePhotos ?? []} />
          </TableCell>
        );
        columns.push(
          <TableCell>
            <AttachmentGrid attachments={activity.boards[index]?.installationPhotos ?? []} />
          </TableCell>
        );
      } else {
        columns.push(<TableCell />);
        columns.push(<TableCell />);
        columns.push(<TableCell />);
      }
    }
    return columns;
  };

  return (
    <React.Fragment>
      <TableRow
        className={cx(classes.root, 'cursor-pointer')}
        hover
        // component="a"
        // href={`/branding/activities/${activity.id}`}
      >
        <CanView roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER, ROLE.CLIENT]}>
          <TableCell>
            <Checkbox checked={selected} onChange={handleCheckboxChange} />
          </TableCell>
        </CanView>
        <TableCell>
          {activity.uploadDate && (
            <Tooltip title={formatDateTime(activity.uploadDate)} placement="top">
              <span className="whitespace-nowrap">
                <DisplayDate date={activity.uploadDate} />
              </span>
            </Tooltip>
          )}
        </TableCell>
        <TableCell>{activity.code}</TableCell>
        <TableCell>
          <Typography className="capitalize font-medium" variant="body2">
            {activity.outlet?.outletName}
          </Typography>
          <Typography color="textSecondary" variant="body2">
            {activity.outlet?.mobile}
          </Typography>
        </TableCell>
        {/* <TableCell align="left">
          <Typography className="capitalize" variant="body2">
            {activity.outlet?.outletType?.name}
          </Typography>
        </TableCell> */}
        <TableCell align="left">
          <Typography className="capitalize" variant="body2">
            {activity.outlet?.retailerName}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography className="capitalize" variant="body2">
            {activity.outlet.lat && activity.outlet.lng ? (
              <MaterialLink
                href={createGoogleMapLink(activity.outlet.lat, activity.outlet.lng)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <LocationOnIcon />
                <span>
                  {activity.outlet?.town} , {activity.outlet?.state}
                </span>
              </MaterialLink>
            ) : (
              <span>
                {activity.outlet?.town} , {activity.outlet?.state}
              </span>
            )}
          </Typography>
        </TableCell>
        {renderBoards()}
        <CanView roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER, ROLE.CLIENT]}>
          <TableCell>{activity.status?.name}</TableCell>
        </CanView>
        <TableCell
          align="center"
          className="whitespace-nowrap sticky right-0 bg-white hover:bg-gray-100"
        >
          <CanView roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER]}>
            <IconButton
              component={Link}
              to={`${activitiesRoute}/${activity.id}/edit`}
              onClick={handlePropogation}
              size="small"
            >
              <EditIcon className="w-6 h-6" />
            </IconButton>
          </CanView>
          <IconButton
            component={Link}
            to={`${activitiesRoute}/${activity.id}`}
            onClick={handlePropogation}
            size="small"
            className="mr-2"
          >
            <ArrowForwardIcon className="w-6 h-6" />
          </IconButton>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default ActivityTableRow;
