import { Campaign, QueryFilterType } from "src/@types";
import API from "src/helpers/api";
import { getErrorMessage } from "src/helpers/axios";
import qs from "qs";

export interface CreateCampaignOptions {
  name: string;
  description: string;
  brandId: string;
  managerId: string;
  file: File | null;
}

export interface CreateCampaignResult {
  campaign?: Campaign;
  message?: string;
  error?: string;
}

export interface CreateCampaignResponse {
  message: string;
  campaign: Campaign;
}

async function create(options: CreateCampaignOptions) {
  const result = {} as CreateCampaignResult;
  try {
    const formData = new FormData();

    for (const key in options) {
      formData.set(key, (options as any)[key]);
    }

    const { data } = await API.post<CreateCampaignResponse>(
      "campaigns",
      formData
    );
    if (data) {
      result.campaign = data.campaign;
      result.message = data.message;
    }
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
}

export interface UpdateCampaignOptions {
  id: string;
  name: string;
  description: string;
  brandId: string;
  managerId: string;
  file: File | null;
}

export interface UpdateCampaignResult {
  campaign?: Campaign;
  message?: string;
  error?: string;
}

export interface UpdateCampaignResponse {
  message: string;
  campaign: Campaign;
}

async function update(id: string, options: UpdateCampaignOptions) {
  const result = {} as UpdateCampaignResult;
  try {
    const formData = new FormData();

    for (const key in options) {
      formData.set(key, (options as any)[key]);
    }

    const { data } = await API.patch<UpdateCampaignResponse>(
      `campaigns/${id}`,
      formData
    );
    if (data) {
      result.campaign = data.campaign;
      result.message = data.message;
    }
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
}

interface GeAllCampaignOptions {
  page?: number;
  size?: number;
  query?: string;
  filterType: QueryFilterType;
  brandIds: string[];
}

interface GetAllCampaignResult {
  campaigns?: Campaign[];
  count?: number;
  error?: string;
}

interface GetAllCampaignResponse {
  campaigns: Campaign[];
  count: number;
}

const getAll = async ({
  page,
  size,
  query,
  filterType,
  brandIds,
}: GeAllCampaignOptions) => {
  const result: GetAllCampaignResult = {};
  try {
    const queryString = qs.stringify({
      page,
      size,
      query,
      filterType,
      brandIds,
    });
    const {
      data: { campaigns, count },
    } = await API.get<GetAllCampaignResponse>(`/campaigns?${queryString}`, {});
    result.campaigns = campaigns;
    result.count = count;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface GetCampaignByIdResult {
  campaign?: Campaign;
  error?: string;
}

interface GetCampaignByIdResponse {
  campaign: Campaign;
}

const getById = async (campaignId: string) => {
  const result: GetCampaignByIdResult = {};
  try {
    const {
      data: { campaign },
    } = await API.get<GetCampaignByIdResponse>(`campaigns/${campaignId}`);
    result.campaign = campaign;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

export const CampaignService = {
  create,
  update,
  getAll,
  getById,
};
