import { Attachment } from "src/@types";
import { readFile } from "./file";
import { v4 as uuid } from "uuid";

export async function createAttachment(file: File): Promise<Attachment> {
  const base64 = await readFile(file);
  return {
    id: uuid(),
    name: file.name,
    contentType: file.type,
    contentSize: file.size,
    base64,
  };
}
