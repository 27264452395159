import {
  CardHeader,
  Typography,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import { FunctionComponent } from 'react';
import { ActivityBoard } from 'src/@types';
import Card from 'src/components/common/Card';
import CanView from 'src/components/RoleBasedAccess/CanView';
import { ROLE } from 'src/enums/role';
import { ActivityBoardStatusEnum } from 'src/enums/activity-board-status.enum';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import ActivityBoardService from 'src/services/activity-board.service';
import toast from 'react-hot-toast';
import AttachmentGrid from 'src/components/AttachmentGrid';

export interface BoardDetailProps {
  boards: ActivityBoard[];
  onRefresh: () => void;
}

const BoardDetail: FunctionComponent<BoardDetailProps> = ({ boards, onRefresh }) => {
  const approveBoard = async (id: string) => {
    const { message, error } = await ActivityBoardService.approve([id]);

    if (message) {
      toast.success(message);
      onRefresh();
    }

    if (error) {
      toast.error(error);
    }
  };

  const rejectBoard = async (id: string) => {
    const { message, error } = await ActivityBoardService.reject([id]);

    if (message) {
      toast.success(message);
      onRefresh();
    }

    if (error) {
      toast.error(error);
    }
  };

  return (
    <Card>
      <CardHeader title={<Typography variant="h6">Boards</Typography>} />
      <Divider />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell scope="th">Material</TableCell>
            <TableCell scope="th">Width in Inches</TableCell>
            <TableCell scope="th">Height in Inches</TableCell>
            <TableCell scope="th">Quantity</TableCell>
            <TableCell scope="th">Remark</TableCell>
            <TableCell scope="th">Recee photo</TableCell>
            <TableCell scope="th">Installation photo</TableCell>
            <CanView roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER]}>
              <TableCell scope="th">Status</TableCell>
            </CanView>
          </TableRow>
        </TableHead>
        <TableBody>
          {boards.map((board) => (
            <TableRow key={board.id}>
              <TableCell>{board.materialType?.name}</TableCell>
              <TableCell>{board.width}</TableCell>
              <TableCell>{board.height}</TableCell>
              <TableCell>{board.quantity}</TableCell>
              <TableCell>{board.remark}</TableCell>
              <TableCell>
                <AttachmentGrid attachments={board.receePhotos ?? []} />
              </TableCell>
              <TableCell>
                <AttachmentGrid attachments={board.installationPhotos ?? []} />
              </TableCell>
              <CanView roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER]}>
                {(board.status === ActivityBoardStatusEnum.APPROVED ||
                  board.status === ActivityBoardStatusEnum.REJECTED) && (
                  <TableCell className="capitalize">{board.status}</TableCell>
                )}
                {board.status === ActivityBoardStatusEnum.PENDING && (
                  <TableCell>
                    <Tooltip title="Approve" placement="top">
                      <IconButton color="primary" onClick={() => approveBoard(board.id)}>
                        <CheckIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Reject" placement="top" onClick={() => rejectBoard(board.id)}>
                      <IconButton color="secondary" className="mr-2">
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                )}
              </CanView>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Card>
  );
};

export default BoardDetail;
