import React from 'react';
import { useSelector } from 'react-redux';
import { ROLE } from 'src/enums/role';
import { RootState } from 'src/store';
import { AuthState } from 'src/store/auth/types';
import { hasRole } from 'src/util/role';

interface CanViewProps {
  roles: ROLE[];
}

const CanView: React.FunctionComponent<CanViewProps> = ({ children, roles }) => {
  const { user } = useSelector<RootState, AuthState>((state) => state.authState);
  if (user && hasRole(user.roles, roles)) {
    return <>{children}</>;
  }
  return <></>;
};

export default CanView;
