import { FileSizeUnit } from "src/@types";
import { GB_SIZE, KB_SIZE, MB_SIZE } from "src/config/file.constants";

export const readFile = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (reader.result) {
          resolve(reader.result as string);
        }
      };
      reader.onerror = (error) => {
        reject(error);
      };
    }
  });
};

export const getFileSizeLabel = (size: number, unit: FileSizeUnit) => {
  switch (unit) {
    case "KB":
      return `${(size / KB_SIZE).toFixed(2)} kb`;
    case "MB":
      return `${(size / MB_SIZE).toFixed(2)} mb`;
    case "GB":
      return `${(size / GB_SIZE).toFixed(2)} db`;
    default:
      return `${size} bytes`;
  }
};
