import { Dialog, DialogContent, DialogActions, Button, Typography } from '@material-ui/core';

import React, { useCallback } from 'react';
import DialogTitle from 'src/components/common/Dialog/DialogTitle';
import BrandService from 'src/services/brand.service';
import toast from "react-hot-toast";

interface DeleteBrandDialogProps {
  open: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
  brandId: string;
}

const DeleteBrandDialog: React.FC<DeleteBrandDialogProps> = ({
  open,
  onClose,
  onSuccess,
  brandId,
}) => {
  

  const handleDelete = useCallback(async () => {
    const { message, error } = await BrandService.deleteBrand(brandId);
    if (error) {
      toast.error(error);
    } else if (message) {
      toast.success(message);
      onSuccess?.();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      PaperProps={{
        className: 'w-4/12',
      }}
    >
      <DialogTitle title="Delete Brand" onClose={onClose} />
      <DialogContent>
        <Typography>
          Are you user you want to delete the Brand? The changes can't be reverted back!!
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          Cancel
        </Button>
        <Button color="secondary" onClick={handleDelete}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteBrandDialog;
