export enum ActivityStatusEnum {
  NEW = "new",
  RECEE_APPROVAL_PENDING = "recee_approval_pending",
  RECEE_APPROVED = "recee_approved",
  RECEE_REJECTED = "recee_rejected",
  INSTALLATION_APPROVAL_PENDING = "installation_approval_pending",
  INSTALLATION_APPROVED = "installation_approved",
  INSTALLATION_REJECTED = "installation_rejected",
  COMPLETED = "completed",
}
