export const UPLOAD_EXCEL_DATA_FORMAT = [
  {
    field: 'ID',
    optional: true,
    value: 'Number',
    example: '1',
  },
  {
    field: 'CODE',
    optional: false,
    value: 'Alpha numeric',
    example: 'TVS00001',
  },
  {
    field: 'NAME_OF_RETAILER',
    optional: false,
    value: 'Alphabet',
    example: 'Amit',
  },
  {
    field: 'MOBILE',
    optional: false,
    value: '10 digit mobile number',
    example: '1234567890',
  },
  {
    field: 'STATE',
    optional: true,
    value: 'Alphabet',
    example: 'Maharashtra',
  },
  {
    field: 'CITY',
    optional: true,
    value: 'Alphabet',
    example: 'Maharashtra',
  },
  {
    field: 'PINCODE',
    optional: false,
    value: '6-8 digit number',
    example: '400017',
  },
  {
    field: 'LANDMARK',
    optional: true,
    value: 'Alpha numeric',
    example: 'Near Ram Mandir',
  },
  {
    field: 'OULET_NAME',
    optional: false,
    value: 'Alpha numeric',
    example: 'India one pvt ltd',
  },
  {
    field: 'REGISTERED_BY',
    optional: true,
    value: 'Alpha numeric',
    example: 'Name',
  },
  // {
  //   field: 'DATE_OF_REGISTRATION',
  //   optional: true,
  //   value: 'Date (DD-MM-YYYY), eg. DD - date | MM - Month | YYYY - Year',
  //   example: '01-01-2021',
  // },

  // {
  //   field: "LAST_DATE_OF_LOGIN",
  //   optional: true,
  //   value: "Date (DD-MM-YYYY), eg. DD - date | MM - Month | YYYY - Year",
  //   example: "01-01-2021",
  // },
  // {
  //   field: "WHETHER_MECHANIC",
  //   optional: true,
  //   value: "Alphabet",
  //   example: "Yes/No",
  // },
  {
    field: 'FULL_ADDRESS',
    optional: true,
    value: 'Alpha numeric',
    example: 'TVS00001',
  },
  {
    field: 'RETAILER_ALTERNATE_MOBILE',
    optional: true,
    value: '10 digit mobile number',
    example: '1234567890',
  },
];
