import { Button, Grid, Paper, Typography } from "@material-ui/core";
import { FunctionComponent, useEffect, useState } from "react";
import { Campaign, CampaignFormData } from "src/@types";
import Breadcrumbs from "src/components/common/Breadcrumbs";
import { BreadcrumbsLink } from "src/components/common/Breadcrumbs/Breadcrumbs";
import MainLayout from "src/components/MainLayout";
import { ROLE } from "src/enums/role";
import withAuth from "src/hocs/withAuth";
import withRoles from "src/hocs/withRoles";
import ActivityService from "src/services/activity.service";
import toast from "react-hot-toast";
import { useHistory, useParams } from "react-router-dom";
import FileSaver from "file-saver";
import { CampaignService } from "src/services/campaign.service";
import CampaignForm from "src/containers/Campaign/CampaignForm";
import {
  getActivitiesRoute,
  getCampaignBrandsRoute,
  getCampaignsRoute,
} from "src/helpers/routes";

const CreateCampaign: FunctionComponent = () => {
  const history = useHistory();
  const { brandId, id } = useParams<{ brandId: string; id: string }>();

  const campaignBrandsRoute = getCampaignBrandsRoute();
  const campaignsRoute = getCampaignsRoute({ brandId });

  const links: BreadcrumbsLink[] = [
    {
      label: "Dashboard",
      href: "/dashboard",
    },
    {
      label: "Branding",
      href: campaignBrandsRoute,
    },
    {
      label: "Campaign Brands",
      href: campaignBrandsRoute,
    },
    {
      label: "Campaigns",
      href: campaignsRoute,
    },
    {
      label: "Edit Campaign",
    },
  ];

  const [campaign, setCampaign] = useState<Campaign | null>(null);
  const [campaignFormData, setCampaignFormData] =
    useState<CampaignFormData | null>(null);
  const [uploading, setUploading] = useState(false);

  const handleMasterExcelDownload = async () => {
    const { buffer } = await ActivityService.downloadMasterExcel();
    if (buffer) {
      FileSaver.saveAs(new Blob([buffer]), "ActivityMasterExcel.xlsx");
    }
  };

  const onSave = async (data: CampaignFormData) => {
    const { brand, manager, file, name, description } = data;

    if (!brand || !manager || !campaign) {
      return;
    }
    setUploading(true);

    const { message, error } = await CampaignService.update(campaign.id, {
      id: campaign.id,
      name,
      description,
      brandId: brand.id,
      managerId: manager.id,
      file,
    });

    setUploading(false);

    if (message) {
      toast.success(message);
      history.push(getActivitiesRoute({ brandId, campaignId: campaign.id }));
    }

    if (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    const getById = async () => {
      const { campaign } = await CampaignService.getById(id);
      if (campaign) {
        setCampaign(campaign);
        setCampaignFormData({
          ...campaign,
          file: null,
        });
      }
    };
    getById();
    // eslint-disable-next-line
  }, []);

  return (
    <MainLayout>
      <Grid container justifyContent="center" spacing={3}>
        <Grid item xs={12} sm={12} lg={8} xl={6}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={12} sm={8} lg={8}>
              <Breadcrumbs links={links} />
              <Typography
                variant="h5"
                color="textPrimary"
                className="font-bold mt-2"
              >
                Edit Campaign
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} lg={4} className="flex md:justify-end">
              <Button
                color="primary"
                variant="contained"
                size="small"
                onClick={handleMasterExcelDownload}
                disableElevation
              >
                Download Master Excel
              </Button>
            </Grid>
          </Grid>
          <Paper className="mt-5" elevation={1}>
            {campaign && campaignFormData && (
              <CampaignForm
                mode="edit"
                campaign={campaignFormData}
                onSave={onSave}
                saving={uploading}
              />
            )}
          </Paper>
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default withAuth(
  withRoles(CreateCampaign, [ROLE.SUPER_ADMIN, ROLE.ADMIN])
);
