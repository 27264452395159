import { Box, Typography, Grid, Backdrop } from "@material-ui/core";
import toast from "react-hot-toast";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Activity } from "src/@types";
import Breadcrumbs, {
  BreadcrumbsLink,
} from "src/components/common/Breadcrumbs/Breadcrumbs";
import CircularProgressWithLabel from "src/components/common/Circular";
import Empty from "src/components/common/Empty";
import LoadingIndicator from "src/components/common/LoadingIndicator";
import MainLayout from "src/components/MainLayout";
import ActivityForm from "src/containers/Activity/ActivityForm";
import { ROLE } from "src/enums/role";
import {
  getCampaignBrandsRoute,
  getCampaignsRoute,
  getActivitiesRoute,
} from "src/helpers/routes";
import withAuth from "src/hocs/withAuth";
import withRoles from "src/hocs/withRoles";
import ActivityService from "src/services/activity.service";

const EditActivity = () => {
  const history = useHistory();
  const { brandId, campaignId, id } =
    useParams<{ brandId: string; campaignId: string; id: string }>();

  const campaignBrandsRoute = getCampaignBrandsRoute();
  const campaignsRoute = getCampaignsRoute({ brandId });
  const activitiesRoute = getActivitiesRoute({ brandId, campaignId });

  const links: BreadcrumbsLink[] = [
    {
      label: "Dashboard",
      href: "/dashboard",
    },
    {
      label: "Branding",
      href: campaignBrandsRoute,
    },
    {
      label: "Campaign Brands",
      href: campaignBrandsRoute,
    },
    {
      label: "Campaigns",
      href: campaignsRoute,
    },
    {
      label: "Activities",
      href: activitiesRoute,
    },
    {
      label: "Edit Activity",
    },
  ];

  const [activity, setActivity] = useState<Activity | null>();
  const [loading, setLoading] = useState(false);

  const [progress, setProgress] = useState(0);
  const [saving, setSaving] = useState(false);

  const getActivityById = async (id: string) => {
    setLoading(true);
    const { activity, error } = await ActivityService.getActivityById(id);
    setLoading(false);
    if (error) {
      toast.error(error);
    } else if (activity) {
      setActivity(activity);
    }
  };

  useEffect(() => {
    getActivityById(id);
    // eslint-disable-next-line
  }, []);

  const handleSave = async (data: Activity) => {
    setSaving(true);
    const { activity: updatedActivity, error } =
      await ActivityService.updateActivity(
        {
          ...activity,
          ...data,
        },
        {
          onUploadProgress: (percent) => {
            setProgress(percent);
          },
        }
      );
    setSaving(false);
    setProgress(0);
    if (error) {
      toast.error(error);
    } else if (updatedActivity) {
      history.push(`${activitiesRoute}/${updatedActivity.id}`);
    }
  };

  return (
    <MainLayout>
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <Breadcrumbs links={links} />
          <Typography
            variant="h5"
            color="textPrimary"
            className="font-bold mt-2"
          >
            Edit Activity
          </Typography>
          <Box marginTop={2}>
            <LoadingIndicator isLoading={loading} imgClassName="w-4/12">
              <Empty isEmpty={!activity} imgClassName="w-4/12">
                {activity && (
                  <ActivityForm
                    mode="edit"
                    activity={activity}
                    onSave={handleSave}
                  />
                )}
              </Empty>
            </LoadingIndicator>
          </Box>
        </Grid>
      </Grid>
      <Backdrop open={saving} style={{ zIndex: 2000 }} className="flex-col">
        <CircularProgressWithLabel value={progress} />
        <Typography variant="h6" className="text-white">
          uploading
        </Typography>
      </Backdrop>
    </MainLayout>
  );
};

export default withAuth(
  withRoles(EditActivity, [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER]),
  "/login"
);
