import { Box, Button, Divider, Grid, Tab, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import Breadcrumbs, { BreadcrumbsLink } from 'src/components/common/Breadcrumbs/Breadcrumbs';
import MainLayout from 'src/components/MainLayout';
import withAuth from 'src/hocs/withAuth';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { Activity } from 'src/@types';
import ActivityService from 'src/services/activity.service';
import Empty from 'src/components/common/Empty';
import ActivityDetail from 'src/containers/Activity/ActivityDetail';
import LoadingIndicator from 'src/components/common/LoadingIndicator';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import toast from 'react-hot-toast';
import DeleteActivityDialog from 'src/containers/Activity/DeleteActivityDialog';
import CanView from 'src/components/RoleBasedAccess/CanView';
import { ROLE } from 'src/enums/role';
import withRoles from 'src/hocs/withRoles';
import { ActivityStatusEnum } from 'src/enums/activity-status';
import { getCampaignBrandsRoute, getCampaignsRoute, getActivitiesRoute } from 'src/helpers/routes';

const ViewActivity = () => {
  const history = useHistory();
  const { brandId, campaignId, id } =
    useParams<{ brandId: string; campaignId: string; id: string }>();

  const campaignBrandsRoute = getCampaignBrandsRoute();
  const campaignsRoute = getCampaignsRoute({ brandId });
  const activitiesRoute = getActivitiesRoute({ brandId, campaignId });

  const links: BreadcrumbsLink[] = [
    {
      label: 'Dashboard',
      href: '/dashboard',
    },
    {
      label: 'Branding',
      href: campaignBrandsRoute,
    },
    {
      label: 'Campaign Brands',
      href: campaignBrandsRoute,
    },
    {
      label: 'Campaigns',
      href: campaignsRoute,
    },
    {
      label: 'Activities',
      href: activitiesRoute,
    },
    {
      label: 'View Activity',
    },
  ];

  const [activity, setActivity] = useState<Activity | null>(null);
  const [loading, setLoading] = useState(false);
  const [currentTab, setTab] = useState('detail');
  const [openDeleteActivityDialog, setOpenDeleteActivityDialog] = useState(false);

  const goToPrevPage = () => {
    if (history.length > 1) {
      history.go(-1);
    } else {
      history.push(activitiesRoute);
    }
  };

  const getActivityById = async (id: string) => {
    setLoading(true);
    const { activity, error } = await ActivityService.getActivityById(id);
    setLoading(false);
    if (error) {
    } else if (activity) {
      setActivity(activity);
    }
  };

  const handleTabChange = (event: React.ChangeEvent<any>, newValue: string) => {
    setTab(newValue);
  };

  const deleteActivity = async () => {
    if (activity) {
      const { message, error } = await ActivityService.deleteActivity(activity.id);
      if (message) {
        toast.success(message);
        goToPrevPage();
      } else if (error) {
        toast.error(error);
      }
    }
  };
  const showDeleteActivityDialog = () => {
    setOpenDeleteActivityDialog(true);
  };
  const closeDeleteActivityDialog = () => {
    setOpenDeleteActivityDialog(false);
  };

  const approveRecee = async () => {
    if (activity) {
      const { message, error } = await ActivityService.approveRecee(activity.id);
      if (message) {
        toast.success(message);
        goToPrevPage();
      } else if (error) {
        toast.error(error);
      }
    }
  };

  const rejectRecee = async () => {
    if (activity) {
      const { message, error } = await ActivityService.rejectRecee(activity.id);
      if (message) {
        toast.success(message);
        goToPrevPage();
      } else if (error) {
        toast.error(error);
      }
    }
  };

  const approveInstallation = async () => {
    if (activity) {
      const { message, error } = await ActivityService.approveInstallation(activity.id);
      if (message) {
        toast.success(message);
        goToPrevPage();
      } else if (error) {
        toast.error(error);
      }
    }
  };

  const rejectInstallation = async () => {
    if (activity) {
      const { message, error } = await ActivityService.rejectInstallation(activity.id);
      if (message) {
        toast.success(message);
        goToPrevPage();
      } else if (error) {
        toast.error(error);
      }
    }
  };

  const getTabs = () => {
    const tabs = [{ label: 'Detail', value: 'detail' }];
    return tabs;
  };

  const handleRefresh = () => {
    getActivityById(id);
  };

  useEffect(() => {
    getActivityById(id);
    // eslint-disable-next-line
  }, []);

  const tabs = getTabs();

  return (
    <MainLayout>
      <Grid container>
        <Grid item xs={12} sm={12} md={6}>
          <Breadcrumbs links={links} />
          {activity && (
            <div className="flex items-center mt-3">
              <Typography variant="h5" color="textPrimary" className="font-bold mt-2 uppercase">
                Activity Detail
              </Typography>
            </div>
          )}
        </Grid>
        {activity && (
          <Grid item xs={12} sm={12} md={6}>
            <CanView roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER]}>
              <Box className="w-full flex lg:justify-end items-center">
                {activity.activityStatusId === ActivityStatusEnum.RECEE_APPROVAL_PENDING && (
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    className="mr-2"
                    onClick={approveRecee}
                  >
                    <ThumbUpIcon className="mr-2" />
                    Approve Recee
                  </Button>
                )}
                {activity.activityStatusId === ActivityStatusEnum.INSTALLATION_APPROVAL_PENDING && (
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    className="mr-2"
                    onClick={approveInstallation}
                  >
                    <ThumbUpIcon className="mr-2" />
                    Approve Installation
                  </Button>
                )}
                {activity.activityStatusId === ActivityStatusEnum.RECEE_APPROVAL_PENDING && (
                  <Button
                    variant="contained"
                    color="secondary"
                    disableElevation
                    className="mr-2"
                    onClick={rejectRecee}
                  >
                    <ThumbDownIcon className="mr-2" />
                    Reject Recee
                  </Button>
                )}
                {activity.activityStatusId === ActivityStatusEnum.INSTALLATION_APPROVAL_PENDING && (
                  <Button
                    variant="contained"
                    color="secondary"
                    disableElevation
                    className="mr-2"
                    onClick={rejectInstallation}
                  >
                    <ThumbDownIcon className="mr-2" />
                    Reject Installation
                  </Button>
                )}

                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  component={Link}
                  to={`${activitiesRoute}/${activity?.id}/edit`}
                  className="mr-2"
                >
                  <EditIcon className="mr-2" />
                  Edit
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  disableElevation
                  onClick={showDeleteActivityDialog}
                  className="mr-2"
                >
                  <DeleteIcon className="mr-2" />
                  Delete
                </Button>
              </Box>
            </CanView>
          </Grid>
        )}
      </Grid>
      <LoadingIndicator isLoading={loading} imgClassName="w-4/12">
        <Empty isEmpty={!activity} imgClassName="w-4/12">
          <TabContext value={currentTab}>
            <TabList className="mt-3" indicatorColor="primary" onChange={handleTabChange}>
              {tabs.map((tab) => (
                <Tab label={tab.label} value={tab.value} key={tab.value} />
              ))}
            </TabList>

            <Divider />
            <Box>
              <TabPanel value="detail">
                {activity && <ActivityDetail activity={activity} onRefresh={handleRefresh} />}
              </TabPanel>
            </Box>
          </TabContext>
        </Empty>
      </LoadingIndicator>
      {openDeleteActivityDialog && (
        <DeleteActivityDialog
          open={openDeleteActivityDialog}
          onClose={closeDeleteActivityDialog}
          onDelete={deleteActivity}
        />
      )}
    </MainLayout>
  );
};

export default withAuth(
  withRoles(ViewActivity, [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER, ROLE.CLIENT]),
  '/login'
);
