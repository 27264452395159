import { joiResolver } from "@hookform/resolvers/joi";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
} from "@material-ui/core";
import debounce from "lodash.debounce";
import React, { useEffect } from "react";
import { useState } from "react";
import { FieldError, useForm } from "react-hook-form";
import { BrandUserForm, User } from "src/@types";
import AutocompleteController from "src/components/common/AutocompleteController";
import DialogTitle from "src/components/common/Dialog/DialogTitle";
import { ROLE } from "src/enums/role";
import UserService from "src/services/user.service";
import { brandUserFormSchema } from "src/validation/brand.schema";

interface BrandUserFormProps {
  mode: "create" | "edit";
  open: boolean;
  brandUser: BrandUserForm;
  onSave?: (brandUser: BrandUserForm) => void;
  onClose?: () => void;
}

const BrandUserFormDialog: React.FC<BrandUserFormProps> = ({
  mode,
  brandUser,
  onSave,
  open,
  onClose,
}) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<BrandUserForm>({
    defaultValues: brandUser,
    mode: "onSubmit",
    resolver: joiResolver(brandUserFormSchema),
  });

  const [users, setUsers] = useState<User[]>([]);

  const getAllUsers = async (query: string = "") => {
    const { users } = await UserService.getAllUser({
      page: 1,
      size: 25,
      query,
      roles: [ROLE.CLIENT, ROLE.PROMOTER],
    });

    if (users) {
      setUsers(users);
    }
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  const debounceUserSearch = debounce((value: string) => {
    getAllUsers(value);
  }, 300);

  const handleUserInputChange = (
    event: React.ChangeEvent<{}>,
    value: string
  ) => {
    debounceUserSearch(value);
  };

  const onSubmit = (data: BrandUserForm) => {
    onSave?.({ ...data, userId: data.user?.id ?? null });
  };

  console.log(users);
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      PaperProps={{
        className: "w-4/12",
      }}
    >
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle
          title={mode === "create" ? "Add User" : "Edit User"}
          onClose={onClose}
        />
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <AutocompleteController
                options={users}
                getOptionLabel={(user) => user.firstName}
                getOptionSelected={(option, value) => option.id === value.id}
                label="User"
                placeholder="Search for client and promoter"
                className="w-full"
                control={control}
                name="user"
                id="user"
                onInputChange={handleUserInputChange}
                error={!!errors.user}
                helperText={(errors?.user as FieldError | undefined)?.message}
                size="small"
                filterOptions={(options, value) => {
                  const input = value.inputValue.toLowerCase();
                  return options.filter(
                    (option) =>
                      option.firstName.toLowerCase().indexOf(input) > -1 ||
                      option.mobile.toLowerCase().indexOf(input) > -1
                  );
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onClose}>
            Cancel
          </Button>
          <Button color="primary" type="submit">
            {mode === "create" ? "Add" : "Save "}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default BrandUserFormDialog;
