import { User } from "src/@types/user";
import API from "src/helpers/api";
import { getErrorMessage } from "src/helpers/axios";
import qs from "qs";
import { ROLE } from "src/enums/role";
import { parseISO } from "date-fns";

const transformUserResponse = (user: any): User => {
  if (user?.dob) {
    user.dob = parseISO(user.dob);
  }
  if (!user.address) {
    user.address = { state: "", city: "", pincode: "", addressLine: "" };
  }
  if (!user.identity) {
    user.identity = { adhaarcard: "", pancard: "" };
  }
  return user;
};

interface GeAllUserOptions {
  page: number;
  size: number;
  query: string;
  roles: ROLE[];
}
interface GetAllUserResult {
  users?: User[];
  count?: number;
  error?: string;
}

interface GetAllUserResponse {
  users: User[];
  count: number;
}

const getAllUser = async ({ page, size, query, roles }: GeAllUserOptions) => {
  const result: GetAllUserResult = {};
  try {
    const queryString = qs.stringify({ page, size, query, roles });
    const {
      data: { users, count },
    } = await API.get<GetAllUserResponse>(`/users?${queryString}`, {
      transformResponse: (data) => {
        const responseData = JSON.parse(data);
        responseData.users = responseData.users.map(transformUserResponse);
        return responseData;
      },
    });
    result.users = users;
    result.count = count;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface GetUserByIdResult {
  user?: User;
  error?: string;
}

interface GetUserByIdResponse {
  user: User;
}

const getUserById = async (userId: string) => {
  const result: GetUserByIdResult = {};
  try {
    const {
      data: { user },
    } = await API.get<GetUserByIdResponse>(`/users/${userId}`, {
      transformResponse: (data) => {
        const responseData = JSON.parse(data);
        responseData.user = transformUserResponse(responseData.user);
        return responseData;
      },
    });
    result.user = user;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface CreateUserResponse {
  user: User;
  message: string;
}
interface CreateUserResult {
  user?: User;
  message?: string;
  error?: string;
}

const createUser = async (userData: User) => {
  const result: CreateUserResult = {};
  try {
    const {
      data: { user, message },
    } = await API.post<CreateUserResponse>("/users", {
      ...userData,
      dob: userData.dob?.toISOString(),
    });
    result.user = user;
    result.message = message;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface UpdateUserResponse {
  user: User;
  message: string;
}
interface UpdateUserResult {
  user?: User;
  message?: string;
  error?: string;
}

const updateUser = async (userData: User) => {
  const result: UpdateUserResult = {};
  try {
    // const userFormData: UserFormData = transformToUserFormData(userData);
    const {
      data: { user, message },
    } = await API.patch<UpdateUserResponse>(`/users/${userData.id}`, userData);
    result.user = user;
    result.message = message;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface DeleteUserResult {
  message?: string;
  error?: string;
}
interface DeleteUserResponse {
  message: string;
}

const deleteUser = async (id: string): Promise<DeleteUserResult> => {
  const result: DeleteUserResult = {};
  try {
    const {
      data: { message },
    } = await API.delete<DeleteUserResponse>(`/users/${id}`);
    result.message = message;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface ChangePasswordResult {
  message?: string;
  error?: string;
}
interface ChangePasswordResponse {
  message: string;
}

const changePassword = async (
  id: string,
  password: string
): Promise<ChangePasswordResult> => {
  const result: DeleteUserResult = {};
  try {
    const {
      data: { message },
    } = await API.patch<ChangePasswordResponse>(
      `/users/${id}/change-password`,
      {
        password,
      }
    );
    result.message = message;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface DeactivateUserResult {
  message?: string;
  error?: string;
}
interface DeactivateUserResponse {
  message: string;
}

const deactivateUser = async (id: string): Promise<DeactivateUserResult> => {
  const result: DeleteUserResult = {};
  try {
    const {
      data: { message },
    } = await API.patch<DeactivateUserResponse>(`/users/${id}/deactivate`);
    result.message = message;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface ActivateUserResult {
  message?: string;
  error?: string;
}
interface ActivateUserResponse {
  message: string;
}
const activateUser = async (id: string): Promise<ActivateUserResult> => {
  const result: DeleteUserResult = {};
  try {
    const {
      data: { message },
    } = await API.patch<ActivateUserResponse>(`/users/${id}/activate`);
    result.message = message;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

const UserService = {
  getAllUser,
  getUserById,
  createUser,
  updateUser,
  deleteUser,
  changePassword,
  deactivateUser,
  activateUser,
};

export default UserService;
