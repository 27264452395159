import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AppDowload from "src/pages/AppDowload";
import Activities from "src/pages/Activities";
import CreateActivity from "src/pages/Activities/CreateActivity";
import EditActivity from "src/pages/Activities/EditActivity";
import ViewActivity from "src/pages/Activities/ViewActivity";
import Brands from "src/pages/Brands";
import BrandUsers from "src/pages/Brands/BrandUsers";
import Dashboard from "src/pages/Dashboard";
import LoginPage from "src/pages/Login";
import PageNotFound from "src/pages/PageNotFound";
import Roles from "src/pages/Roles";
import UserList from "src/pages/Users";
import CreateUser from "src/pages/users/CreateUser";
import EditUser from "src/pages/users/EditUser";
import ViewUser from "src/pages/users/ViewUser";
import MaterialTypes from "src/pages/MaterialTypes";
import { loadSession } from "src/store/auth/actions";
import OutletTypes from "src/pages/OutletTypes";
import CreateCampaing from "src/pages/Campaigns/CreateCampaing";
import Campaigns from "src/pages/Campaigns";
import SelectBrandforCampaign from "src/pages/Campaigns/SelectBrandforCampaign";
import EditCampaign from "src/pages/Campaigns/EditCampaign";

const AppRoutes: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadSession());
  });

  return (
    <Router>
      <Switch>
        <Route exact path="/" component={LoginPage} />
        <Route exact path="/dashboard" component={Dashboard} />
        <Route exact path="/management/users" component={UserList} />
        <Route exact path="/management/users/new" component={CreateUser} />
        <Route exact path="/management/users/:id" component={ViewUser} />
        <Route exact path="/management/users/:id/edit" component={EditUser} />
        <Route exact path="/management/roles" component={Roles} />
        <Route exact path="/management/brands" component={Brands} />
        <Route
          exact
          path="/management/brands/:id/users"
          component={BrandUsers}
        />

        <Route
          exact
          path="/branding/campaign-brands"
          component={SelectBrandforCampaign}
        />
        <Route
          exact
          path="/branding/campaign-brands/:brandId/campaigns"
          component={Campaigns}
        />

        <Route
          exact
          path="/branding/campaign-brands/:brandId/campaigns/new"
          component={CreateCampaing}
        />

        <Route
          exact
          path="/branding/campaign-brands/:brandId/campaigns/:id/edit"
          component={EditCampaign}
        />
        <Route
          exact
          path="/branding/campaign-brands/:brandId/campaigns/:campaignId/activities"
          component={Activities}
        />
        <Route
          exact
          path="/branding/campaign-brands/:brandId/campaigns/:campaignId/activities/new"
          component={CreateActivity}
        />
        <Route
          exact
          path="/branding/campaign-brands/:brandId/campaigns/:campaignId/activities/:id"
          component={ViewActivity}
        />
        <Route
          exact
          path="/branding/campaign-brands/:brandId/campaigns/:campaignId/activities/:id/edit"
          component={EditActivity}
        />
        <Route
          exact
          path="/branding/material-types"
          component={MaterialTypes}
        />
        <Route exact path="/branding/outlet-types" component={OutletTypes} />
        <Route exact path="/download/app" component={AppDowload} />
        <Route exact path="/login" component={LoginPage} />
        <Route exact path="/404" component={PageNotFound} />

        <Route component={PageNotFound} />
      </Switch>
    </Router>
  );
};

export default AppRoutes;
