import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import { Attachment } from 'src/@types';
import AttachmentCard from '../AttachmentCard';

export interface AttachmentGridProps {
  attachments: Attachment[];
}

const AttachmentGrid: React.FC<AttachmentGridProps> = ({ attachments }) => {
  const [isLightBoxOpen, setLightBoxOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [images, setImages] = useState<string[]>([]);

  const handlePhotoOpen = (index: number) => {
    const images = attachments.map((attachment) => attachment.url ?? '');
    setImages(images);
    setPhotoIndex(index);
    setLightBoxOpen(true);
  };

  const handleCloseLightBox = () => {
    setImages([]);
    setPhotoIndex(0);
    setLightBoxOpen(false);
  };
  return (
    <>
      <Grid container spacing={3} className="mb-3">
        {attachments?.map((photo, index) => (
          <Grid item key={photo.id}>
            <AttachmentCard
              attachment={photo}
              size="small"
              onClick={() => handlePhotoOpen(index)}
            />
          </Grid>
        ))}
      </Grid>
      {isLightBoxOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={handleCloseLightBox}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex) => (photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() => setPhotoIndex((photoIndex) => (photoIndex + 1) % images.length)}
          reactModalStyle={{ overlay: { zIndex: 2000 } }}
        />
      )}
    </>
  );
};

export default AttachmentGrid;
