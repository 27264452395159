import { ActivityBoard } from "src/@types";
import { ActivityBoardStatusEnum } from "src/enums/activity-board-status.enum";

export const DEFAULT_ACTIVITY_BOARD_VALUE: ActivityBoard = {
  id: "",
  width: 1.0,
  height: 1.0,
  quantity: 1,
  remark: "",
  materialTypeId: "",
  materialType: null,
  receePhotos: [],
  newReceePhotos: [],
  deletedReceePhotos: [],
  installationPhotos: [],
  newInstallationPhotos: [],
  deletedInstallationPhotos: [],
  status: ActivityBoardStatusEnum.PENDING,
};
