import { KeyboardDatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import React, { useContext } from "react";
import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";
import DateUtilContext from "src/context/DateUtilContext";

interface DatePickerControllerPros<
  T extends FieldValues,
  TName extends FieldPath<T> = FieldPath<T>
> {
  control: Control<T>;
  error?: boolean;
  helperText?: string;
  label: string;
  className?: string;
  name: TName;
  id: string;
  shouldDisableDate?: (day: MaterialUiPickersDate) => boolean;
  disabled?: boolean;
  size?: "small" | "medium";
}

function DatePickerController<T>({
  control,
  error,
  helperText,
  label,
  className,
  id,
  name,
  shouldDisableDate,
  disabled,
  size,
}: DatePickerControllerPros<T>) {
  const { displayFormat } = useContext(DateUtilContext);
  return (
    <Controller
      render={({ field }) => (
        <KeyboardDatePicker
          id={id}
          margin="normal"
          format={displayFormat}
          inputVariant="outlined"
          disableFuture
          className={className}
          error={error}
          helperText={helperText}
          shouldDisableDate={shouldDisableDate}
          disabled={disabled}
          clearable
          size={size}
          {...field}
          label={label}
        />
      )}
      control={control}
      name={name}
    />
  );
}

export default DatePickerController;
