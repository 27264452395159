import { useState, useEffect } from "react";
import { ActivityFilterOption } from "src/@types";
import useHistoryState from "./useHistoryState";

export default function useActivityFilterOption(
  defaultFilterOption: ActivityFilterOption
) {
  const state = useHistoryState<ActivityFilterOption>();

  const [filterOption, setFilterOption] = useState(
    state ?? defaultFilterOption
  );

  useEffect(() => {
    if (state) {
      setFilterOption({ ...state });
    }
  }, [state]);

  return filterOption;
}
