import Joi from 'joi';

export const newAckPhotoSchema = Joi.object({
  id: Joi.string().required().messages({
    'string.empty': 'New acknowledgement photo id is required',
  }),
  name: Joi.string().required().messages({
    'string.empty': 'New acknowledgement photo name is required',
  }),
  contentType: Joi.string().required().messages({
    'string.empty': 'New acknowledgement photo content type is required',
  }),
  contentSize: Joi.number().required().messages({
    'any.required': 'New acknowledgement photo content size is required',
  }),
  base64: Joi.string().required().messages({
    'string.empty': 'New acknowledgement photo image is required',
  }),
}).unknown();

export const ackPhotoSchema = Joi.object({
  id: Joi.string().required().messages({
    'string.empty': 'Acknowledgement photo id is required',
  }),
  name: Joi.string().allow('', null).messages({
    'string.empty': 'Acknowledgement photo name is required',
  }),
  contentType: Joi.string().required().messages({
    'string.empty': 'Acknowledgement photo content type is required',
  }),
  contentSize: Joi.number().required().messages({
    'any.required': 'Acknowledgement photo content size is required',
  }),
  url: Joi.string().required().messages({
    'string.empty': 'Acknowledgement photo image is required',
  }),
}).unknown();
