import {
  Box,
  Button,
  Card,
  CardContent,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  TextField,
  Typography,
  Checkbox,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  Controller,
  FieldError,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { Activity, ActivityStatus, OutletType } from "src/@types";
import Lightbox from "react-image-lightbox";
import toast from "react-hot-toast";
import DatePickerController from "src/components/common/DatePickerController";
import AutocompleteController from "src/components/common/AutocompleteController";
import ActivityStatusService from "src/services/activity-status.service";
import OutletTypeService from "src/services/outlet-type.service";
import FileUploadButton from "src/components/common/FileUploadButton";
import { createAttachment } from "src/helpers/attachment";
import { useWatch } from "react-hook-form";
import AttachmentCard from "src/components/AttachmentCard";
import { MB_SIZE } from "src/config/file.constants";
import EditableBoardTable from "./EditableBoardTable";
import { activitySchema } from "src/validation/activity.schema";
import { joiResolver } from "@hookform/resolvers/joi";
import Map from "src/components/Map";

interface ActivityFormProps {
  mode: "create" | "edit";
  activity: Activity;
  onSave?: (activity: Activity) => void;
}

const ActivityForm: React.FC<ActivityFormProps> = ({
  mode,
  activity,
  onSave,
}) => {
  const {
    formState: { errors },
    handleSubmit,
    control,
    setValue,
    clearErrors,
  } = useForm<Activity>({
    defaultValues: activity,
    mode: "onSubmit",
    resolver: joiResolver(activitySchema),
  });

  const { fields: storePhotoFields, remove: removeStorePhoto } = useFieldArray({
    control,
    name: "storePhotos",
  });

  const {
    fields: newStorePhotoFields,
    append: appendNewStorePhoto,
    remove: removeNewStorePhoto,
  } = useFieldArray({
    control,
    name: "newStorePhotos",
  });

  const { append: appendDeletedStorePhoto } = useFieldArray({
    control,
    name: "deletedStorePhotos",
  });

  const { fields: ackPhotoFields, remove: removeAckPhoto } = useFieldArray({
    control,
    name: "ackPhotos",
  });

  const {
    fields: newAckPhotoFields,
    append: appendNewAckPhoto,
    remove: removeNewAckPhoto,
  } = useFieldArray({
    control,
    name: "newAckPhotos",
  });

  const { append: appendDeletedAckPhoto } = useFieldArray({
    control,
    name: "deletedAckPhotos",
  });

  const [statuses, setStatuses] = useState<ActivityStatus[]>([]);
  const [outletTypes, setOutletTypes] = useState<OutletType[]>([]);

  const [isLightBoxOpen, setLightBoxOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [images, setImages] = useState<string[]>([]);

  const lat = useWatch({
    name: "outlet.lat",
    control,
    // defaultValue: 19.013748,
  });
  const lng = useWatch({
    name: "outlet.lng",
    control,
    // defaultValue: 72.841928,
  });

  const getAllActivityStatus = async () => {
    const { activityStatuses } =
      await ActivityStatusService.getAllActivityStatus();

    if (activityStatuses) {
      setStatuses(activityStatuses);
    }
  };

  const getAllOutletType = async () => {
    const { outletTypes } = await OutletTypeService.getAllOutletType({
      filterType: "all",
    });

    if (outletTypes) {
      setOutletTypes(outletTypes);
    }
  };

  useEffect(() => {
    getAllActivityStatus();
    getAllOutletType();
  }, []);

  const handleStorePhotoSelect = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      if (file.size > MB_SIZE) {
        toast.error("File size is too large");
      } else {
        const attachment = await createAttachment(file);
        appendNewStorePhoto(attachment);
        clearErrors("newStorePhotos");
      }
    }
  };

  const handleAckPhotoSelect = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      if (file.size > MB_SIZE) {
        toast.error("File size is too large");
      } else {
        const attachment = await createAttachment(file);
        appendNewAckPhoto(attachment);
        clearErrors("newAckPhotos");
      }
    }
  };

  const handleCloseLightBox = () => {
    setImages([]);
    setPhotoIndex(0);
    setLightBoxOpen(false);
  };

  console.log(errors);

  const onSubmit = (data: Activity) => {
    onSave?.(data);
  };

  return (
    <Card>
      <CardContent>
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <Box marginBottom={2}>
            <Typography variant="h6">Basic details</Typography>
          </Box>
          <Box marginBottom={2}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={3}>
                <DatePickerController
                  label="Upload date"
                  className="w-full m-0"
                  error={!!errors?.uploadDate}
                  helperText={errors?.uploadDate?.message}
                  control={control}
                  id="uploadDate"
                  name="uploadDate"
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <Controller
                  name="code"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      label="Code"
                      className="w-full"
                      id="code"
                      error={!!errors.code}
                      helperText={errors.code?.message}
                      size="small"
                      {...field}
                      disabled={mode === "edit"}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <Controller
                  name="campaign.name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      label="Campaign"
                      className="w-full"
                      id="code"
                      error={!!errors.campaign?.name}
                      helperText={errors.campaign?.name?.message}
                      size="small"
                      {...field}
                      disabled
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={3}>
                <AutocompleteController
                  options={statuses}
                  getOptionLabel={(status) => status.name}
                  getOptionSelected={(option, value) => option.id === value.id}
                  label="Status"
                  placeholder="Status"
                  className="w-full"
                  control={control}
                  name="status"
                  id="status"
                  error={!!errors.status}
                  helperText={
                    (errors?.status as FieldError | undefined)?.message
                  }
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <DatePickerController
                  label="Recee date"
                  className="w-full m-0"
                  error={!!errors?.receeDate}
                  helperText={errors?.receeDate?.message}
                  control={control}
                  id="receeDate"
                  name="receeDate"
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <DatePickerController
                  label="Installation date"
                  className="w-full m-0"
                  error={!!errors?.installationDate}
                  helperText={errors?.installationDate?.message}
                  control={control}
                  id="installationDate"
                  name="installationDate"
                  size="small"
                />
              </Grid>
            </Grid>
          </Box>
          <Box marginBottom={2}>
            <Typography variant="h6">Outlet detail</Typography>
          </Box>
          <Box marginBottom={2}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={3}>
                <Controller
                  name="outlet.outletName"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      label="Outlet Name"
                      className="w-full"
                      id="name"
                      error={!!errors.outlet?.outletName}
                      helperText={errors.outlet?.outletName?.message}
                      size="small"
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <Controller
                  name="outlet.retailerName"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      label="Retailer Name"
                      className="w-full"
                      id="name"
                      error={!!errors.outlet?.retailerName}
                      helperText={errors.outlet?.retailerName?.message}
                      size="small"
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <Controller
                  name="outlet.mobile"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      label="Mobile"
                      className="w-full"
                      id="name"
                      error={!!errors.outlet?.mobile}
                      helperText={errors.outlet?.mobile?.message}
                      size="small"
                      {...field}
                    />
                  )}
                />
              </Grid>
              {/* <Grid item xs={12} sm={12} md={3}>
                <AutocompleteController
                  options={outletTypes}
                  getOptionLabel={(outletType) => outletType.name}
                  getOptionSelected={(option, value) => option.id === value.id}
                  label="Outlet type"
                  placeholder="Outlet type"
                  className="w-full"
                  control={control}
                  name="outlet.outletType"
                  id="outlet.outletType"
                  error={!!errors.outlet?.outletType}
                  helperText={
                    (errors?.outlet?.outletType as FieldError | undefined)
                      ?.message
                  }
                  size="small"
                />
              </Grid> */}
              {/* <Grid item xs={12} sm={12} md={3}>
                <Controller
                  name="outlet.registeredBy"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      label="Registered By"
                      className="w-full"
                      id="registeredBy"
                      error={!!errors.outlet?.registeredBy}
                      helperText={errors.outlet?.registeredBy?.message}
                      size="small"
                      {...field}
                    />
                  )}
                />
              </Grid> */}
              {/* <Grid item xs={12} sm={12} md={3}>
                <DatePickerController
                  label="Date of Registration"
                  className="w-full m-0"
                  error={!!errors?.outlet?.registrationDate}
                  helperText={errors?.outlet?.registrationDate?.message}
                  control={control}
                  id="outlet.registrationDate"
                  name="outlet.registrationDate"
                  size="small"
                />
              </Grid> */}
              <Grid item xs={12} sm={12} md={3}>
                <Controller
                  name="outlet.alternateMobile"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      label="Retailer Alternate mobile"
                      className="w-full"
                      id="outlet.alternateMobile"
                      error={!!errors.outlet?.alternateMobile}
                      helperText={errors.outlet?.alternateMobile?.message}
                      size="small"
                      {...field}
                    />
                  )}
                />
              </Grid>
              {/* <Grid item xs={12} sm={12} md={3}>
                <DatePickerController
                  label="Last login"
                  className="w-full m-0"
                  error={!!errors?.outlet?.lastLogin}
                  helperText={errors?.outlet?.lastLogin?.message}
                  control={control}
                  id="outlet.lastLogin"
                  name="outlet.lastLogin"
                  size="small"
                />
              </Grid> */}
              {/* <Grid item xs={12}>
                <FormGroup row>
                  <Controller
                    name="outlet.isMechanic"
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={<Checkbox color="primary" />}
                        label="Whether mechanic?"
                        checked={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                  <Controller
                    name="outlet.hasSmartPhone"
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            defaultChecked={field.value}
                            {...field}
                          />
                        }
                        label="Smart phone present?"
                      />
                    )}
                  />
                  <Controller
                    name="outlet.hasMobileApp"
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            defaultChecked={field.value}
                            {...field}
                          />
                        }
                        label="Retailer using mobile app?"
                      />
                    )}
                  />
                  <Controller
                    name="outlet.isBoardAvailable"
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            defaultChecked={field.value}
                            {...field}
                          />
                        }
                        label="Board Available?"
                      />
                    )}
                  />
                </FormGroup>
              </Grid> */}
            </Grid>
          </Box>
          <Box marginBottom={2}>
            <Typography variant="h6">Outlet location</Typography>
          </Box>
          <Box marginBottom={2}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={8}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={6}>
                    <Controller
                      name="outlet.state"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="State"
                          className="w-full"
                          id="state"
                          error={!!errors.outlet?.state}
                          helperText={errors.outlet?.state?.message}
                          size="small"
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Controller
                      name="outlet.town"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="Town"
                          className="w-full"
                          id="town"
                          error={!!errors.outlet?.town}
                          helperText={errors.outlet?.town?.message}
                          size="small"
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Controller
                      name="outlet.pincode"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="Pincode"
                          className="w-full"
                          id="pincode"
                          error={!!errors.outlet?.pincode}
                          helperText={errors.outlet?.pincode?.message}
                          size="small"
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Controller
                      name="outlet.landmark"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="Landmark"
                          className="w-full"
                          id="landmark"
                          error={!!errors.outlet?.landmark}
                          helperText={errors.outlet?.landmark?.message}
                          size="small"
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Controller
                      name="outlet.fullAddress"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="Full Address"
                          className="w-full"
                          id="fullAddress"
                          error={!!errors.outlet?.fullAddress}
                          helperText={errors.outlet?.fullAddress?.message}
                          size="small"
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={4}>
                {lat && lng && (
                  <>
                    <Map
                      lat={lat}
                      lng={lng}
                      onClick={(_lat, _lng) => {
                        setValue("outlet.lat", _lat);
                        setValue("outlet.lng", _lng);
                      }}
                    />
                    <Controller
                      name="outlet.lat"
                      control={control}
                      render={({ field }) => <input type="hidden" />}
                    />
                    <Controller
                      name="outlet.lng"
                      control={control}
                      render={({ field }) => <input type="hidden" />}
                    />
                  </>
                )}
              </Grid>
            </Grid>
          </Box>
          <Box marginBottom={2}>
            <Typography variant="h6">Board</Typography>
          </Box>
          <Box marginBottom={2}>
            <EditableBoardTable
              data={activity.boards}
              onChange={(result) => {
                setValue("newBoards", result.newBoards);
                setValue("boards", result.boards);
                setValue("deletedBoards", result.deletedBoards);
              }}
            />
            <FormHelperText error={!!errors.boards}>
              {(errors.boards as any as FieldError)?.message}
            </FormHelperText>
          </Box>
          <Box marginBottom={2}>
            <Typography variant="h6">Store photos</Typography>
          </Box>
          <Box marginBottom={2}>
            <Grid container spacing={3} className="mb-3">
              {storePhotoFields.map((storePhotoField, index) => (
                <Grid item key={storePhotoField.id}>
                  <Controller
                    control={control}
                    name={`storePhotos.${index}` as const}
                    render={({ field }) => (
                      <AttachmentCard
                        attachment={storePhotoField}
                        onDelete={() => {
                          removeStorePhoto(index);
                          appendDeletedStorePhoto(storePhotoField);
                        }}
                        size="medium"
                        showDelete
                      />
                    )}
                  />
                </Grid>
              ))}
              {newStorePhotoFields.map((newStorePhotoField, index) => (
                <Grid item key={newStorePhotoField.id}>
                  <Controller
                    control={control}
                    name={`newStorePhotos.${index}` as const}
                    defaultValue={newStorePhotoField}
                    render={({ field }) => (
                      <AttachmentCard
                        attachment={newStorePhotoField}
                        onDelete={() => removeNewStorePhoto(index)}
                        size="medium"
                        showDelete
                      />
                    )}
                  />
                </Grid>
              ))}
            </Grid>
            <FileUploadButton
              variant="outlined"
              onChange={handleStorePhotoSelect}
              accept="image/png,image/jpeg"
              id="store-photo"
            >
              Browse
            </FileUploadButton>
            <FormHelperText error={!!errors.storePhotos}>
              {(errors.storePhotos as any as FieldError)?.message}
            </FormHelperText>
          </Box>
          <Box marginBottom={2}>
            <Typography variant="h6">Acknowledgment photos</Typography>
          </Box>

          <Box marginBottom={2}>
            <Grid container spacing={3} className="mb-3">
              {ackPhotoFields.map((ackPhotoField, index) => (
                <Grid item key={ackPhotoField.id}>
                  <Controller
                    control={control}
                    name={`ackPhotos.${index}` as const}
                    render={({ field }) => (
                      <AttachmentCard
                        attachment={ackPhotoField}
                        onDelete={() => {
                          removeAckPhoto(index);
                          appendDeletedAckPhoto(ackPhotoField);
                        }}
                        size="medium"
                        showDelete
                      />
                    )}
                  />
                </Grid>
              ))}
              {newAckPhotoFields.map((newAckPhotoField, index) => (
                <Grid item key={newAckPhotoField.id}>
                  <Controller
                    control={control}
                    name={`newAckPhotos.${index}` as const}
                    defaultValue={newAckPhotoField}
                    render={({ field }) => (
                      <AttachmentCard
                        attachment={newAckPhotoField}
                        onDelete={() => removeNewAckPhoto(index)}
                        size="medium"
                        showDelete
                      />
                    )}
                  />
                </Grid>
              ))}
            </Grid>
            <FileUploadButton
              variant="outlined"
              onChange={handleAckPhotoSelect}
              accept="image/png,image/jpeg"
              id="ack-photo"
            >
              Browse
            </FileUploadButton>
            <FormHelperText error={!!errors.ackPhotos}>
              {(errors.ackPhotos as any as FieldError)?.message}
            </FormHelperText>
          </Box>
          <Grid container>
            <Button
              variant="contained"
              color="primary"
              size="large"
              disableElevation
              className="mb-5"
              type="submit"
              // disabled={!isValid}
            >
              {mode === "create" ? "Create" : "Save"}
            </Button>
          </Grid>
        </Box>
        {isLightBoxOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={handleCloseLightBox}
            onMovePrevRequest={() =>
              setPhotoIndex(
                (photoIndex) => (photoIndex + images.length - 1) % images.length
              )
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex) => (photoIndex + 1) % images.length)
            }
            reactModalStyle={{ overlay: { zIndex: 2000 } }}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default ActivityForm;
