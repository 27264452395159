import React from 'react';
import cx from 'classnames';
import { Typography } from '@material-ui/core';
import Loading from 'src/components/Images/Loading';

interface LoadingIndicatorProps {
  isLoading: boolean;
  imgClassName?: string;
}

const LoadingIndicator: React.FunctionComponent<LoadingIndicatorProps> = ({
  isLoading,
  children,
  imgClassName,
}) => {
  if (!isLoading) {
    return <>{children}</>;
  }
  return (
    <div className="flex flex-col w-full items-center p-4">
      <Loading className={cx(imgClassName ?? 'w-4/12 ', 'h-auto mb-2')} />
      <Typography variant="subtitle1">Loading data...</Typography>
    </div>
  );
};

export default LoadingIndicator;
