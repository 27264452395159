import { useEffect, useState } from "react";
import { Campaign } from "src/@types";
import { CampaignService } from "src/services/campaign.service";

export function useCampaign(id: string | null) {
  const [campaign, setCampaign] = useState<Campaign | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getById = async () => {
      if (!id) {
        return;
      }
      setLoading(true);
      const { campaign } = await CampaignService.getById(id);
      setLoading(false);

      if (campaign) {
        setCampaign(campaign);
      }
    };

    getById();
    // eslint-disable-next-line
  }, [id]);
  return { campaign, loading };
}
