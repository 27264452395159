import { Brand, CampaignBrand, QueryFilterType } from "src/@types";
import API from "src/helpers/api";
import { getErrorMessage } from "src/helpers/axios";
import qs from "qs";

export interface GetAllBrandOptions {
  page?: number;
  size?: number;
  query?: string;
  filterType: QueryFilterType;
}

export interface GetAllBrandResult {
  brands?: Brand[];
  count?: number;
  error?: string;
}

export interface GetAllBrandResponse {
  brands: Brand[];
  count: number;
}

const getAllBrand = async ({
  page,
  size,
  query,
  filterType,
}: GetAllBrandOptions) => {
  const result: GetAllBrandResult = {};
  try {
    const queryString = qs.stringify({ page, size, query, filterType });
    const {
      data: { brands, count },
    } = await API.get<GetAllBrandResponse>(`/brands?${queryString}`, {});
    result.brands = brands;
    result.count = count;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

export interface GetAllCampaignBrandOptions {
  page?: number;
  size?: number;
  query?: string;
  filterType: QueryFilterType;
}

export interface GetAllCampaignBrandResult {
  brands?: CampaignBrand[];
  count?: number;
  error?: string;
}

export interface GetAllCampaignBrandResponse {
  brands: CampaignBrand[];
  count: number;
}

const getAllCampaignBrand = async ({
  page,
  size,
  query,
  filterType,
}: GetAllCampaignBrandOptions) => {
  const result: GetAllCampaignBrandResult = {};
  try {
    const queryString = qs.stringify({ page, size, query, filterType });
    const {
      data: { brands, count },
    } = await API.get<GetAllCampaignBrandResponse>(
      `/brands/getAllCampaignBrand?${queryString}`,
      {}
    );
    result.brands = brands;
    result.count = count;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface GetBrandByIdResult {
  brand?: Brand;
  error?: string;
}

interface GetBrandByIdResponse {
  brand: Brand;
}

const getBrandById = async (brandId: string) => {
  const result: GetBrandByIdResult = {};
  try {
    const {
      data: { brand },
    } = await API.get<GetBrandByIdResponse>(`/brands/${brandId}`);
    result.brand = brand;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface CreateBrandResponse {
  brand: Brand;
  message: string;
}
interface CreateBrandResult {
  brand?: Brand;
  message?: string;
  error?: string;
}

const createBrand = async (brandData: Brand) => {
  const result: CreateBrandResult = {};
  try {
    const {
      data: { brand, message },
    } = await API.post<CreateBrandResponse>("/brands", brandData);
    result.brand = brand;
    result.message = message;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface UpdateBrandResponse {
  brand: Brand;
  message: string;
}

interface UpdateBrandResult {
  brand?: Brand;
  message?: string;
  error?: string;
}

const updateBrand = async (brandData: Brand) => {
  const result: UpdateBrandResult = {};
  try {
    // const brandFormData: BrandFormData = transformToBrandFormData(brandData);
    const {
      data: { brand, message },
    } = await API.patch<UpdateBrandResponse>(
      `brands/${brandData.id}`,
      brandData
    );
    result.brand = brand;
    result.message = message;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface DeleteBrandResult {
  message?: string;
  error?: string;
}
interface DeleteBrandResponse {
  message: string;
}

const deleteBrand = async (id: string): Promise<DeleteBrandResult> => {
  const result: DeleteBrandResult = {};
  try {
    const {
      data: { message },
    } = await API.delete<DeleteBrandResponse>(`/brands/${id}`);
    result.message = message;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface DeactivateBrandResult {
  message?: string;
  error?: string;
}
interface DeactivateBrandResponse {
  message: string;
}

const deactivateBrand = async (id: string): Promise<DeactivateBrandResult> => {
  const result: DeleteBrandResult = {};
  try {
    const {
      data: { message },
    } = await API.put<DeactivateBrandResponse>(`/brands/${id}/deactivate`);
    result.message = message;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface ActivateBrandResult {
  message?: string;
  error?: string;
}
interface ActivateBrandResponse {
  message: string;
}
const activateBrand = async (id: string): Promise<ActivateBrandResult> => {
  const result: DeleteBrandResult = {};
  try {
    const {
      data: { message },
    } = await API.put<ActivateBrandResponse>(`/brands/${id}/activate`);
    result.message = message;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

const BrandService = {
  getAllBrand,
  getBrandById,
  createBrand,
  updateBrand,
  deleteBrand,
  deactivateBrand,
  activateBrand,
  getAllCampaignBrand,
};

export default BrandService;
