import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  InputAdornment,
  Box,
  Button,
  Typography,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { ActivityStatus, MaterialType, OutletType } from "src/@types";
import MaterialTypeService from "src/services/material-type.service";
import OutletTypeService from "src/services/outlet-type.service";
import CanView from "src/components/RoleBasedAccess/CanView";
import { ROLE } from "src/enums/role";
import { Controller, useForm } from "react-hook-form";
import DatePickerController from "src/components/common/DatePickerController";
import AutocompleteController from "src/components/common/AutocompleteController";
import {
  PartialActivityFilterOption,
  ActivityFilterOption,
} from "src/@types";
import { isDateBefore } from "src/util/datetime";
import ActivityStatusService from "src/services/activity-status.service";

interface QueryFilterPanelProps {
  onSearch?: (data: PartialActivityFilterOption) => void;
  onClear?: (data: PartialActivityFilterOption) => void;
  filterOption: ActivityFilterOption;
  count: number;
}

const defaultQuertFilterValues: PartialActivityFilterOption = {
  query: "",
  statuses: [],
  state: "",
  town: "",
  outletTypes: [],
  materialTypes: [],
  startDate: null,
  endDate: null,
};

const QueryFilterPanel: React.FC<QueryFilterPanelProps> = ({
  onSearch,
  filterOption,
}) => {
  const [activityStatuses, setActivityStatuses] = useState<ActivityStatus[]>(
    []
  );
  const [materialTypes, setMaterialTypes] = useState<MaterialType[]>([]);
  const [outletTypes, setOutletTypes] = useState<OutletType[]>([]);

  const { handleSubmit, control, reset, watch } =
    useForm<PartialActivityFilterOption>({
      mode: "all",
      defaultValues: filterOption,
    });

  const getAlActivityStatus = async () => {
    const { activityStatuses } =
      await ActivityStatusService.getAllActivityStatus();
    if (activityStatuses) {
      setActivityStatuses(activityStatuses);
    }
  };

  const getAllOutletType = async () => {
    const { outletTypes } = await OutletTypeService.getAllOutletType({
      filterType: "all",
    });
    if (outletTypes) {
      setOutletTypes(outletTypes);
    }
  };

  const getAllMaterialType = async () => {
    const { materialTypes } = await MaterialTypeService.getAllMaterialType({
      filterType: "all",
    });
    if (materialTypes) {
      setMaterialTypes(materialTypes);
    }
  };

  useEffect(() => {
    getAlActivityStatus();
    getAllOutletType();
    getAllMaterialType();
  }, []);

  const handleSearchClick = (data: PartialActivityFilterOption) => {
    onSearch?.(data);
  };

  const handleClearClick = () => {
    reset(defaultQuertFilterValues);
    onSearch?.(defaultQuertFilterValues);
  };

  const startDate = watch("startDate");

  return (
    <Box component="form" onSubmit={handleSubmit(handleSearchClick)}>
      <Typography variant="h6" className="pt-4 px-4">
        Filter Branding Activity
      </Typography>
      <Grid container spacing={3} className="pt-2 px-5 mb-2">
        <Grid item xs={12} sm={12} md={12}>
          <Controller
            name="query"
            control={control}
            render={({ field }) => (
              <TextField
                variant="outlined"
                placeholder="Search activity"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                className="w-full"
                size="small"
                {...field}
              />
            )}
          />
        </Grid>
        <CanView roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER]}>
          <Grid item xs={12} sm={12} md={12}>
            <AutocompleteController
              options={activityStatuses}
              getOptionLabel={(activityStatus) => activityStatus.name}
              getOptionSelected={(option, value) => option.id === value.id}
              label="Status"
              placeholder="Status"
              className="w-full"
              control={control}
              name="statuses"
              id="statuses"
              size="small"
              multiple
            />
          </Grid>
        </CanView>

        <Grid item xs={12} sm={12} md={12}>
          <DatePickerController
            label="Start date"
            className="w-full m-0"
            control={control}
            id="startDate"
            name="startDate"
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <DatePickerController
            label="End date"
            className="w-full m-0"
            control={control}
            id="endDate"
            name="endDate"
            shouldDisableDate={(day) =>
              !!startDate && isDateBefore(day as Date, startDate)
            }
            disabled={!startDate}
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <AutocompleteController
            options={outletTypes}
            getOptionLabel={(outletType) => outletType.name}
            getOptionSelected={(option, value) => option.id === value.id}
            label="Outlet Types"
            placeholder="Outlet Types"
            className="w-full"
            control={control}
            name="outletTypes"
            id="outletTypes"
            size="small"
            multiple
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <AutocompleteController
            options={materialTypes}
            getOptionLabel={(materialType) => materialType.name}
            getOptionSelected={(option, value) => option.id === value.id}
            label="Material Type"
            placeholder="Material Type"
            className="w-full"
            control={control}
            name="materialTypes"
            id="materialTypes"
            size="small"
            multiple
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Controller
            name="state"
            control={control}
            render={({ field }) => (
              <TextField
                variant="outlined"
                placeholder="State"
                className="w-full"
                size="small"
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Controller
            name="town"
            control={control}
            render={({ field }) => (
              <TextField
                variant="outlined"
                placeholder="Town"
                className="w-full"
                size="small"
                {...field}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="space-between"
        spacing={3}
        className="px-5"
      >
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            className="mr-4"
            type="submit"
          >
            Search
          </Button>
          <Button
            variant="contained"
            disableElevation
            onClick={handleClearClick}
          >
            Clear
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default QueryFilterPanel;
