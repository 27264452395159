import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@material-ui/core";
import React, { useCallback } from "react";
import DialogTitle from "src/components/common/Dialog/DialogTitle";
import BrandUserService from "src/services/brand-user.service";
import toast from "react-hot-toast";

interface DeleteBrandUserDialogProps {
  open: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
  brandId: string;
  userId: string;
}

const DeleteBrandUserDialog: React.FC<DeleteBrandUserDialogProps> = ({
  open,
  onClose,
  onSuccess,
  userId,
  brandId,
}) => {
  const handleDelete = useCallback(async () => {
    const { message, error } = await BrandUserService.deleteBrandUser(
      userId,
      brandId
    );
    if (error) {
      toast.error(error);
    } else if (message) {
      toast.success(message);
      onSuccess?.();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      PaperProps={{
        className: "w-4/12",
      }}
    >
      <DialogTitle title="Remove user" onClose={onClose} />
      <DialogContent>
        <Typography>
          Are you user you want to remove the user? The changes can't be
          reverted back!!
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          Cancel
        </Button>
        <Button color="secondary" onClick={handleDelete}>
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteBrandUserDialog;
