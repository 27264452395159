import { BrandUser } from "src/@types";
import API from "src/helpers/api";
import { getErrorMessage } from "src/helpers/axios";

interface GetAllBrandUserResult {
  brandUsers?: BrandUser[];
  count?: number;
  error?: string;
}

interface GetAllBrandUserResponse {
  brandUsers: BrandUser[];
  count: number;
}

const getAllBrandUserByBrandId = async (brandId: string) => {
  const result: GetAllBrandUserResult = {};
  try {
    const {
      data: { brandUsers, count },
    } = await API.get<GetAllBrandUserResponse>(`/brands/${brandId}/users`, {});
    result.brandUsers = brandUsers;
    result.count = count;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface CreateBrandUserOptions {
  brandId: string;
  userId: string;
}
interface CreateBrandUserResponse {
  brandUser: BrandUser;
  message: string;
}
interface CreateBrandUserResult {
  brandUser?: BrandUser;
  message?: string;
  error?: string;
}

const createBrandUser = async (options: CreateBrandUserOptions) => {
  const result: CreateBrandUserResult = {};
  try {
    const {
      data: { brandUser, message },
    } = await API.post<CreateBrandUserResponse>(
      `/brands/${options.brandId}/users`,
      options
    );
    result.brandUser = brandUser;
    result.message = message;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface DeleteBrandUserResult {
  message?: string;
  error?: string;
}
interface DeleteBrandUserResponse {
  message: string;
}

const deleteBrandUser = async (
  userId: string,
  brandId: string
): Promise<DeleteBrandUserResult> => {
  const result: DeleteBrandUserResult = {};
  try {
    const {
      data: { message },
    } = await API.delete<DeleteBrandUserResponse>(
      `/brands/${brandId}/users/${userId}`
    );
    result.message = message;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

const BrandUserService = {
  getAllBrandUserByBrandId,
  createBrandUser,
  deleteBrandUser,
};

export default BrandUserService;
