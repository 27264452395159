import {
  AppBar,
  Avatar,
  Button,
  ClickAwayListener,
  IconButton,
  MenuItem,
  MenuList,
  Popover,
  Toolbar,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'src/store';
import { AuthState } from 'src/store/auth/types';
import { logout } from 'src/store/auth/actions';
import { Link, useHistory } from 'react-router-dom';
import AuthService from 'src/services/auth.service';
import MenuIcon from '@material-ui/icons/Menu';

const HeaderWrapper = styled(AppBar)`
  z-index: 1201;
`;

interface HeaderProps {}

const Header: React.FC<HeaderProps> = () => {
  const { user, authenticated } = useSelector<RootState, AuthState>((state) => state.authState);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const doLogout = async () => {
    setAnchorEl(null);
    try {
      await AuthService.logout();
      await dispatch(logout());
      history.push('/');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <HeaderWrapper position="fixed" elevation={0} className="border-b border-gray-300">
      <Toolbar>
        <IconButton edge="start" color="inherit" aria-label="menu" className="mr-2">
          <MenuIcon />
        </IconButton>
        <div className="flex-grow">
          <Typography variant="h5" component={Link} to="/">
            Visibility Hub
          </Typography>
        </div>

        {!authenticated && (
          <Button color="primary" component={Link} to="/login">
            Login
          </Button>
        )}
        {authenticated && (
          <div>
            <Button onClick={(event) => setAnchorEl(event.currentTarget)} disableElevation>
              <Avatar alt={user?.firstName} src={user?.imageUrl} />
              <Typography variant="button" className="ml-2 text-white">
                {user?.firstName}
              </Typography>
            </Button>
          </div>
        )}
      </Toolbar>

      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
          <MenuList>
            <MenuItem onClick={doLogout}>Logout</MenuItem>
          </MenuList>
        </ClickAwayListener>
      </Popover>
    </HeaderWrapper>
  );
};

export default Header;
