import { Role } from 'src/@types/user';
import { ROLE } from 'src/enums/role';

export function hasRole(userRoles: Role[], rolesRequired: ROLE[]): boolean {
  return userRoles?.some((role) => (rolesRequired as string[]).indexOf(role.id) > -1);
}

export function hasAnyRoleTillManager(userRoles: Role[]) {
  return hasRole(userRoles, [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER]);
}

export function hasManagerRole(userRoles: Role[]) {
  return hasRole(userRoles, [ROLE.MANAGER]);
}

export function hasAdminRole(userRoles: Role[]) {
  return hasRole(userRoles, [ROLE.ADMIN]);
}

export function hasSuperAdminRole(userRoles: Role[]) {
  return hasRole(userRoles, [ROLE.SUPER_ADMIN]);
}

export function hasPromoterRole(userRoles: Role[]) {
  return hasRole(userRoles, [ROLE.PROMOTER]);
}
