import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@material-ui/core";
import toast from "react-hot-toast";
import React, { useCallback } from "react";
import DialogTitle from "src/components/common/Dialog/DialogTitle";
import OutletTypeService from "src/services/outlet-type.service";

interface DeleteOutletTypeDialogProps {
  open: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
  outletTypeId: string;
}

const DeleteOutletTypeDialog: React.FC<DeleteOutletTypeDialogProps> = ({
  open,
  onClose,
  onSuccess,
  outletTypeId,
}) => {
  const handleDelete = useCallback(async () => {
    const { message, error } = await OutletTypeService.deleteOutletType(
      outletTypeId
    );
    if (error) {
      toast.error(error);
    } else if (message) {
      toast.success(message);
      onSuccess?.();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      PaperProps={{
        className: "w-4/12",
      }}
    >
      <DialogTitle title="Delete outlet type" onClose={onClose} />
      <DialogContent>
        <Typography>
          Are you user you want to delete the outlet type? The changes can't be
          reverted back!!
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          Cancel
        </Button>
        <Button color="secondary" onClick={handleDelete}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteOutletTypeDialog;
