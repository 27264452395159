import {
  Button,
  Drawer,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import Breadcrumbs, { BreadcrumbsLink } from 'src/components/common/Breadcrumbs/Breadcrumbs';
import Empty from 'src/components/common/Empty';
import LoadingIndicator from 'src/components/common/LoadingIndicator';
import MainLayout from 'src/components/MainLayout';
import { ROLE } from 'src/enums/role';
import withAuth from 'src/hocs/withAuth';
import withRoles from 'src/hocs/withRoles';
import ActivityService from 'src/services/activity.service';
import { Activity, ActivityFilterOption, PartialActivityFilterOption } from 'src/@types';
import { Link, useHistory, useParams } from 'react-router-dom';
import CanView from 'src/components/RoleBasedAccess/CanView';
import ActivityTableRow from 'src/containers/Activity/ActivityTableRow';
import QueryFilterPanel from 'src/containers/Activity/QueryFilterPanel';
import Pagination from 'src/components/common/Pagination';
import GetAppIcon from '@material-ui/icons/GetApp';
import FileSaver from 'file-saver';
import toast from 'react-hot-toast';
import { formatDateTime } from 'src/util/datetime';
import FilterListIcon from '@material-ui/icons/FilterList';
import ClearIcon from '@material-ui/icons/Clear';
import useActivityFilterOption from 'src/hooks/useActivityFilterOption';
import qs from 'qs';
import Checkbox from '@material-ui/core/Checkbox';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useCampaign } from 'src/hooks/useCampaign';
import { getActivitiesRoute, getCampaignBrandsRoute, getCampaignsRoute } from 'src/helpers/routes';

interface ActivityTableRowState {
  id: string;
  checked: boolean;
}

const defaultFilterOption: ActivityFilterOption = {
  query: '',
  page: 1,
  size: 20,
  filterType: 'query',
  statuses: [],
  state: '',
  town: '',
  outletTypes: [],
  materialTypes: [],
  startDate: null,
  endDate: null,
  campaignIds: [],
};

const Activities = () => {
  const history = useHistory();

  const { brandId, campaignId } = useParams<{ brandId: string; campaignId: string }>();
  const { campaign } = useCampaign(campaignId);

  const campaignBrandsRoute = getCampaignBrandsRoute();
  const campaignsRoute = getCampaignsRoute({ brandId });
  const activitiesRoute = getActivitiesRoute({ brandId, campaignId });

  const links: BreadcrumbsLink[] = [
    {
      label: 'Dashboard',
      href: '/dashboard',
    },
    {
      label: 'Branding',
      href: campaignBrandsRoute,
    },
    {
      label: 'Campaign Brands',
      href: campaignBrandsRoute,
    },
    {
      label: 'Campaigns',
      href: campaignsRoute,
    },
    {
      label: 'Activities',
    },
  ];

  const campaignIds = campaignId ? [campaignId] : [];
  const filterOption = useActivityFilterOption({
    ...defaultFilterOption,
    campaignIds,
  });

  const [activities, setActivities] = useState<Activity[]>([]);

  const [loading, setLoading] = useState(false);

  const rowsPerPageOptions = [10, 20, 30, 40, 50];
  const [activityCount, setActivityCount] = useState(0);
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const [rowSelectionList, setRowSelectionList] = useState<ActivityTableRowState[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [downloadingExcel, setDownloadingExcel] = useState(false);
  const [downloadingPPT, setDownloadingPPT] = useState(false);

  const isRowSelected = useMemo(
    () => rowSelectionList.some((row) => row.checked),
    [rowSelectionList]
  );
  const maxNoOfBoards = useMemo(
    () => activities.reduce((max, activity) => Math.max(activity.boards?.length ?? 0, max), 0),
    [activities]
  );
  const boardColumns = useMemo(() => {
    const columns = [];

    for (let start = 1; start <= maxNoOfBoards; start++) {
      columns.push(`Board ${start} Material`);
      columns.push(`Board ${start} Recee`);
      columns.push(`Board ${start} Installation`);
    }
    return columns;
  }, [maxNoOfBoards]);

  const handleChangePage = (event: React.ChangeEvent<unknown> | undefined, newPage: number) => {
    const queryString = qs.stringify({
      page: newPage,
      size: filterOption.size,
      campaignId,
    });
    history.push(`${activitiesRoute}?${queryString}`, {
      ...filterOption,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const size = parseInt(event.target.value as string, 10);
    const queryString = qs.stringify({
      page: 1,
      size,
      campaignId,
    });
    history.push(`${activitiesRoute}?${queryString}`, {
      ...filterOption,
      page: 1,
      size,
    });
  };

  const getAllActivity = async () => {
    setLoading(true);
    const {
      activities = [],
      count = 0,
      error,
    } = await ActivityService.getAllActivity(filterOption);
    setActivityCount(count);
    setLoading(false);
    if (error) {
    } else {
      setActivities(activities);
      setRowSelectionList(activities.map((activity) => ({ id: activity.id, checked: false })));
    }
  };

  const handleSearch = (data: PartialActivityFilterOption) => {
    const queryString = qs.stringify({
      page: 1,
      size: filterOption.size,
      campaignId,
    });
    history.push(`${activitiesRoute}?${queryString}`, {
      ...filterOption,
      ...data,
      page: 1,
    });
    setFilterDrawerOpen(false);
  };

  const handleClear = (data: PartialActivityFilterOption) => {
    setFilterDrawerOpen(false);
    const queryString = qs.stringify({
      page: 1,
      size: filterOption.size,
      campaignId,
    });
    history.push(`${activitiesRoute}?${queryString}`, {
      ...filterOption,
      ...data,
      campaignIds,
      page: 1,
    });
  };

  const downloadExcel = async () => {
    setDownloadingExcel(true);
    const { data, error } = await ActivityService.downloadExcel(filterOption);
    if (data) {
      const blob = new Blob([data]);
      FileSaver.saveAs(blob, `Activity Data -${formatDateTime(new Date())}.xlsx`);
    } else if (error) {
      toast.error(error);
    }
    setDownloadingExcel(false);
  };

  const downloadPPT = async () => {
    setDownloadingPPT(true);
    const { data, error } = await ActivityService.downloadPPT(filterOption);
    if (data) {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      });
      FileSaver.saveAs(blob, `Activity presentation -${formatDateTime(new Date())}.pptx`);
    } else if (error) {
      toast.error(error);
    }
    setDownloadingPPT(false);
  };

  const onSelectAllRow = () => {
    setRowSelectionList(
      rowSelectionList.map((row) => {
        row.checked = true;
        return row;
      })
    );
  };

  const onUnselectAllRow = () => {
    setRowSelectionList(
      rowSelectionList.map((row) => {
        row.checked = false;
        return row;
      })
    );
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setSelectAll(checked);
    if (checked) {
      onSelectAllRow();
    } else {
      onUnselectAllRow();
    }
  };

  const onSelectRow = (index: number) => {
    const row: ActivityTableRowState = {
      ...rowSelectionList[index],
      checked: true,
    };
    rowSelectionList.splice(index, 1, row);
    setRowSelectionList([...rowSelectionList]);
  };

  const onUnselectRow = (index: number) => {
    const row: ActivityTableRowState = {
      ...rowSelectionList[index],
      checked: false,
    };
    rowSelectionList.splice(index, 1, row);
    setRowSelectionList([...rowSelectionList]);
  };

  const refresh = () => {
    const queryString = qs.stringify({
      page: filterOption.page,
      size: filterOption.size,
      campaignId,
    });
    history.push(`${activitiesRoute}?${queryString}`, {
      ...filterOption,
    });
    setSelectAll(false);
  };

  const handleApprove = async () => {
    const activityIds = rowSelectionList?.filter((row) => row.checked).map((row) => row.id) ?? [];
    const { message, error } = await ActivityService.approve(activityIds);
    if (message) {
      toast.success(message);
      refresh();
    }

    if (error) {
      toast.error(error);
    }
  };

  const handleReject = async () => {
    const activityIds = rowSelectionList?.filter((row) => row.checked).map((row) => row.id) ?? [];
    const { message, error } = await ActivityService.reject(activityIds);
    if (message) {
      toast.success(message);
      refresh();
    }

    if (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    getAllActivity();
    // eslint-disable-next-line
  }, [filterOption]);

  return (
    <MainLayout>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Breadcrumbs links={links} />
              <Typography variant="h5" color="textPrimary" className="font-bold mt-2 mb-2">
                All Activity
              </Typography>
              <div className="flex">
                <Typography className="mb-2">
                  <span className="font-medium">Campaign name :</span> {campaign?.name}
                </Typography>
                <Typography className="mb-2 ml-5">
                  <span className="font-medium">Brand name :</span> {campaign?.brand?.name}
                </Typography>
                <Typography className="mb-2 ml-5">
                  <span className="font-medium">Manager name :</span> {campaign?.manager?.firstName}
                </Typography>
              </div>
            </Grid>
            <Grid item>
              <CanView roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER]}>
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  component={Link}
                  to={`${activitiesRoute}/new`}
                  size="small"
                >
                  New Activity
                </Button>
              </CanView>
            </Grid>
          </Grid>
          <Paper className="mt-5" elevation={1}>
            <Grid container spacing={3} justifyContent="space-between" className="px-4">
              <Grid item>
                <Tooltip title="Refresh data">
                  <Button onClick={refresh} color="primary" variant="outlined" className="ml-2">
                    <RefreshIcon />
                  </Button>
                </Tooltip>
                <Button
                  onClick={() => setFilterDrawerOpen(true)}
                  color="primary"
                  variant="outlined"
                  className="ml-2"
                >
                  <FilterListIcon className="mr-2" />
                  Show Filter
                </Button>
                <Button
                  onClick={() => handleClear(defaultFilterOption)}
                  color="primary"
                  variant="outlined"
                  className="ml-2"
                >
                  <ClearIcon className="mr-2" />
                  Clear Filter
                </Button>

                <CanView roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER, ROLE.CLIENT]}>
                  <Button
                    onClick={handleApprove}
                    color="primary"
                    variant="outlined"
                    className="ml-2"
                    disabled={!isRowSelected}
                  >
                    Approve
                  </Button>

                  <Button
                    onClick={handleReject}
                    color="secondary"
                    variant="outlined"
                    className="ml-2"
                    disabled={!isRowSelected}
                  >
                    Reject
                  </Button>
                </CanView>
              </Grid>
              {activityCount > 0 && (
                <Grid item>
                  <Button
                    disableElevation
                    className="ml-2"
                    onClick={downloadExcel}
                    size="small"
                    color="primary"
                    variant="outlined"
                    disabled={downloadingExcel}
                  >
                    {downloadingExcel ? (
                      <CircularProgress className="mr-2" size={16} />
                    ) : (
                      <GetAppIcon className="mr-2" />
                    )}
                    Download Excel
                  </Button>
                  <Button
                    disableElevation
                    className="ml-2"
                    onClick={downloadPPT}
                    size="small"
                    color="primary"
                    variant="outlined"
                    disabled={downloadingPPT}
                  >
                    {downloadingPPT ? (
                      <CircularProgress className="mr-2" size={16} />
                    ) : (
                      <GetAppIcon className="mr-2" />
                    )}
                    Download PPT
                  </Button>
                </Grid>
              )}
            </Grid>
            <Drawer
              open={filterDrawerOpen}
              anchor="right"
              onClose={() => setFilterDrawerOpen(false)}
              PaperProps={{ style: { width: '400px' } }}
            >
              {filterDrawerOpen && (
                <QueryFilterPanel
                  onSearch={handleSearch}
                  onClear={handleClear}
                  filterOption={filterOption}
                  count={activityCount}
                />
              )}
            </Drawer>
            <LoadingIndicator isLoading={loading} imgClassName="w-4/12">
              <Empty isEmpty={activities.length === 0} imgClassName="w-4/12">
                <TableContainer className="mt-5" style={{ maxHeight: '700px' }}>
                  <Table stickyHeader aria-label="simple table" size="small">
                    <TableHead>
                      <TableRow>
                        <CanView roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER, ROLE.CLIENT]}>
                          <TableCell>
                            <Checkbox checked={selectAll} onChange={handleCheckboxChange} />
                          </TableCell>
                        </CanView>
                        <TableCell>Upload Date</TableCell>
                        <TableCell>Code</TableCell>
                        <TableCell>Outlet</TableCell>
                        {/* <TableCell>Outlet Type</TableCell> */}
                        <TableCell>Retailer name</TableCell>
                        <TableCell> Town {'&'} State </TableCell>
                        {boardColumns.map((column) => (
                          <TableCell key={column}>{column}</TableCell>
                        ))}
                        <CanView roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER, ROLE.CLIENT]}>
                          <TableCell>Status</TableCell>
                        </CanView>
                        <TableCell align="center" className="sticky right-0">
                          Actions
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {activities.map((activity, index) => (
                        <ActivityTableRow
                          activity={activity}
                          key={activity.id}
                          index={index}
                          selected={!!rowSelectionList[index]?.checked}
                          onSelect={() => onSelectRow(index)}
                          onUnselect={() => onUnselectRow(index)}
                          maxNoOfBoards={maxNoOfBoards}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Empty>
            </LoadingIndicator>
            {activityCount > 0 && (
              <Pagination
                count={activityCount}
                rowsPerPage={filterOption.size}
                page={filterOption.page}
                onChange={handleChangePage}
                rowsPerPageOptions={rowsPerPageOptions}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </Paper>
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default withAuth(
  withRoles(Activities, [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER, ROLE.CLIENT]),
  '/login'
);
