import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { Brand, BrandFilterOption } from "src/@types/brand";
import Breadcrumbs from "src/components/common/Breadcrumbs";
import { BreadcrumbsLink } from "src/components/common/Breadcrumbs/Breadcrumbs";
import Empty from "src/components/common/Empty";
import LoadingIndicator from "src/components/common/LoadingIndicator";
import MainLayout from "src/components/MainLayout";
import { ROLE } from "src/enums/role";
import withAuth from "src/hocs/withAuth";
import withRoles from "src/hocs/withRoles";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import PeopleIcon from "@material-ui/icons/People";
import BrandService from "src/services/brand.service";
import CreateBrandDialog from "src/containers/Brand/CreateBrandDialog";
import EditBrandDialog from "src/containers/Brand/EditingBrandDialog";
import DeleteBrandDialog from "src/containers/Brand/DeleteBrandDialog";
import debounce from "lodash.debounce";
import SearchIcon from "@material-ui/icons/Search";
import { Link } from "react-router-dom";
import CanView from "src/components/RoleBasedAccess/CanView";

const links: BreadcrumbsLink[] = [
  {
    label: "Dashboard",
    href: "/dashboard",
  },
  {
    label: "Management",
    href: "/management/users",
  },
  {
    label: "Brands",
  },
];

const rowsPerPageOptions = [10, 20, 30, 40, 50];

const Brands = () => {
  const [brands, setBrands] = useState<Brand[]>([]);
  const [loading, setLoading] = useState(false);

  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [brandId, setBrandId] = useState<string | null>(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [filterOption, setFilterOption] = useState<BrandFilterOption>({
    query: "",
    page: 1,
    size: 20,
    filterType: "query",
  });

  const [brandCount, setBrandCount] = useState(0);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setFilterOption({ ...filterOption, page: newPage + 1 });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFilterOption({
      ...filterOption,
      page: 1,
      size: parseInt(event.target.value, 10),
    });
  };

  const getAllBrand = async () => {
    setLoading(true);
    const {
      brands = [],
      count = 0,
      error,
    } = await BrandService.getAllBrand(filterOption);
    setBrandCount(count);
    setLoading(false);
    if (error) {
    } else {
      setBrands(brands);
    }
  };

  const debounceSearch = debounce((value: string) => {
    setFilterOption({ ...filterOption, page: 1, query: value });
  }, 300);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    debounceSearch(event.target.value);
  };

  const handleCreateBrandSuccess = useCallback(() => {
    setOpenCreateDialog(false);
    getAllBrand();
    // eslint-disable-next-line
  }, []);

  const handleEditClick = useCallback(
    (brandId: string) => () => {
      setBrandId(brandId);
      setOpenEditDialog(true);
    },
    // eslint-disable-next-line
    []
  );

  const handleEditDialogClose = useCallback(() => {
    setOpenEditDialog(false);
    setBrandId(null);
    // eslint-disable-next-line
  }, []);

  const handleEditBrandSuccess = useCallback(() => {
    setOpenEditDialog(false);
    setBrandId(null);
    getAllBrand();
    // eslint-disable-next-line
  }, []);

  const handleDeleteClick = useCallback(
    (brandId: string) => () => {
      setBrandId(brandId);
      setOpenDeleteDialog(true);
    },
    // eslint-disable-next-line
    []
  );

  const handleDeleteDialogClose = useCallback(() => {
    setOpenDeleteDialog(false);
    setBrandId(null);
    // eslint-disable-next-line
  }, []);

  const handleDeleteBrandSuccess = useCallback(() => {
    setOpenDeleteDialog(false);
    setBrandId(null);
    getAllBrand();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getAllBrand();
    // eslint-disable-next-line
  }, [filterOption]);

  return (
    <MainLayout>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Breadcrumbs links={links} />
              <Typography
                variant="h5"
                color="textPrimary"
                className="font-bold mt-2"
              >
                All Brands
              </Typography>
            </Grid>
            <Grid item>
              <CanView roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN]}>
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  onClick={() => setOpenCreateDialog(true)}
                >
                  New Brand
                </Button>
              </CanView>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Paper className="mt-5" elevation={1}>
        <Box padding={3}>
          <Grid spacing={3}>
            <Grid item xs={12} sm={3}>
              <TextField
                variant="outlined"
                placeholder="Search"
                id="search"
                name="search"
                onChange={handleSearch}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>
        <LoadingIndicator isLoading={loading} imgClassName="w-4/12">
          <Empty isEmpty={brands.length === 0} imgClassName="w-4/12">
            <TableContainer className="mt-2">
              <Table aria-label="simple table" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell align="center">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {brands.map((brand, index) => (
                    <TableRow key={brand.id}>
                      <TableCell component="th" scope="row">
                        {index + 1}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {brand.name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {brand.description}
                      </TableCell>
                      <TableCell className="font-bold" align="center">
                        <CanView roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN]}>
                          <IconButton onClick={handleEditClick(brand.id)}>
                            <EditIcon />
                          </IconButton>
                          <IconButton onClick={handleDeleteClick(brand.id)}>
                            <DeleteIcon />
                          </IconButton>
                        </CanView>
                        <IconButton
                          component={Link}
                          to={`/management/brands/${brand.id}/users`}
                        >
                          <PeopleIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              component="div"
              count={brandCount}
              rowsPerPage={filterOption.size}
              page={filterOption.page - 1}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Empty>
        </LoadingIndicator>
      </Paper>
      <CreateBrandDialog
        open={openCreateDialog}
        onClose={() => setOpenCreateDialog(false)}
        onSuccess={handleCreateBrandSuccess}
      />
      {brandId && (
        <EditBrandDialog
          open={openEditDialog}
          onClose={handleEditDialogClose}
          onSuccess={handleEditBrandSuccess}
          brandId={brandId}
        />
      )}
      {brandId && (
        <DeleteBrandDialog
          open={openDeleteDialog}
          onClose={handleDeleteDialogClose}
          onSuccess={handleDeleteBrandSuccess}
          brandId={brandId}
        />
      )}
    </MainLayout>
  );
};

export default withAuth(
  withRoles(Brands, [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER]),
  "/login"
);
