import { useEffect, useState } from "react";
import { Campaign, CampaignFilterOption } from "src/@types";
import { CampaignService } from "src/services/campaign.service";

export interface UseCampaignsOptions extends CampaignFilterOption {}

export interface UseCampaignsResult {
  campaigns: Campaign[];
  loading: boolean;
  count: number;
}

export function useCampaigns(options: UseCampaignsOptions) {
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getAllCampaign = async () => {
      setLoading(true);
      const {
        campaigns = [],
        count = 0,
        error,
      } = await CampaignService.getAll(options);
      setCount(count);
      setLoading(false);
      if (error) {
      } else {
        setCampaigns(campaigns);
      }
    };
    getAllCampaign();
  }, [options]);

  return { campaigns, count, loading };
}
