import React from "react";
import { Divider, Drawer, Toolbar } from "@material-ui/core";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { RootState } from "src/store";
import { AuthState } from "src/store/auth/types";
import BeenhereIcon from "@material-ui/icons/Beenhere";
import SidebarProfile from "./SidebarProfile";
import SideBarMenuList from "./SideBarMenuList";
import { IMenuGroup } from "src/@types/sidebar";
import { ROLE } from "src/enums/role";
import { hasRole } from "src/util/role";
import LocalActivityIcon from "@material-ui/icons/LocalActivity";
import GroupIcon from "@material-ui/icons/Group";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import StoreIcon from "@material-ui/icons/Store";
import WallpaperIcon from "@material-ui/icons/Wallpaper";

const menuGroupList: IMenuGroup[] = [
  // {
  //   id: 'report',
  //   groupTitle: 'Report',
  //   roles: [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER],
  //   menuList: [
  //     {
  //       title: 'Dashboard',
  //       path: '/dashboard',
  //       icon: DashboardIcon,
  //       roles: [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER],
  //     },
  //   ],
  // },
  {
    id: "management",
    groupTitle: "Management",
    roles: [ROLE.SUPER_ADMIN, ROLE.ADMIN],
    menuList: [
      {
        title: "Users",
        path: "/management/users",
        icon: GroupIcon,
        roles: [ROLE.SUPER_ADMIN, ROLE.ADMIN],
      },
      {
        title: "Roles",
        path: "/management/roles",
        icon: BeenhereIcon,
        roles: [ROLE.SUPER_ADMIN, ROLE.ADMIN],
      },
      {
        title: "Brands",
        path: "/management/brands",
        icon: SupervisedUserCircleIcon,
        roles: [ROLE.SUPER_ADMIN, ROLE.ADMIN],
      },
    ],
  },
  {
    id: "branding",
    groupTitle: "Branding",
    roles: [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER, ROLE.CLIENT],
    menuList: [
      {
        title: "Campaigns",
        path: "/branding/campaign-brands",
        icon: LocalActivityIcon,
        roles: [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER, ROLE.CLIENT],
      },
      {
        title: "Material Type",
        path: "/branding/material-types",
        icon: WallpaperIcon,
        roles: [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER],
      },
      {
        title: "Outlet Type",
        path: "/branding/outlet-types",
        icon: StoreIcon,
        roles: [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER],
      },
    ],
  },
];

const SideBarWrapper = styled(Drawer)`
  width: 240px;
  flex-shrink: 0;
  .sidebar-paper {
    width: 240px;
  }
  .profile-avatar {
    width: 80px;
    height: 80px;
  }
`;
interface SideBarProps {
  floatSideBar?: boolean;
}
const SideBar: React.FC<SideBarProps> = ({ floatSideBar }) => {
  const { pathname } = useLocation();
  const { authenticated, user } = useSelector<RootState, AuthState>(
    (state) => state.authState
  );
  const filteredMenuList = menuGroupList.filter(
    (menuGroup) => user && hasRole(user.roles, menuGroup.roles)
  );
  return (
    <SideBarWrapper
      variant={floatSideBar ? "temporary" : "permanent"}
      className=""
      classes={{ paper: "sidebar-paper" }}
    >
      <Toolbar />
      {user && <SidebarProfile user={user} />}
      <Divider />
      {user &&
        filteredMenuList.map((menuGroup) => (
          <SideBarMenuList
            menuList={menuGroup.menuList}
            isActive={(menu) => {
              return pathname.indexOf(menu.path) === 0;
            }}
            isVisible={() => true}
            title={menuGroup.groupTitle}
            key={menuGroup.id}
            user={user}
          />
        ))}
      {authenticated && <></>}
    </SideBarWrapper>
  );
};

export default SideBar;
