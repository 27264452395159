import React from 'react';
import MuiPagination from '@material-ui/lab/Pagination';
import { FormControl, MenuItem, Select } from '@material-ui/core';

interface PaginationProps {
  count: number;
  rowsPerPage: number;
  rowsPerPageOptions: number[];
  onRowsPerPageChange?: (event: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
  onChange?: (event: React.ChangeEvent<unknown> | undefined, newPage: number) => void;
  page: number;
}

const Pagination: React.FunctionComponent<PaginationProps> = ({
  count,
  rowsPerPage,
  rowsPerPageOptions,
  onChange,
  onRowsPerPageChange,
  page,
}) => {
  return (
    <div className="flex justify-center items-center p-3">
      <MuiPagination
        count={Math.ceil(count / rowsPerPage)}
        color="primary"
        boundaryCount={1}
        siblingCount={2}
        page={page}
        onChange={onChange}
      />
      <div className="flex items-center ml-3">
        <span className="mr-3">Rows per page:</span>
       
          <FormControl size="small">
            <Select value={rowsPerPage} onChange={onRowsPerPageChange} variant="outlined">
              {rowsPerPageOptions.map((rowsPerPageOption) => (
                <MenuItem key={rowsPerPageOption} value={rowsPerPageOption}>
                  {rowsPerPageOption}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
       
      </div>
    </div>
  );
};

export default Pagination;
