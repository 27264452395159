import { Card, CardHeader, Typography, Divider, Box, Button, CardContent } from '@material-ui/core';
import React from 'react';
import { User } from 'src/@types/user';
import DeleteIcon from '@material-ui/icons/Delete';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import BlockIcon from '@material-ui/icons/Block';

interface UserOtherActionCardProps {
  user: User;
  onDeactive?: () => void;
  onActivate: () => void;
  onDelete: () => void;
  onChangePassword: () => void;
}

const UserOtherActionCard: React.FunctionComponent<UserOtherActionCardProps> = ({
  user,
  onDeactive,
  onActivate,
  onChangePassword,
  onDelete,
}) => {
  return (
    <Card>
      <CardHeader title={<Typography variant="h6">Other actions</Typography>} />
      <Divider />
      <CardContent>
        <Box className="flex flex-col items-start">
          <Button disableElevation className="mb-5" onClick={onChangePassword}>
            <LockOpenIcon className="mr-2" />
            Change password
          </Button>
          {user?.active && (
            <Button disableElevation className="mb-5" onClick={onDeactive}>
              <BlockIcon className="mr-2" />
              Deactivate user
            </Button>
          )}
          {!user?.active && (
            <Button disableElevation className="mb-5" onClick={onActivate}>
              <BlockIcon className="mr-2" />
              Activate user
            </Button>
          )}
          <Button color="secondary" disableElevation className="mb-5" onClick={onDelete}>
            <DeleteIcon className="mr-2" />
            Delete User
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default UserOtherActionCard;
