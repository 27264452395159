import Joi from 'joi';

export const newInstallationPhotoSchema = Joi.object({
  id: Joi.string().required().messages({
    'string.empty': 'New installation photo id is required',
  }),
  name: Joi.string().required().messages({
    'string.empty': 'New installation photo name is required',
  }),
  contentType: Joi.string().required().messages({
    'string.empty': 'New installation photo content type is required',
  }),
  contentSize: Joi.number().required().messages({
    'any.required': 'New installation photo content size is required',
  }),
  base64: Joi.string().required().messages({
    'string.empty': 'New installation photo image is required',
  }),
}).unknown();

export const installationPhotoSchema = Joi.object({
  id: Joi.string().required().messages({
    'string.empty': 'Installation photo id is required',
  }),
  name: Joi.string().allow('', null).messages({
    'string.empty': 'Installation photo name is required',
  }),
  contentType: Joi.string().required().messages({
    'string.empty': 'Installation photo content type is required',
  }),
  contentSize: Joi.number().required().messages({
    'any.required': 'Installation photo content size is required',
  }),
  url: Joi.string().required().messages({
    'string.empty': 'Installation photo image is required',
  }),
}).unknown();
