import React, { useCallback, useEffect, useState } from "react";
import { Brand } from "src/@types";
import BrandService from "src/services/brand.service";
import BrandFormDialog from "./BrandFormDialog";
import toast from "react-hot-toast";

interface EditBrandDialogProps {
  open: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
  brandId: string;
}

const EditBrandDialog: React.FC<EditBrandDialogProps> = ({
  open,
  onClose,
  onSuccess,
  brandId,
}) => {
  const [brand, setBrand] = useState<Brand | null>(null);

  const handleSave = useCallback(async (data: Brand) => {
    const { message, error } = await BrandService.updateBrand({
      ...data,
      id: brandId,
    });
    if (error) {
      toast.error(error);
    } else if (message) {
      toast.success(message);
      onSuccess?.();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const getBrandById = async () => {
      const { brand } = await BrandService.getBrandById(brandId);
      if (brand) {
        setBrand(brand);
      }
    };
    getBrandById();
    // eslint-disable-next-line
  }, [brandId]);

  return (
    <>
      {brand && (
        <BrandFormDialog
          mode="edit"
          brand={brand}
          open={open}
          onClose={onClose}
          onSave={handleSave}
        />
      )}
    </>
  );
};

export default EditBrandDialog;
