import React, { FunctionComponent } from "react";
import { MapContainer, Marker, TileLayer, useMapEvents } from "react-leaflet";

export interface EventListenerProps {
  onClick?: (lat: number, lng: number) => void;
}

const EventListener: FunctionComponent<EventListenerProps> = ({ onClick }) => {
  useMapEvents({
    click(event) {
      const { lat, lng } = event.latlng;
      onClick?.(lat, lng);
    },
  });

  return null;
};

export interface MapProps {
  lat: number;
  lng: number;
  onClick?: (lat: number, lng: number) => void;
}
const Map: FunctionComponent<MapProps> = ({ lat, lng, onClick }) => {
  return (
    <MapContainer center={{ lat, lng }} zoom={12} scrollWheelZoom={false}>
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={{ lat, lng }} />
      <EventListener onClick={onClick} />
    </MapContainer>
  );
};
export default Map;
