import { ActivityStatus } from "src/@types/activity-status";
import API from "src/helpers/api";
import { getErrorMessage } from "src/helpers/axios";

interface GetAllActivityStatusResult {
  activityStatuses?: Array<ActivityStatus>;
  error?: string;
}

interface GetAllActivityStatusResponse {
  activityStatuses: Array<ActivityStatus>;
}

async function getAllActivityStatus(): Promise<GetAllActivityStatusResult> {
  const result = {} as GetAllActivityStatusResult;
  try {
    const { data } = await API.get<GetAllActivityStatusResponse>(
      "/activity-status"
    );
    if (data.activityStatuses) {
      result.activityStatuses = data.activityStatuses;
    }
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
}

const ActivityStatusService = {
  getAllActivityStatus,
};

export default ActivityStatusService;
