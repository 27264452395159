import Joi, { ValidationResult } from "joi";
import { useCallback, useState } from "react";
import { ValidationErrorResult } from "src/@types/form-validation";

function processValidationResult<T>(
  result: ValidationResult
): ValidationErrorResult<T> {
  const errors = {} as ValidationErrorResult<T>;
  result.error?.details.forEach((detail) => {
    errors[detail.context?.key as keyof T] = {
      message: detail.message,
    };
  });
  return errors;
}

export function useFormValidation<T>({
  schema,
  data,
}: {
  schema: Joi.Schema;
  data: T;
}) {
  const [errors, setErrors] = useState<ValidationErrorResult<T>>(
    {} as ValidationErrorResult<T>
  );

  const clearErrors = useCallback(() => {
    setErrors({} as ValidationErrorResult<T>);
  }, []);

  const validate = useCallback(() => {
    const result = schema.validate(data, { abortEarly: false });
    const errors = processValidationResult<T>(result);

    setErrors(errors);

    return Object.keys(errors).length === 0;
  }, [schema, data]);

  return { errors, validate, clearErrors };
}
