import { joiResolver } from "@hookform/resolvers/joi";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
} from "@material-ui/core";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { MaterialType } from "src/@types";
import DialogTitle from "src/components/common/Dialog/DialogTitle";
import { materialTypeFormSchema } from "src/validation/material-type.schema";

interface MaterialTypeFormProps {
  mode: "create" | "edit";
  open: boolean;
  materialType: MaterialType;
  onSave?: (materialType: MaterialType) => void;
  onClose?: () => void;
}

const MaterialTypeFormDialog: React.FC<MaterialTypeFormProps> = ({
  mode,
  materialType,
  onSave,
  open,
  onClose,
}) => {
  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm<MaterialType>({
    defaultValues: materialType,
    mode: "onSubmit",
    resolver: joiResolver(materialTypeFormSchema),
  });

  const onSubmit = (data: MaterialType) => {
    onSave?.(data);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      PaperProps={{
        className: "w-4/12",
      }}
    >
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle
          title={
            mode === "create" ? "Create material type" : "Update material type"
          }
          onClose={onClose}
        />
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    variant="outlined"
                    label="Name"
                    className="w-full"
                    id="name"
                    error={!!errors.name}
                    helperText={errors.name?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <TextField
                    variant="outlined"
                    label="Description"
                    className="w-full"
                    id="description"
                    error={!!errors.description}
                    helperText={errors.description?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onClose}>
            Cancel
          </Button>
          <Button color="primary" type="submit">
            {mode === "create" ? "Create" : "Save "}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default MaterialTypeFormDialog;
