import {
  Card,
  CardHeader,
  Typography,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Chip,
} from '@material-ui/core';
import React from 'react';
import { User } from 'src/@types/user';
import DisplayDate from 'src/components/DisplayDate';

interface UserInfoCardProps {
  user: User;
}

const UserInfoCard: React.FunctionComponent<UserInfoCardProps> = ({ user }) => {
  return (
    <Card>
      <CardHeader title={<Typography variant="h6">User information</Typography>} />
      <Divider />
      <Table>
        <TableBody>
        <TableRow>
            <TableCell className="font-medium capitalize">ID</TableCell>
            <TableCell className="capitalize">{user?.displayId}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="font-medium capitalize">Mobile</TableCell>
            <TableCell>{user?.mobile}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="font-medium capitalize">Email</TableCell>
            <TableCell>{user?.email}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="font-medium capitalize">Gender</TableCell>
            <TableCell className="capitalize">{user?.gender}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="font-medium capitalize">Date of birth</TableCell>
            <TableCell className="capitalize">
              {user?.dob && <DisplayDate date={user.dob} />}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="font-medium capitalize">Roles</TableCell>
            <TableCell>
              {user?.roles.map((role) => (
                <Chip label={role.name} key={role.id} size="small" className="mr-2" />
              ))}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Card>
  );
};

export default UserInfoCard;
