import API from 'src/helpers/api';
import { getErrorMessage } from 'src/helpers/axios';

export interface ApproveActivityBoardResult {
  message?: string;
  error?: string;
}

export interface ApproveActivityBoardResponse {
  message: string;
}

async function approve(boardIds: string[]) {
  const result: ApproveActivityBoardResult = {};
  try {
    const { data } = await API.patch<ApproveActivityBoardResponse>('/activity-boards/approve', {
      boardIds,
    });
    result.message = data.message;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
}

export interface RejectActivityBoardResult {
  message?: string;
  error?: string;
}

export interface RejectActivityBoardResponse {
  message: string;
}

async function reject(boardIds: string[]) {
  const result: RejectActivityBoardResult = {};
  try {
    const { data } = await API.patch<RejectActivityBoardResponse>('/activity-boards/reject', {
      boardIds,
    });
    result.message = data.message;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
}

const ActivityBoardService = {
  approve,
  reject,
};

export default ActivityBoardService;
