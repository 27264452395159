import Joi from "joi";

export const brandFormSchema = Joi.object({
  id: Joi.string().allow(""),
  name: Joi.string().required().messages({
    "string.empty": "Name should not be empty",
  }),
  description: Joi.string().allow(""),
}).unknown();

export const brandUserFormSchema = Joi.object({
  user: Joi.object().required().messages({
    "object.base": "Please select user",
  }),
}).unknown();
