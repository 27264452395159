import toast from "react-hot-toast";
import React, { useCallback, useState } from "react";
import { Brand } from "src/@types";
import BrandService from "src/services/brand.service";
import BrandFormDialog from "./BrandFormDialog";

interface CreateBrandDialogProps {
  open: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
}

const CreateBrandDialog: React.FC<CreateBrandDialogProps> = ({
  open,
  onClose,
  onSuccess,
}) => {
  
  const [brand] = useState<Brand>({ id: "", name: "", description: "" });

  const handleSave = useCallback(async (data: Brand) => {
    const { message, error } = await BrandService.createBrand(data);
    if (error) {
      toast.error(error);
    } else if (message) {
      toast.success(message);
      onSuccess?.();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <BrandFormDialog
      mode="create"
      brand={brand}
      open={open}
      onClose={onClose}
      onSave={handleSave}
    />
  );
};

export default CreateBrandDialog;
