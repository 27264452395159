import { LoginFormData } from "src/@types/login";
import { User } from "src/@types/user";
import API from "src/helpers/api";
import { getErrorMessage } from "src/helpers/axios";

interface LoginResponse {
  message: string;
  user: User;
  token: string;
}

interface LoginResult {
  user?: User;
  error?: any;
}

const login = async (credentials: LoginFormData): Promise<LoginResult> => {
  const result: LoginResult = {};
  try {
    const response = await API.post<LoginResponse>("/auth/login", credentials);
    result.user = response.data.user;
    localStorage.setItem("ACCESS_TOKEN", response.data.token);
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface LogoutResponse {
  message: string;
}

const logout = async (): Promise<LogoutResponse> => {
  const response = await API.post<LogoutResponse>("/auth/logout");
  return response.data;
};

const AuthService = {
  login,
  logout,
};

export default AuthService;
