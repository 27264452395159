import React from "react";
import Layout from "src/components/Layout";
import Header from "src/components/Layout/Header";
import SideBar from "src/components/SiderBar";
import Main from "src/components/Layout/Main";

interface MainLayoutProps {
  floatSideBar?: boolean;
}
const MainLayout: React.FC<MainLayoutProps> = ({ children, floatSideBar }) => {
  return (
    <Layout>
      <Header />
      <SideBar floatSideBar={floatSideBar} />
      <Main className="bg-gray-100 flex-grow ">{children}</Main>
    </Layout>
  );
};

export default MainLayout;
