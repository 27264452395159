import toast from "react-hot-toast";
import React, { useCallback, useState } from "react";
import { OutletType } from "src/@types";
import OutletTypeService from "src/services/outlet-type.service";
import OutletTypeFormDialog from "./OutletTypeFormDialog";

interface CreateOutletTypeDialogProps {
  open: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
}

const CreateOutletTypeDialog: React.FC<CreateOutletTypeDialogProps> = ({
  open,
  onClose,
  onSuccess,
}) => {
  const [outletType] = useState<OutletType>({
    id: "",
    name: "",
    description: "",
  });

  const handleSave = useCallback(async (data: OutletType) => {
    const { message, error } = await OutletTypeService.createOutletType(data);
    if (error) {
      toast.error(error);
    } else if (message) {
      toast.success(message);
      onSuccess?.();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <OutletTypeFormDialog
      mode="create"
      outletType={outletType}
      open={open}
      onClose={onClose}
      onSave={handleSave}
    />
  );
};

export default CreateOutletTypeDialog;
