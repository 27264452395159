import { Activity, ActivityStatus, MaterialType, OutletType } from 'src/@types';
import API from 'src/helpers/api';
import { getErrorMessage } from 'src/helpers/axios';
import { ActivityFilterOption } from 'src/@types';
import parseISO from 'date-fns/parseISO';
import { ActivityStatusEnum } from 'src/enums/activity-status';

const transformActivity = (data: any): Activity => {
  // data.createdTime = parseISO(data.createdTime);

  if (!data.outlet) {
    data.outlet = {};
  }
  data.uploadDate = data.uploadDate ? parseISO(data.uploadDate) : null;
  data.receeDate = data.receeDate ? parseISO(data.receeDate) : null;
  data.installationDate = data.installationDate ? parseISO(data.installationDate) : null;
  if (data.outlet?.registrationDate) {
    data.outlet.registrationDate = data.outlet.registrationDate
      ? parseISO(data.outlet.registrationDate)
      : null;
  }
  if (data.outlet?.lastLogin) {
    data.outlet.lastLogin = data.outlet.lastLogin ? parseISO(data.outlet.lastLogin) : null;
  }
  if (!data.outlet.lat) {
    data.outlet.lat = 19.013748;
  }
  if (!data.outlet.lng) {
    data.outlet.lng = 72.841928;
  }
  return data;
};

type GetAllActivityOptions = ActivityFilterOption;

const parseGetAllActivityOptions = (options: GetAllActivityOptions) => {
  const updatedOptions = { ...options };
  updatedOptions.statuses = (options.statuses as ActivityStatus[]).map((status) => status.id);
  updatedOptions.outletTypes = (options.outletTypes as OutletType[]).map(
    (outletType) => outletType.name
  );
  updatedOptions.materialTypes = (options.materialTypes as MaterialType[]).map(
    (materialType) => materialType.name
  );
  return updatedOptions;
};

interface GetAllActivityResult {
  activities?: Activity[];
  count?: number;
  error?: string;
}

interface GetAllActivityResponse {
  activities: Activity[];
  count: number;
}

const getAllActivity = async (options: GetAllActivityOptions) => {
  const result: GetAllActivityResult = {};
  try {
    const {
      data: { activities, count },
    } = await API.post<GetAllActivityResponse>(
      `/activities/search`,
      parseGetAllActivityOptions(options),
      {
        transformResponse: (data) => {
          data = JSON.parse(data);
          data.activities = data.activities.map(transformActivity);
          return data;
        },
      }
    );
    result.activities = activities;
    result.count = count;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface GetActivityByIdResult {
  activity?: Activity;
  error?: string;
}

interface GetActivityByIdResponse {
  activity: Activity;
}

const getActivityById = async (activityId: string) => {
  const result: GetActivityByIdResult = {};
  try {
    const {
      data: { activity },
    } = await API.get<GetActivityByIdResponse>(`activities/${activityId}`, {
      transformResponse: (data) => {
        data = JSON.parse(data);
        data.activity = transformActivity(data.activity);
        return data;
      },
    });
    result.activity = activity;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface CreateActivityResponse {
  activity: Activity;
  message: string;
}
interface CreateActivityResult {
  activity?: Activity;
  message?: string;
  error?: string;
}

interface CreateActivityOption {
  onUploadProgress?: (event: any) => void;
}
const createActivity = async (
  activityData: Activity,
  { onUploadProgress }: CreateActivityOption
) => {
  const result: CreateActivityResult = {};
  try {
    activityData.campaignId = activityData.campaign?.id ?? null;
    if (activityData.status?.id) {
      activityData.activityStatusId = activityData.status.id as ActivityStatusEnum;
    }
    activityData.outlet.outletTypeId = activityData.outlet?.outletType?.id ?? null;
    const {
      data: { activity, message },
    } = await API.post<CreateActivityResponse>('activities', activityData, {
      onUploadProgress: (event) => {
        onUploadProgress?.((event.loaded / event.total) * 100);
      },
    });
    result.activity = activity;
    result.message = message;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface UpdateActivityResponse {
  activity: Activity;
  message: string;
}

interface UpdateActivityResult {
  activity?: Activity;
  message?: string;
  error?: string;
}

interface UpdateActivityOption {
  onUploadProgress?: (event: any) => void;
}

const updateActivity = async (
  activityData: Activity,
  { onUploadProgress }: UpdateActivityOption
) => {
  const result: UpdateActivityResult = {};
  try {
    activityData.campaignId = activityData.campaign?.id ?? null;
    if (activityData.status?.id) {
      activityData.activityStatusId = activityData.status.id as ActivityStatusEnum;
    }
    activityData.outlet.outletTypeId = activityData.outlet?.outletType?.id ?? null;

    const {
      data: { activity, message },
    } = await API.patch<UpdateActivityResponse>(`activities/${activityData.id}`, activityData, {
      onUploadProgress: (event) => {
        onUploadProgress?.((event.loaded / event.total) * 100);
      },
    });
    result.activity = activity;
    result.message = message;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface DeleteActivityResult {
  message?: string;
  error?: string;
}
interface DeleteActivityResponse {
  message: string;
}

const deleteActivity = async (id: string): Promise<DeleteActivityResult> => {
  const result: DeleteActivityResult = {};
  try {
    const {
      data: { message },
    } = await API.delete<DeleteActivityResponse>(`activities/${id}`);
    result.message = message;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface DeactivateActivityResult {
  message?: string;
  error?: string;
}
interface DeactivateActivityResponse {
  message: string;
}

const deactivateActivity = async (id: string): Promise<DeactivateActivityResult> => {
  const result: DeleteActivityResult = {};
  try {
    const {
      data: { message },
    } = await API.put<DeactivateActivityResponse>(`activities/${id}/deactivate`);
    result.message = message;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface ActivateActivityResult {
  message?: string;
  error?: string;
}
interface ActivateActivityResponse {
  message: string;
}
const activateActivity = async (id: string): Promise<ActivateActivityResult> => {
  const result: DeleteActivityResult = {};
  try {
    const {
      data: { message },
    } = await API.put<ActivateActivityResponse>(`activities/${id}/activate`);
    result.message = message;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface DeleteAttachmentResult {
  message?: string;
  error?: string;
}
interface DeleteAttachmentResponse {
  message: string;
}

const deleteAttachment = async (
  id: string,
  attachmentId: string
): Promise<DeleteAttachmentResult> => {
  const result: DeleteAttachmentResult = {};
  try {
    const {
      data: { message },
    } = await API.delete<DeleteAttachmentResponse>(`activities/${id}/attachments/${attachmentId}`);
    result.message = message;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface DeleteAcknowledgementResult {
  message?: string;
  error?: string;
}
interface DeleteAcknowledgementResponse {
  message: string;
}

const deleteAcknowledgement = async (
  id: string,
  acknowledgementId: string
): Promise<DeleteAcknowledgementResult> => {
  const result: DeleteAcknowledgementResult = {};
  try {
    const {
      data: { message },
    } = await API.delete<DeleteAcknowledgementResponse>(
      `activities/${id}/acknowledgements/${acknowledgementId}`
    );
    result.message = message;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface ApproveReceeResult {
  message?: string;
  error?: string;
}
interface ApproveReceeResponse {
  message: string;
}

const approveRecee = async (id: string): Promise<ApproveReceeResult> => {
  const result: ApproveReceeResult = {};
  try {
    const {
      data: { message },
    } = await API.patch<ApproveReceeResponse>(`activities/${id}/approve-recee`);
    result.message = message;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};
interface RejectReceeResult {
  message?: string;
  error?: string;
}
interface RejectReceeResponse {
  message: string;
}

const rejectRecee = async (id: string): Promise<RejectReceeResult> => {
  const result: RejectReceeResult = {};
  try {
    const {
      data: { message },
    } = await API.patch<RejectReceeResponse>(`activities/${id}/reject-recee`);
    result.message = message;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};
interface ApproveInstallationResult {
  message?: string;
  error?: string;
}
interface ApproveInstallationResponse {
  message: string;
}

const approveInstallation = async (id: string): Promise<ApproveInstallationResult> => {
  const result: ApproveInstallationResult = {};
  try {
    const {
      data: { message },
    } = await API.patch<ApproveInstallationResponse>(`activities/${id}/approve-installation`);
    result.message = message;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface RejectInstallationResult {
  message?: string;
  error?: string;
}
interface RejectInstallationResponse {
  message: string;
}

const rejectInstallation = async (id: string): Promise<RejectInstallationResult> => {
  const result: RejectInstallationResult = {};
  try {
    const {
      data: { message },
    } = await API.patch<RejectInstallationResponse>(`activities/${id}/reject-installation`);
    result.message = message;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface DownloadActivitySheetResult {
  data?: ArrayBuffer;
  error?: string;
}

const downloadExcel = async (
  options: GetAllActivityOptions
): Promise<DownloadActivitySheetResult> => {
  const result: DownloadActivitySheetResult = {};
  try {
    const { data } = await API.post<ArrayBuffer>(
      'activities/download/excel-report',
      parseGetAllActivityOptions(options),
      {
        responseType: 'arraybuffer',
      }
    );
    result.data = data;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface DownloadActivityPPTResult {
  data?: ArrayBuffer;
  error?: string;
}

const downloadPPT = async (options: GetAllActivityOptions): Promise<DownloadActivityPPTResult> => {
  const result: DownloadActivityPPTResult = {};
  try {
    const { data } = await API.post<ArrayBuffer>(
      'activities/download/ppt',
      parseGetAllActivityOptions(options),
      {
        responseType: 'arraybuffer',
      }
    );
    result.data = data;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

export interface DownloadActivityMasterExcelResult {
  buffer?: ArrayBuffer;
  error?: string;
}

async function downloadMasterExcel() {
  const result = {} as DownloadActivityMasterExcelResult;
  try {
    const { data } = await API.get<ArrayBuffer>('activities/download/master-excel', {
      responseType: 'arraybuffer',
    });
    result.buffer = data;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
}

export interface ApproveActivityResult {
  message?: string;
  error?: string;
}

export interface ApproveActivityResponse {
  message: string;
}

async function approve(activityIds: string[]) {
  const result: ApproveActivityResult = {};
  try {
    const { data } = await API.patch<ApproveActivityResponse>('/activities/approve', {
      activityIds,
    });
    result.message = data.message;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
}

export interface RejectActivityResult {
  message?: string;
  error?: string;
}

export interface RejectActivityResponse {
  message: string;
}

async function reject(activityIds: string[]) {
  const result: RejectActivityResult = {};
  try {
    const { data } = await API.patch<RejectActivityResponse>('/activities/reject', {
      activityIds,
    });
    result.message = data.message;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
}

const ActivityService = {
  getAllActivity,
  getActivityById,
  createActivity,
  updateActivity,
  deleteActivity,
  deactivateActivity,
  activateActivity,
  deleteAttachment,
  deleteAcknowledgement,
  approveRecee,
  rejectRecee,
  approveInstallation,
  rejectInstallation,
  downloadExcel,
  downloadPPT,
  downloadMasterExcel,
  approve,
  reject,
};

export default ActivityService;
