import toast from "react-hot-toast";
import React, { useCallback, useState } from "react";
import { BrandUserForm } from "src/@types";
import BrandUserService from "src/services/brand-user.service";
import BrandUserFormDialog from "./BrandUserFormDialog";

interface CreateBrandUserDialogProps {
  open: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
  brandId: string;
}

const CreateBrandUserDialog: React.FC<CreateBrandUserDialogProps> = ({
  open,
  onClose,
  onSuccess,
  brandId,
}) => {
  
  const [brandUser] = useState<BrandUserForm>({ user: null, userId: null });

  const handleSave = useCallback(async (data: BrandUserForm) => {
    if (!data.userId) {
      return;
    }
    const { message, error } = await BrandUserService.createBrandUser({
      brandId,
      userId: data.userId,
    });

    if (error) {
      toast.error(error);
    } else if (message) {
      toast.success(message);
      onSuccess?.();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <BrandUserFormDialog
      mode="create"
      brandUser={brandUser}
      open={open}
      onClose={onClose}
      onSave={handleSave}
    />
  );
};

export default CreateBrandUserDialog;
