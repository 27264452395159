import {
  Card,
  CardHeader,
  Typography,
  Divider,
  Grid,
  Box,
} from "@material-ui/core";
import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import { Activity } from "src/@types";
import AttachmentCard from "src/components/AttachmentCard";

interface AcknowledgementProps {
  activity: Activity;
}
const Acknowledgement: React.FC<AcknowledgementProps> = ({ activity }) => {
  const [isLightBoxOpen, setLightBoxOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [images, setImages] = useState<string[]>([]);

  const handleAcknowledgementOpen = (index: number) => {
    const photos = activity.ackPhotos ?? [];
    const images = photos?.map((photo) => photo.url);
    setImages(images);
    setPhotoIndex(index);
    setLightBoxOpen(true);
  };

  const handleCloseLightBox = () => {
    setImages([]);
    setPhotoIndex(0);
    setLightBoxOpen(false);
  };

  return (
    <Card>
      <CardHeader
        title={<Typography variant="h6">Acknowledgement document</Typography>}
      />
      <Divider />
      <Box padding={3}>
        <Grid container spacing={3}>
          {activity.ackPhotos?.map((photo, index) => (
            <Grid item key={photo.id}>
              <AttachmentCard
                attachment={photo}
                size="medium"
                onClick={() => handleAcknowledgementOpen(index)}
              />
            </Grid>
          ))}
        </Grid>
        {isLightBoxOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={handleCloseLightBox}
            onMovePrevRequest={() =>
              setPhotoIndex(
                (photoIndex) => (photoIndex + images.length - 1) % images.length
              )
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex) => (photoIndex + 1) % images.length)
            }
            reactModalStyle={{ overlay: { zIndex: 2000 } }}
          />
        )}
      </Box>
    </Card>
  );
};

export default Acknowledgement;
