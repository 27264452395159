import Joi from 'joi';

export const newStorePhotoSchema = Joi.object({
  id: Joi.string().required().messages({
    'string.empty': 'New store photo id is required',
  }),
  name: Joi.string().required().messages({
    'string.empty': 'New store photo name is required',
  }),
  contentType: Joi.string().required().messages({
    'string.empty': 'New store photo content type is required',
  }),
  contentSize: Joi.number().required().messages({
    'any.required': 'New store photo content size is required',
  }),
  base64: Joi.string().required().messages({
    'string.empty': 'New store photo image is required',
  }),
}).unknown();

export const storePhotoSchema = Joi.object({
  id: Joi.string().required().messages({
    'string.empty': 'Store photo id is required',
  }),
  name: Joi.string().allow('', null).messages({
    'string.empty': 'Store photo name is required',
  }),
  contentType: Joi.string().required().messages({
    'string.empty': 'Store photo content type is required',
  }),
  contentSize: Joi.number().required().messages({
    'any.required': 'Store photo content size is required',
  }),
  url: Joi.string().required().messages({
    'string.empty': 'Store photo image is required',
  }),
}).unknown();
