import React from 'react';
import cx from 'classnames';
import { Typography } from '@material-ui/core';
import EmptyData from 'src/components/Images/EmptyData';

interface EmptyProps {
  isEmpty: boolean;
  imgClassName?: string;
  className?: string;
}

const Empty: React.FunctionComponent<EmptyProps> = ({
  isEmpty,
  children,
  imgClassName,
  className,
}) => {
  if (!isEmpty) {
    return <>{children}</>;
  }
  return (
    <div className={cx('flex flex-col w-full items-center p-4', className)}>
      <EmptyData
        className={cx(imgClassName ?? 'w-4/12 ', 'h-auto mb-2')}
      />
      <Typography variant="subtitle1">No data found</Typography>
    </div>
  );
};

export default Empty;
