import Joi from 'joi';
import { installationPhotoSchema, newInstallationPhotoSchema } from './installation-photo.schema';
import { newReceePhotoSchema, receePhotoSchema } from './recee-photo.schema';

export const boardSchema = Joi.object({
  materialTypeId: Joi.string().required().messages({
    'string.empty': 'Please select material',
  }),
  width: Joi.number().required().min(1.0).max(100).messages({
    'number.base': 'Invalid width value',
    'number.min': 'width must be atleast 1',
    'number.max': 'width should not exceed 100',
  }),
  height: Joi.number().required().min(1.0).max(100).messages({
    'number.base': 'Invalid height value',
    'number.min': 'height must be atleast 1',
    'number.max': 'height should not exceed 100',
  }),
  quantity: Joi.number().required().min(1.0).max(100).messages({
    'number.base': 'Invalid quantity value',
    'number.min': 'Quantity must be atleast 1',
    'number.max': 'Quantity should not exceed 100',
  }),
  remark: Joi.string().allow(''),
  status: Joi.string().required().messages({
    'string.empty': 'Please select status',
  }),
  newReceePhotos: Joi.array().items(newReceePhotoSchema),
  receePhotos: Joi.array()
    .items(receePhotoSchema)
    // .custom((value, helpers) => {
    //   const {
    //     state: { ancestors },
    //   } = helpers;
    //   const board = ancestors[0];
    //   const receePhotos = board.receePhotos ?? [];
    //   const newReceePhotos = board.newReceePhotos ?? [];
    //   if (receePhotos.length + newReceePhotos.length === 0) {
    //     return helpers.error('any.invalid');
    //   }
    //   return value;
    // })
    .messages({
      'any.invalid': 'Please select atleast two recee photos',
    }),
  deletedReceePhotos: Joi.array().items(receePhotoSchema),
  newInstallationPhotos: Joi.array().items(newInstallationPhotoSchema),
  installationPhotos: Joi.array()
    .items(installationPhotoSchema)
    // .custom((value, helpers) => {
    //   const {
    //     state: { ancestors },
    //   } = helpers;
    //   const board = ancestors[0];
    //   const installationPhotos = board.installationPhotos ?? [];
    //   const newInstallationPhotos = board.newInstallationPhotos ?? [];
    //   if (installationPhotos.length + newInstallationPhotos.length === 0) {
    //     return helpers.error('any.invalid');
    //   }
    //   return value;
    // })
    .messages({
      'any.invalid': 'Please select atleast two installation photos',
    }),
  deletedInstallationPhotos: Joi.array().items(installationPhotoSchema),
}).unknown();
