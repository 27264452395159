import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@material-ui/core";
import toast from "react-hot-toast";
import React, { useCallback } from "react";
import DialogTitle from "src/components/common/Dialog/DialogTitle";
import MaterialTypeService from "src/services/material-type.service";

interface DeleteMaterialTypeDialogProps {
  open: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
  materialTypeId: string;
}

const DeleteMaterialTypeDialog: React.FC<DeleteMaterialTypeDialogProps> = ({
  open,
  onClose,
  onSuccess,
  materialTypeId,
}) => {
  const handleDelete = useCallback(async () => {
    const { message, error } = await MaterialTypeService.deleteMaterialType(
      materialTypeId
    );
    if (error) {
      toast.error(error);
    } else if (message) {
      toast.success(message);
      onSuccess?.();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      PaperProps={{
        className: "w-4/12",
      }}
    >
      <DialogTitle title="Delete material type" onClose={onClose} />
      <DialogContent>
        <Typography>
          Are you user you want to delete the material type? The changes can't
          be reverted back!!
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          Cancel
        </Button>
        <Button color="secondary" onClick={handleDelete}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteMaterialTypeDialog;
