import React from "react";
import { Controller, Control, FieldPath, FieldValues } from "react-hook-form";
import MuiAutocomplete, {
  AutocompleteInputChangeReason,
} from "@material-ui/lab/Autocomplete";
import { Chip, TextField } from "@material-ui/core";
import { FilterOptionsState } from "@material-ui/lab";

export interface AutocompleteControllerProps<
  T extends FieldValues,
  V,
  TName extends FieldPath<T> = FieldPath<T>
> {
  options: V[];
  control: Control<T>;
  error?: boolean;
  helperText?: string;
  getOptionLabel?: (option: V) => string;
  defaultValue?: V | V[];
  label: string;
  className?: string;
  placeholder?: string;
  name: TName;
  id: string;
  getOptionSelected?: (option: V, value: V) => boolean;
  size?: "small" | "medium";
  multiple?: boolean;
  onInputChange?: (
    event: React.ChangeEvent<{}>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => void;
  filterOptions?: (option: V[], value: FilterOptionsState<V>) => V[];
}

function AutocompleteController<T, V>({
  options,
  control,
  error,
  helperText,
  getOptionLabel,
  defaultValue,
  label,
  className,
  placeholder,
  name,
  id,
  getOptionSelected,
  size,
  multiple,
  onInputChange,
  filterOptions,
}: AutocompleteControllerProps<T, V>): JSX.Element {
  return (
    <Controller
      render={({ field: { onChange, ...props } }) => (
        <MuiAutocomplete
          id={id}
          multiple={multiple}
          defaultValue={defaultValue}
          options={options}
          getOptionLabel={getOptionLabel}
          getOptionSelected={getOptionSelected}
          filterOptions={filterOptions}
          onInputChange={onInputChange}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={label}
              className={className}
              placeholder={placeholder}
              error={error}
              helperText={helperText}
              size={size}
            />
          )}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                label={getOptionLabel?.(option)}
                {...getTagProps({ index })}
                size={size}
              />
            ))
          }
          onChange={(event, data) => {
            onChange(data);
          }}
          {...props}
        />
      )}
      control={control}
      name={name}
    />
  );
}

export default AutocompleteController;
