import {
  Avatar,
  Badge,
  Button,
  Card,
  CardActions,
  CardMedia,
} from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { Attachment } from "src/@types/attachment";
import FileSize from "../FileSize";
import cn from "classnames";
import CloseIcon from "@material-ui/icons/Close";

export interface AttachmentCardProps {
  attachment: Attachment;
  onClick?: () => void;
  onDelete?: () => void;
  size?: "small" | "medium" | "large";
  showDelete?: boolean;
}

const AttachmentCard: FunctionComponent<AttachmentCardProps> = ({
  attachment,
  onClick,
  onDelete,
  size = "large",
  showDelete,
}) => {
  if (size !== "large") {
    return (
      <Badge
        color="secondary"
        badgeContent={
          showDelete ? (
            <CloseIcon
              onClick={onDelete}
              className={cn("cursor-pointer", {
                "w-4 h-4": size === "small",
                "w-5 h-5": size === "medium",
              })}
            />
          ) : null
        }
        classes={{
          badge: cn("rounded-full p-2", {
            "w-5 h-5": size === "small",
            "w-7 h-7": size === "medium",
          }),
        }}
      >
        <Avatar
          variant="rounded"
          src={attachment.base64 ?? attachment.url}
          className={cn({
            "w-10 h-10": size === "small",
            "w-20 h-20": size === "medium",
          })}
          onClick={onClick}
        />
      </Badge>
    );
  }

  return (
    <Card onClick={onClick} className="relative">
      <CardMedia
        component="img"
        image={attachment.base64 ?? attachment.url}
        alt="activity"
        className="w-40 h-40 object-cover"
      />

      <CardActions>
        <Button onClick={onDelete}>Delete</Button>
        <FileSize size={attachment.contentSize} />
      </CardActions>
    </Card>
  );
};

export default AttachmentCard;
