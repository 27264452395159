import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Paper,
} from "@material-ui/core";
import { FunctionComponent } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { ActivityBoard, MaterialType } from "src/@types";
import CanView from "src/components/RoleBasedAccess/CanView";
import { DEFAULT_ACTIVITY_BOARD_VALUE } from "src/config/activity-board.constant";
import { ROLE } from "src/enums/role";
import MaterialTypeService from "src/services/material-type.service";
import EditableBoardRow from "./EditableBoardRow";

interface BoardState {
  newBoards: NewBoard[];
  boards: UpdatedBoard[];
  deletedBoards: ActivityBoard[];
}

export interface EditableBoardTableResult {
  newBoards: ActivityBoard[];
  boards: ActivityBoard[];
  deletedBoards: ActivityBoard[];
}
export interface EditableBoardTableProps {
  data: ActivityBoard[];
  onChange?: (data: EditableBoardTableResult) => void;
}

export interface EditableRowState {
  isEditing: boolean;
}

export type NewBoard = ActivityBoard & EditableRowState;

export type UpdatedBoard = ActivityBoard & EditableRowState;

const EditableBoardTable: FunctionComponent<EditableBoardTableProps> = ({
  data = [],
  onChange,
}) => {
  const [boardState, setBoardState] = useState<BoardState>({
    newBoards: [],
    boards: data.map((board) => ({ ...board, isEditing: false })),
    deletedBoards: [],
  });

  const [newBoard, setNewBoard] = useState({ ...DEFAULT_ACTIVITY_BOARD_VALUE });

  const [materials, setMaterials] = useState<MaterialType[]>([]);

  const { newBoards, boards } = boardState;

  const addNewBoard = (board: ActivityBoard) => {
    setBoardState((state) => ({
      ...state,
      newBoards: [...state.newBoards, { ...board, isEditing: false }],
    }));
    setNewBoard({ ...DEFAULT_ACTIVITY_BOARD_VALUE });
  };

  const onNewBoardEditing = (index: number) => {
    setBoardState((state) => {
      const _newBoards = state.newBoards;
      _newBoards[index] = { ..._newBoards[index], isEditing: true };
      return { ...state, newBoards: [..._newBoards] };
    });
  };

  const onNewBoardSave = (board: ActivityBoard, index: number) => {
    setBoardState((state) => {
      const _newBoards = state.newBoards;
      _newBoards[index] = { ...board, isEditing: false };
      return { ...state, newBoards: [..._newBoards] };
    });
  };

  const onNewBoardDelete = (index: number) => {
    setBoardState((state) => {
      const _newBoards = state.newBoards;
      _newBoards.splice(index, 1);
      return { ...state, newBoards: [..._newBoards] };
    });
  };

  const onBoardEditing = (index: number) => {
    setBoardState((state) => {
      const _boards = state.boards;
      _boards[index] = { ..._boards[index], isEditing: true };
      return { ...state, boards: [..._boards] };
    });
  };

  const onBoardSave = (board: ActivityBoard, index: number) => {
    setBoardState((state) => {
      const _boards = state.boards;
      _boards[index] = { ...board, isEditing: false };
      return { ...state, boards: [..._boards] };
    });
  };

  const onBoardDelete = (index: number) => {
    setBoardState((state) => {
      const _boards = state.boards;
      const deletedBoards = _boards.splice(index, 1);
      return {
        ...state,
        boards: [..._boards],
        deletedBoards: [...state.deletedBoards, ...deletedBoards],
      };
    });
  };

  useEffect(() => {
    const fetchAllMaterialTypes = async () => {
      const { materialTypes } = await MaterialTypeService.getAllMaterialType({
        filterType: "all",
      });
      if (materialTypes) {
        setMaterials(materialTypes);
      }
    };
    fetchAllMaterialTypes();
  }, []);

  useEffect(() => {
    onChange?.(boardState);
    // eslint-disable-next-line
  }, [boardState]);

  return (
    <Paper>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell scope="th">Material</TableCell>
              <TableCell scope="th">Width in Inches</TableCell>
              <TableCell scope="th">Height in Inches</TableCell>
              <TableCell scope="th">Quantity</TableCell>
              <TableCell scope="th">Remark</TableCell>
              <TableCell scope="th">Recee photo</TableCell>
              <TableCell scope="th">Installation photo</TableCell>
              <CanView roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER]}>
                <TableCell scope="th">Status</TableCell>
                <TableCell scope="th">Action</TableCell>
              </CanView>
            </TableRow>
          </TableHead>
          <TableBody>
            {boards.map((board, index) => (
              <EditableBoardRow
                key={index}
                mode={board.isEditing ? "edit" : "view"}
                defaultBoardValue={board}
                onEdit={() => onBoardEditing(index)}
                onSave={(updatedBoard) => onBoardSave(updatedBoard, index)}
                onDelete={() => onBoardDelete(index)}
                materials={materials}
              />
            ))}
            {newBoards.map((board, index) => (
              <EditableBoardRow
                key={index}
                mode={board.isEditing ? "edit" : "view"}
                defaultBoardValue={board}
                onEdit={() => onNewBoardEditing(index)}
                onSave={(_board) => onNewBoardSave(_board, index)}
                onDelete={() => onNewBoardDelete(index)}
                materials={materials}
              />
            ))}
            {newBoard && (
              <EditableBoardRow
                mode="create"
                defaultBoardValue={newBoard}
                onSave={(board) => addNewBoard(board)}
                materials={materials}
              />
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default EditableBoardTable;
