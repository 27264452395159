import Joi from 'joi';
import { ackPhotoSchema, newAckPhotoSchema } from './ack-photo.schema';
import { boardSchema } from './board.schema';
import { mobileSchema } from './common';
import { newStorePhotoSchema, storePhotoSchema } from './store-photo.schema';

export const outletSchema = Joi.object({
  outletName: Joi.string().required().messages({
    'string.empty': 'Outlet name is empty',
  }),
  retailerName: Joi.string().required().messages({
    'string.empty': 'Retailer name is required',
  }),
  mobile: mobileSchema,
  // outletType: Joi.object().allow(null),
  // .required().messages({
  //   'object.base': 'Outlet Type is required',
  // }),
  // registeredBy: Joi.string().allow('', null),
  // registrationDate: Joi.date().allow('', null),
  alternateMobile: mobileSchema.allow('', null),
  // isMechanic: Joi.boolean().default(false),
  // hasMobileApp: Joi.boolean(),
  // hasSmartPhone: Joi.boolean(),
  // isBoardAvailable: Joi.boolean(),
  // lastLogin: Joi.date().allow('', null),
  state: Joi.string().allow('', null),
  town: Joi.string().allow('', null),
  pincode: Joi.string()
    .required()
    .pattern(/^[1-9](\d){5,8}$/)
    .messages({
      'string.empty': 'Pincode is required',
      'string.pattern.base': 'Invalid pincode',
    }),
  landmark: Joi.string().allow('', null),
  fullAddress: Joi.string().allow('', null),
}).unknown();

export const activitySchema = Joi.object({
  uploadDate: Joi.date().required(),
  code: Joi.string().required().messages({
    'string.empty': 'Code is required',
  }),
  campaign: Joi.object().required().messages({
    'object.base': 'Please select manager',
  }),
  status: Joi.object().required().messages({
    'object.base': 'Please select status',
  }),
  outlet: outletSchema,
  boards: Joi.array().items(boardSchema),
  // .custom((value, helpers) => {
  //   const {
  //     state: { ancestors },
  //   } = helpers;
  //   const activity = ancestors[0];
  //   const boards = activity.boards ?? [];
  //   const newBoards = activity.newBoards ?? [];
  //   if (boards.length + newBoards.length === 0) {
  //     return helpers.error('any.invalid');
  //   }
  //   return value;
  // })
  // .messages({
  //   'any.invalid': 'Please add atleast one board details',
  // }),
  newBoards: Joi.array().items(boardSchema),
  deletedBoards: Joi.array().items(boardSchema),
  storePhotos: Joi.array().items(storePhotoSchema),
  // .custom((value, helpers) => {
  //   const {
  //     state: { ancestors },
  //   } = helpers;
  //   const activity = ancestors[0];
  //   const storePhotos = activity.storePhotos ?? [];
  //   const newStorePhotos = activity.newStorePhotos ?? [];
  //   const count = storePhotos.length + newStorePhotos.length;
  //   if (count === 0) {
  //     return helpers.error('array.min');
  //   }

  //   if (count > 2) {
  //     return helpers.error('array.max');
  //   }
  //   return value;
  // })
  // .messages({
  //   'array.min': 'Please select atleast 1 store overview photo',
  //   'array.max': 'You can select max 2 store overview photo',
  // }),
  newStorePhotos: Joi.array()
    .items(newStorePhotoSchema)
    .custom((value, helpers) => {
      return value;
    }),
  deletedStorePhotos: Joi.array().items(storePhotoSchema),
  ackPhotos: Joi.array().items(ackPhotoSchema),
  // .custom((value, helpers) => {
  //   const {
  //     state: { ancestors },
  //   } = helpers;
  //   const activity = ancestors[0];
  //   const ackPhotos = activity.ackPhotos ?? [];
  //   const newAckPhotos = activity.newAckPhotos ?? [];
  //   const count = ackPhotos.length + newAckPhotos.length;
  //   if (count === 0) {
  //     return helpers.error('array.min');
  //   }

  //   if (count > 2) {
  //     return helpers.error('array.max');
  //   }
  //   return value;
  // })
  // .messages({
  //   'array.min': 'Please select atleast 1 acknowledgement photo',
  //   'array.max': 'You can select max 2 acknowledgement photo',
  // }),
  newAckPhotos: Joi.array().items(newAckPhotoSchema),
  deletedAckPhotos: Joi.array().items(ackPhotoSchema),
}).unknown();
