import Joi from 'joi';

export const newReceePhotoSchema = Joi.object({
  id: Joi.string().required().messages({
    'string.empty': 'New recee photo id is required',
  }),
  name: Joi.string().required().messages({
    'string.empty': 'New recee photo name is required',
  }),
  contentType: Joi.string().required().messages({
    'string.empty': 'New recee photo content type is required',
  }),
  contentSize: Joi.number().required().messages({
    'any.required': 'New recee photo content size is required',
  }),
  base64: Joi.string().required().messages({
    'string.empty': 'New recee photo image is required',
  }),
}).unknown();

export const receePhotoSchema = Joi.object({
  id: Joi.string().required().messages({
    'string.empty': 'Recee photo id is required',
  }),
  name: Joi.string().allow('', null).messages({
    'string.empty': 'Recee photo name is required',
  }),
  contentType: Joi.string().required().messages({
    'string.empty': 'Recee photo content type is required',
  }),
  contentSize: Joi.number().required().messages({
    'any.required': 'Recee photo content size is required',
  }),
  url: Joi.string().required().messages({
    'string.empty': 'Recee photo image is required',
  }),
}).unknown();
