import { joiResolver } from "@hookform/resolvers/joi";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
} from "@material-ui/core";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Brand } from "src/@types/brand";
import DialogTitle from "src/components/common/Dialog/DialogTitle";
import { brandFormSchema } from "src/validation/brand.schema";

interface BrandFormProps {
  mode: "create" | "edit";
  open: boolean;
  brand: Brand;
  onSave?: (brand: Brand) => void;
  onClose?: () => void;
}

const BrandFormDialog: React.FC<BrandFormProps> = ({
  mode,
  brand,
  onSave,
  open,
  onClose,
}) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<Brand>({
    defaultValues: brand,
    mode: "onSubmit",
    resolver: joiResolver(brandFormSchema),
  });

  const onSubmit = (data: Brand) => {
    onSave?.(data);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      PaperProps={{
        className: "w-4/12",
        component: "form",
        onSubmit: handleSubmit(onSubmit),
      }}
    >
      <DialogTitle
        title={mode === "create" ? "Add Brand" : "Edit Brand"}
        onClose={onClose}
      />
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  label="Name"
                  className="w-full"
                  id="name"
                  error={!!errors.name}
                  helperText={errors.name?.message}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  label="Description"
                  className="w-full"
                  id="description"
                  error={!!errors.description}
                  helperText={errors.description?.message}
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          Cancel
        </Button>
        <Button color="primary" type="submit">
          {mode === "create" ? "Create" : "Save "}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BrandFormDialog;
