import { Card, CardContent, CardHeader, Grid, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { BrandFilterOption } from 'src/@types';
import Breadcrumbs from 'src/components/common/Breadcrumbs';
import { BreadcrumbsLink } from 'src/components/common/Breadcrumbs/Breadcrumbs';
import MainLayout from 'src/components/MainLayout';
import { ROLE } from 'src/enums/role';
import withAuth from 'src/hocs/withAuth';
import withRoles from 'src/hocs/withRoles';
import { TextField } from '@material-ui/core';
import Pagination from 'src/components/common/Pagination';
import { Link } from 'react-router-dom';
import { useCampaignBrands } from 'src/hooks/useCampaignBrands';
import { getCampaignBrandsRoute, getCampaignsRoute } from 'src/helpers/routes';
import Empty from 'src/components/common/Empty';
import LoadingIndicator from 'src/components/common/LoadingIndicator';

const SelectBrandforCampaign = () => {
  const campaignBrandsRoute = getCampaignBrandsRoute();

  const links: BreadcrumbsLink[] = [
    {
      label: 'Dashboard',
      href: '/dashboard',
    },
    {
      label: 'Branding',
      href: campaignBrandsRoute,
    },
    {
      label: 'Campaing Brands',
    },
  ];
  const [filterOption, setFilterOption] = useState<BrandFilterOption>({
    query: '',
    page: 1,
    size: 20,
    filterType: 'query',
  });
  const rowsPerPageOptions = [10, 20, 30, 40, 50];

  const { brands, count, loading } = useCampaignBrands(filterOption);

  const handleBrandSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setFilterOption({ query: value, page: 1, size: 20, filterType: 'query' });
  };

  const handleChangePage = (event: React.ChangeEvent<unknown> | undefined, newPage: number) => {
    setFilterOption((state) => ({ ...state, page: newPage }));
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const size = parseInt(event.target.value as string, 10);
    setFilterOption((state) => ({ ...state, page: 1, size }));
  };

  return (
    <MainLayout>
      <Grid container justifyContent="center" spacing={3}>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={12} sm={8} lg={8}>
              <Breadcrumbs links={links} />
              <Typography variant="h5" color="textPrimary" className="font-bold mt-2">
                Select Brand to view the campaigns
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <TextField
                fullWidth
                variant="outlined"
                label="Search Brand"
                placeholder="Search brand by name"
                onChange={handleBrandSearch}
              />
            </CardContent>
          </Card>
        </Grid>
        <LoadingIndicator isLoading={loading}>
          <Empty isEmpty={count === 0}>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                {brands.map((brand) => (
                  <Grid item xs={12} sm={6} md={3} key={brand.id}>
                    <Link to={getCampaignsRoute({ brandId: brand.id })}>
                      <Card>
                        <CardHeader title={brand.name} />
                        <CardContent>
                          <div className="text-green-600">
                            Total campaigns: {brand.noOfCampaign}
                          </div>
                        </CardContent>
                      </Card>
                    </Link>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {count > 0 && (
                <Pagination
                  count={count}
                  rowsPerPage={filterOption.size}
                  page={filterOption.page}
                  onChange={handleChangePage}
                  rowsPerPageOptions={rowsPerPageOptions}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              )}
            </Grid>
          </Empty>
        </LoadingIndicator>
      </Grid>
    </MainLayout>
  );
};

export default withAuth(
  withRoles(SelectBrandforCampaign, [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER, ROLE.CLIENT]),
  '/login'
);
