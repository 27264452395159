import {
  Paper,
  Table,
  Typography,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  TableContainer,
  Grid,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { Role } from "src/@types/user";
import Breadcrumbs from "src/components/common/Breadcrumbs";
import { BreadcrumbsLink } from "src/components/common/Breadcrumbs/Breadcrumbs";
import MainLayout from "src/components/MainLayout";
import { ROLE } from "src/enums/role";
import withAuth from "src/hocs/withAuth";
import withRoles from "src/hocs/withRoles";
import RoleService from "src/services/role.service";

const links: BreadcrumbsLink[] = [
  {
    label: "Dashboard",
    href: "/dashboard",
  },
  {
    label: "Management",
    href: "/management/roles",
  },
  {
    label: "Roles",
  },
];
const Roles = () => {
  const [roles, setRoles] = useState<Role[]>([]);

  const getAllRole = async () => {
    const { roles } = await RoleService.getAllRole();
    if (roles) {
      setRoles(roles);
    }
  };

  useEffect(() => {
    getAllRole();
  }, []);
  return (
    <MainLayout>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={8} md={6}>
          <Breadcrumbs links={links} />
          <Typography
            variant="h5"
            color="textPrimary"
            className="font-bold mt-2"
          >
            All Roles
          </Typography>
          <Paper className="mt-5" elevation={1}>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Key</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {roles.map((role) => (
                    <TableRow key={role.id}>
                      <TableCell component="th" scope="row">
                        {role.name}
                      </TableCell>
                      <TableCell className="uppercase">{role.id}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default withAuth(
  withRoles(Roles, [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER]),
  "/login"
);
