import { joiResolver } from "@hookform/resolvers/joi";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
} from "@material-ui/core";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { OutletType } from "src/@types";
import DialogTitle from "src/components/common/Dialog/DialogTitle";
import { outletTypeFormSchema } from "src/validation/outlet-type.schema";

interface OutletTypeFormProps {
  mode: "create" | "edit";
  open: boolean;
  outletType: OutletType;
  onSave?: (outletType: OutletType) => void;
  onClose?: () => void;
}

const OutletTypeFormDialog: React.FC<OutletTypeFormProps> = ({
  mode,
  outletType,
  onSave,
  open,
  onClose,
}) => {
  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm<OutletType>({
    defaultValues: outletType,
    mode: "onSubmit",
    resolver: joiResolver(outletTypeFormSchema),
  });

  const onSubmit = (data: OutletType) => {
    onSave?.(data);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      PaperProps={{
        className: "w-4/12",
      }}
    >
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle
          title={
            mode === "create"
              ? "Create outlet type"
              : "Update outlet type"
          }
          onClose={onClose}
        />
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    variant="outlined"
                    label="Name"
                    className="w-full"
                    id="name"
                    error={!!errors.name}
                    helperText={errors.name?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <TextField
                    variant="outlined"
                    label="Description"
                    className="w-full"
                    id="description"
                    error={!!errors.description}
                    helperText={errors.description?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onClose}>
            Cancel
          </Button>
          <Button color="primary" type="submit">
            {mode === "create" ? "Create" : "Save "}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default OutletTypeFormDialog;
