import {
  Button,
  Chip,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { Brand, BrandUser } from "src/@types";
import Breadcrumbs from "src/components/common/Breadcrumbs";
import { BreadcrumbsLink } from "src/components/common/Breadcrumbs/Breadcrumbs";
import Empty from "src/components/common/Empty";
import LoadingIndicator from "src/components/common/LoadingIndicator";
import MainLayout from "src/components/MainLayout";
import { ROLE } from "src/enums/role";
import withAuth from "src/hocs/withAuth";
import withRoles from "src/hocs/withRoles";
import DeleteIcon from "@material-ui/icons/Delete";
import BrandUserService from "src/services/brand-user.service";
import BrandService from "src/services/brand.service";
import CreateBrandUserDialog from "src/containers/BrandUser/CreateBrandUserDialog";
import DeleteBrandUserDialog from "src/containers/BrandUser/DeleteBrandUserDialog";
import { Link, useParams } from "react-router-dom";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CanView from "src/components/RoleBasedAccess/CanView";

const links: BreadcrumbsLink[] = [
  {
    label: "Dashboard",
    href: "/dashboard",
  },
  {
    label: "Management",
    href: "/management/users",
  },
  {
    label: "Brands",
    href: "/management/brands",
  },
];

const BrandUserUsers = () => {
  const { id } = useParams<{ id: string }>();
  const [brandUsers, setBrandUsers] = useState<BrandUser[]>([]);
  const [brand, setBrand] = useState<Brand | null>(null);
  const [loading, setLoading] = useState(false);

  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [userId, setUserId] = useState<string | null>(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const getAllBrandUserByBrandUserId = async () => {
    setLoading(true);
    const { brandUsers = [], error } =
      await BrandUserService.getAllBrandUserByBrandId(id);
    setLoading(false);
    if (error) {
    } else {
      setBrandUsers(brandUsers);
    }
  };

  const handleCreateBrandUserSuccess = useCallback(() => {
    setOpenCreateDialog(false);
    getAllBrandUserByBrandUserId();
    // eslint-disable-next-line
  }, []);

  const handleDeleteClick = useCallback(
    (userId: string) => () => {
      setUserId(userId);
      setOpenDeleteDialog(true);
    },
    // eslint-disable-next-line
    []
  );

  const handleDeleteDialogClose = useCallback(() => {
    setOpenDeleteDialog(false);
    setUserId(null);
    // eslint-disable-next-line
  }, []);

  const handleDeleteBrandUserSuccess = useCallback(() => {
    setOpenDeleteDialog(false);
    setUserId(null);
    getAllBrandUserByBrandUserId();
    // eslint-disable-next-line
  }, []);

  const getBrandById = async () => {
    const { brand, error } = await BrandService.getBrandById(id);
    if (brand) {
      setBrand(brand);
    } else if (error) {
    }
  };

  useEffect(() => {
    getAllBrandUserByBrandUserId();
    getBrandById();
    // eslint-disable-next-line
  }, []);

  return (
    <MainLayout>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Breadcrumbs links={links} />
              <Typography
                variant="h5"
                color="textPrimary"
                className="font-bold mt-2"
              >
                {brand?.name}'s users
              </Typography>
            </Grid>
            <Grid item>
              <CanView roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN]}>
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  onClick={() => setOpenCreateDialog(true)}
                >
                  Add User
                </Button>
              </CanView>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Paper className="mt-5" elevation={1}>
        <LoadingIndicator isLoading={loading} imgClassName="w-4/12">
          <Empty isEmpty={brandUsers.length === 0} imgClassName="w-4/12">
            <TableContainer className="mt-2">
              <Table aria-label="simple table" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Mobile</TableCell>
                    <TableCell>Role</TableCell>
                    <TableCell align="center">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {brandUsers.map((brandUser, index) => (
                    <TableRow key={brandUser.userId}>
                      <TableCell component="th" scope="row">
                        {index + 1}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {brandUser.user?.firstName}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {brandUser.user?.mobile}
                      </TableCell>
                      <TableCell>
                        {brandUser.user?.roles.map((role) => (
                          <Chip
                            label={role.name}
                            key={role.id}
                            size="small"
                            className="mr-2"
                          />
                        ))}
                      </TableCell>
                      <TableCell className="font-bold" align="center">
                        <CanView roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN]}>
                          <IconButton
                            onClick={handleDeleteClick(brandUser.userId)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </CanView>
                        <IconButton
                          component={Link}
                          to={`/management/users/${brandUser.user?.id}`}
                          onClick={(
                            event: React.MouseEvent<
                              HTMLAnchorElement,
                              MouseEvent
                            >
                          ) => event.stopPropagation()}
                        >
                          <ArrowForwardIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Empty>
        </LoadingIndicator>
      </Paper>
      {id && (
        <CreateBrandUserDialog
          open={openCreateDialog}
          onClose={() => setOpenCreateDialog(false)}
          onSuccess={handleCreateBrandUserSuccess}
          brandId={id}
        />
      )}

      {userId && (
        <DeleteBrandUserDialog
          open={openDeleteDialog}
          onClose={handleDeleteDialogClose}
          onSuccess={handleDeleteBrandUserSuccess}
          userId={userId}
          brandId={id}
        />
      )}
    </MainLayout>
  );
};

export default withAuth(
  withRoles(BrandUserUsers, [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER]),
  "/login"
);
