import React from "react";
import { useSelector } from "react-redux";
import MainLayout from "src/components/MainLayout";
import { ROLE } from "src/enums/role";
import withAuth from "src/hocs/withAuth";
import { RootState } from "src/store";
import { AuthState } from "src/store/auth/types";
import { hasRole } from "src/util/role";
import { Redirect } from "react-router-dom";

const Dashboard = () => {
  const { user } = useSelector<RootState, AuthState>(
    (state) => state.authState
  );
  if (user && hasRole(user.roles, [ROLE.SUPER_ADMIN, ROLE.ADMIN])) {
    return <Redirect to="/management/users" />;
  } else if (user && hasRole(user.roles, [ROLE.MANAGER, ROLE.CLIENT])) {
    return <Redirect to="/branding/campaign-brands" />;
  }
  return <MainLayout></MainLayout>;
};

export default withAuth(Dashboard, "/login");
