import toast from "react-hot-toast";
import React, { useCallback, useState } from "react";
import { MaterialType } from "src/@types";
import MaterialTypeService from "src/services/material-type.service";
import MaterialTypeFormDialog from "./MaterialTypeFormDialog";

interface CreateMaterialTypeDialogProps {
  open: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
}

const CreateMaterialTypeDialog: React.FC<CreateMaterialTypeDialogProps> = ({
  open,
  onClose,
  onSuccess,
}) => {
  const [materialType] = useState<MaterialType>({
    id: "",
    name: "",
    description: "",
  });

  const handleSave = useCallback(async (data: MaterialType) => {
    const { message, error } = await MaterialTypeService.createMaterialType(
      data
    );
    if (error) {
      toast.error(error);
    } else if (message) {
      toast.success(message);
      onSuccess?.();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <MaterialTypeFormDialog
      mode="create"
      materialType={materialType}
      open={open}
      onClose={onClose}
      onSave={handleSave}
    />
  );
};

export default CreateMaterialTypeDialog;
