import { OutletType } from "src/@types";
import API from "src/helpers/api";
import { getErrorMessage } from "src/helpers/axios";
import qs from "qs";
import { QueryFilterType } from "src/@types";

interface GeAllOutletTypeOptions {
  page?: number;
  size?: number;
  query?: string;
  filterType: QueryFilterType;
}

interface GetAllOutletTypeResult {
  outletTypes?: OutletType[];
  count?: number;
  error?: string;
}

interface GetAllOutletTypeResponse {
  outletTypes: OutletType[];
  count: number;
}

const getAllOutletType = async ({
  page,
  size,
  query,
  filterType,
}: GeAllOutletTypeOptions) => {
  const result: GetAllOutletTypeResult = {};
  try {
    const queryString = qs.stringify({ page, size, query, filterType });
    const {
      data: { outletTypes, count },
    } = await API.get<GetAllOutletTypeResponse>(
      `/outlet-types?${queryString}`,
      {}
    );
    result.outletTypes = outletTypes;
    result.count = count;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface GetOutletTypeByIdResult {
  outletType?: OutletType;
  error?: string;
}

interface GetOutletTypeByIdResponse {
  outletType: OutletType;
}

const getOutletTypeById = async (outletTypeId: string) => {
  const result: GetOutletTypeByIdResult = {};
  try {
    const {
      data: { outletType },
    } = await API.get<GetOutletTypeByIdResponse>(
      `outlet-types/${outletTypeId}`
    );
    result.outletType = outletType;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface CreateOutletTypeResponse {
  outletType: OutletType;
  message: string;
}
interface CreateOutletTypeResult {
  outletType?: OutletType;
  message?: string;
  error?: string;
}

const createOutletType = async (outletTypeData: OutletType) => {
  const result: CreateOutletTypeResult = {};
  try {
    const {
      data: { outletType, message },
    } = await API.post<CreateOutletTypeResponse>(
      "outlet-types",
      outletTypeData
    );
    result.outletType = outletType;
    result.message = message;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface UpdateOutletTypeResponse {
  outletType: OutletType;
  message: string;
}

interface UpdateOutletTypeResult {
  outletType?: OutletType;
  message?: string;
  error?: string;
}

const updateOutletType = async (outletTypeData: OutletType) => {
  const result: UpdateOutletTypeResult = {};
  try {
    // const outletTypeFormData: OutletTypeFormData = transformToOutletTypeFormData(outletTypeData);
    const {
      data: { outletType, message },
    } = await API.patch<UpdateOutletTypeResponse>(
      `outlet-types/${outletTypeData.id}`,
      outletTypeData
    );
    result.outletType = outletType;
    result.message = message;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface DeleteOutletTypeResult {
  message?: string;
  error?: string;
}
interface DeleteOutletTypeResponse {
  message: string;
}

const deleteOutletType = async (
  id: string
): Promise<DeleteOutletTypeResult> => {
  const result: DeleteOutletTypeResult = {};
  try {
    const {
      data: { message },
    } = await API.delete<DeleteOutletTypeResponse>(
      `outlet-types/${id}`
    );
    result.message = message;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface DeactivateOutletTypeResult {
  message?: string;
  error?: string;
}
interface DeactivateOutletTypeResponse {
  message: string;
}

const deactivateOutletType = async (
  id: string
): Promise<DeactivateOutletTypeResult> => {
  const result: DeleteOutletTypeResult = {};
  try {
    const {
      data: { message },
    } = await API.put<DeactivateOutletTypeResponse>(
      `outlet-types/${id}/deactivate`
    );
    result.message = message;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

interface ActivateOutletTypeResult {
  message?: string;
  error?: string;
}
interface ActivateOutletTypeResponse {
  message: string;
}
const activateOutletType = async (
  id: string
): Promise<ActivateOutletTypeResult> => {
  const result: DeleteOutletTypeResult = {};
  try {
    const {
      data: { message },
    } = await API.put<ActivateOutletTypeResponse>(
      `outlet-types/${id}/activate`
    );
    result.message = message;
  } catch (error) {
    result.error = getErrorMessage(error);
  }
  return result;
};

const OutletTypeService = {
  getAllOutletType,
  getOutletTypeById,
  createOutletType,
  updateOutletType,
  deleteOutletType,
  deactivateOutletType,
  activateOutletType,
};

export default OutletTypeService;
