export function getCampaignBrandsRoute() {
  return "/branding/campaign-brands";
}

export function getCampaignsRoute({ brandId }: { brandId: string }) {
  return `/branding/campaign-brands/${brandId}/campaigns`;
}

export function getActivitiesRoute({
  brandId,
  campaignId,
}: {
  brandId: string;
  campaignId: string;
}) {
  return `/branding/campaign-brands/${brandId}/campaigns/${campaignId}/activities`;
}
