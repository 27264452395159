import React from 'react';
import { Provider } from 'react-redux';
import { initStore } from './store';
import { createTheme, ThemeProvider, StylesProvider, Theme } from '@material-ui/core';
import AppRoutes from './containers/AppRoutes';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { DateUtilProvider } from './context/DateUtilContext';
import { Toaster } from 'react-hot-toast';

const theme: Theme = createTheme({
  palette: {
    primary: {
      main: '#4361ee',
      dark: '#4361ee',
    },
    secondary: {
      main: 'rgb(252, 75, 108)',
    },
    text: {
      primary: '#263238',
      secondary: '#546e7a',
    },
  },
});
function App() {
  const store = initStore();
  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <Toaster />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DateUtilProvider value={{ format: 'dd/MM/yyyy', displayFormat: 'dd MMM yyyy' }}>
              <AppRoutes />
            </DateUtilProvider>
          </MuiPickersUtilsProvider>
        </Provider>
      </ThemeProvider>
    </StylesProvider>
  );
}

export default App;
