import { Grid } from '@material-ui/core';
import React from 'react';
import { Activity } from 'src/@types';
import Acknowledgement from './Acknowledgement';
import ActivityInfo from './ActivityInfo';
import StoreOverview from './StoreOverview';
import BoardDetail from './BoardDetail';
import Location from './Location';
import OutletDetail from './OutletDetail';

interface ActivityDetailProps {
  activity: Activity;
  onRefresh: () => void;
}

const ActivityDetail: React.FC<ActivityDetailProps> = ({ activity, onRefresh }) => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <ActivityInfo activity={activity} />
      </Grid>
      {activity.outlet && (
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <OutletDetail outlet={activity.outlet} />
        </Grid>
      )}
      <Grid item xs={12} sm={12} md={12} lg={6}>
        <Location activity={activity} />
      </Grid>

      <Grid item xs={12}>
        <BoardDetail boards={activity.boards} onRefresh={onRefresh} />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6}>
        <StoreOverview activity={activity} />
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={6}>
        <Acknowledgement activity={activity} />
      </Grid>
    </Grid>
  );
};

export default ActivityDetail;
