import { Button, Typography } from '@material-ui/core';
import React from 'react';
import Download from 'src/components/Images/Download';
import Layout from 'src/components/Layout';
import { MOBILE_APP_URL } from 'src/config/url.constant';

const AppDowload = () => {
  return (
    <Layout>
      <div className="w-full h-full flex flex-col items-center justify-center p-8">
        <Typography variant="h5" className="uppercase font-bold text-center mb-12">Visibility Hub</Typography>
        <Download className="w-full md:w-1/3 h-auto mb-16" />
        <Button component="a" href={MOBILE_APP_URL} variant="contained" color="primary" disableElevation>
          Download App
        </Button>
      </div>
    </Layout>
  );
};

export default AppDowload;
