import {
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import Breadcrumbs, {
  BreadcrumbsLink,
} from "src/components/common/Breadcrumbs/Breadcrumbs";
import Empty from "src/components/common/Empty";
import LoadingIndicator from "src/components/common/LoadingIndicator";
import MainLayout from "src/components/MainLayout";
import { ROLE } from "src/enums/role";
import withAuth from "src/hocs/withAuth";
import withRoles from "src/hocs/withRoles";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import OutletTypeService from "src/services/outlet-type.service";
import { OutletType } from "src/@types";
import CreateOutletTypeDialog from "src/containers/OutletType/CreateOutletTypeDialog";
import EditOutletTypeDialog from "src/containers/OutletType/EditOutletTypeDialog";
import DeleteOutletTypeDialog from "src/containers/OutletType/DeleteOutletTypeDialog";
import { OutletTypeFilterOption } from "src/@types";
import CanView from "src/components/RoleBasedAccess/CanView";

const links: BreadcrumbsLink[] = [
  {
    label: "Dashboard",
    href: "/dashboard",
  },
  {
    label: "Branding",
    href: "/branding/outlet-types",
  },
  {
    label: "Outlet Types",
  },
];

const OutletTypes = () => {
  const [outletTypes, setOutletTypes] = useState<OutletType[]>([]);
  const [loading, setLoading] = useState(false);

  const [openCreateDialog, setOpenCreateDialog] = useState(false);

  const [outletTypeId, setOutletTypeId] = useState<string | null>(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [filterOption, setFilterOption] = useState<OutletTypeFilterOption>({
    query: "",
    page: 1,
    size: 20,
    filterType: "query",
  });
  const rowsPerPageOptions = [10, 20, 30, 40, 50];
  const [outletTypeCount, setOutletTypeCount] = useState(0);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setFilterOption({ ...filterOption, page: newPage + 1 });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFilterOption({
      ...filterOption,
      page: 1,
      size: parseInt(event.target.value, 10),
    });
  };

  const getAllOutletType = async () => {
    setLoading(true);
    const {
      outletTypes = [],
      count = 0,
      error,
    } = await OutletTypeService.getAllOutletType(filterOption);
    setOutletTypeCount(count);
    setLoading(false);
    if (error) {
    } else {
      setOutletTypes(outletTypes);
    }
  };

  const handleCreateBrandinTypeSuccess = useCallback(() => {
    setOpenCreateDialog(false);
    getAllOutletType();
    // eslint-disable-next-line
  }, []);

  const handleEditClick = useCallback(
    (outletTypeId: string) => () => {
      setOutletTypeId(outletTypeId);
      setOpenEditDialog(true);
    },
    // eslint-disable-next-line
    []
  );

  const handleEditDialogClose = useCallback(() => {
    setOpenEditDialog(false);
    setOutletTypeId(null);
    // eslint-disable-next-line
  }, []);

  const handleEditBrandinTypeSuccess = useCallback(() => {
    setOpenEditDialog(false);
    setOutletTypeId(null);
    getAllOutletType();
    // eslint-disable-next-line
  }, []);

  const handleDeleteClick = useCallback(
    (outletTypeId: string) => () => {
      setOutletTypeId(outletTypeId);
      setOpenDeleteDialog(true);
    },
    // eslint-disable-next-line
    []
  );

  const handleDeleteDialogClose = useCallback(() => {
    setOpenDeleteDialog(false);
    setOutletTypeId(null);
    // eslint-disable-next-line
  }, []);

  const handleDeleteBrandinTypeSuccess = useCallback(() => {
    setOpenDeleteDialog(false);
    setOutletTypeId(null);
    getAllOutletType();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getAllOutletType();
    // eslint-disable-next-line
  }, [filterOption]);

  return (
    <MainLayout>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={8} xl={6}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Breadcrumbs links={links} />
              <Typography
                variant="h5"
                color="textPrimary"
                className="font-bold mt-2"
              >
                All Outlet Types
              </Typography>
            </Grid>
            <Grid item>
              <CanView roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN]}>
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  onClick={() => setOpenCreateDialog(true)}
                >
                  New outlet type
                </Button>
              </CanView>
            </Grid>
          </Grid>
          <Paper className="mt-5" elevation={1}>
            <LoadingIndicator isLoading={loading} imgClassName="w-4/12">
              <Empty isEmpty={outletTypes.length === 0} imgClassName="w-4/12">
                <TableContainer className="mt-2">
                  <Table aria-label="simple table" size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Description</TableCell>
                        <CanView roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN]}>
                          <TableCell align="center">Actions</TableCell>
                        </CanView>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {outletTypes.map((outletType, index) => (
                        <TableRow key={outletType.id}>
                          <TableCell component="th" scope="row">
                            {index + 1}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {outletType.name}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {outletType.description}
                          </TableCell>
                          <CanView roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN]}>
                            <TableCell className="font-bold" align="center">
                              <IconButton
                                onClick={handleEditClick(outletType.id)}
                              >
                                <EditIcon />
                              </IconButton>
                              <IconButton
                                onClick={handleDeleteClick(outletType.id)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </CanView>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={rowsPerPageOptions}
                  component="div"
                  count={outletTypeCount}
                  rowsPerPage={filterOption.size}
                  page={filterOption.page - 1}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Empty>
            </LoadingIndicator>
          </Paper>
        </Grid>
      </Grid>
      <CreateOutletTypeDialog
        open={openCreateDialog}
        onClose={() => setOpenCreateDialog(false)}
        onSuccess={handleCreateBrandinTypeSuccess}
      />
      {outletTypeId && (
        <EditOutletTypeDialog
          open={openEditDialog}
          onClose={handleEditDialogClose}
          onSuccess={handleEditBrandinTypeSuccess}
          outletTypeId={outletTypeId}
        />
      )}
      {outletTypeId && (
        <DeleteOutletTypeDialog
          open={openDeleteDialog}
          onClose={handleDeleteDialogClose}
          onSuccess={handleDeleteBrandinTypeSuccess}
          outletTypeId={outletTypeId}
        />
      )}
    </MainLayout>
  );
};

export default withAuth(
  withRoles(OutletTypes, [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER]),
  "/login"
);
