import { Typography } from "@material-ui/core";
import React from "react";
import { FileSizeUnit } from "src/@types";
import { getFileSizeLabel } from "src/helpers/file";

interface FileSizeProps {
  size: number; // file size in bytes
  unit?: FileSizeUnit;
}

const FileSize: React.FunctionComponent<FileSizeProps> = ({
  size,
  unit = "MB",
}) => {
  return (
    <Typography variant="caption">{getFileSizeLabel(size, unit)}</Typography>
  );
};

export default FileSize;
