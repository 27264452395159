import { Box, Typography, Grid, Backdrop } from "@material-ui/core";
import toast from "react-hot-toast";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Activity } from "src/@types";
import Breadcrumbs, {
  BreadcrumbsLink,
} from "src/components/common/Breadcrumbs/Breadcrumbs";
import CircularProgressWithLabel from "src/components/common/Circular";
import MainLayout from "src/components/MainLayout";
import ActivityForm from "src/containers/Activity/ActivityForm";
import { ActivityStatusEnum } from "src/enums/activity-status";
import { ROLE } from "src/enums/role";
import {
  getCampaignBrandsRoute,
  getCampaignsRoute,
  getActivitiesRoute,
} from "src/helpers/routes";
import withAuth from "src/hocs/withAuth";
import withRoles from "src/hocs/withRoles";
import { useCampaign } from "src/hooks/useCampaign";
import ActivityService from "src/services/activity.service";

const defaultActivity: Activity = {
  id: "",
  code: "",
  activityStatusId: ActivityStatusEnum.NEW,
  status: null,
  uploadDate: new Date(),
  campaign: null,
  campaignId: null,
  receeDate: null,
  installationDate: null,
  outlet: {
    id: "",
    activityId: "",
    outletName: "",
    retailerName: "",
    mobile: "",
    outletTypeId: "",
    outletType: null,
    // registeredBy: "",
    // registrationDate: null,
    alternateMobile: "",
    // isMechanic: false,
    // hasMobileApp: false,
    // hasSmartPhone: false,
    // isBoardAvailable: false,
    // lastLogin: null,
    state: "",
    town: "",
    pincode: "",
    landmark: "",
    fullAddress: "",
    lat: 19.013748,
    lng: 72.841928,
  },
  boards: [],
  newBoards: [],
  deletedBoards: [],
  storePhotos: [],
  newStorePhotos: [],
  deletedStorePhotos: [],
  ackPhotos: [],
  newAckPhotos: [],
  deletedAckPhotos: [],
};
const CreateActivity = () => {
  const history = useHistory();
  const { brandId, campaignId } =
    useParams<{ brandId: string; campaignId: string }>();
  const campaignBrandsRoute = getCampaignBrandsRoute();
  const campaignsRoute = getCampaignsRoute({ brandId });
  const activitiesRoute = getActivitiesRoute({ brandId, campaignId });

  const links: BreadcrumbsLink[] = [
    {
      label: "Dashboard",
      href: "/dashboard",
    },
    {
      label: "Branding",
      href: campaignBrandsRoute,
    },
    {
      label: "Campaign Brands",
      href: campaignBrandsRoute,
    },
    {
      label: "Campaigns",
      href: campaignsRoute,
    },
    {
      label: "Activities",
      href: activitiesRoute,
    },
    {
      label: "New Activity",
    },
  ];

  const { campaign } = useCampaign(campaignId);
  const [activity, setActivity] = useState<Activity | null>(null);
  const [progress, setProgress] = useState(0);
  const [saving, setSaving] = useState(false);

  const handleSave = async (data: Activity) => {
    setSaving(true);
    const { activity, error } = await ActivityService.createActivity(
      {
        ...data,
      },
      {
        onUploadProgress: (percent) => {
          setProgress(percent);
        },
      }
    );
    setSaving(false);
    setProgress(0);
    if (error) {
      toast.error(error);
    } else if (activity) {
      history.push(`${activitiesRoute}/${activity.id}`);
    }
  };

  useEffect(() => {
    if (campaign) {
      setActivity({ ...defaultActivity, campaign, campaignId: campaign.id });
    }
  }, [campaign]);

  return (
    <MainLayout>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={12} xl={8}>
          <Breadcrumbs links={links} />
          <Typography
            variant="h5"
            color="textPrimary"
            className="font-bold mt-2"
          >
            Create Activity
          </Typography>
          <Box marginTop={2}>
            {activity && (
              <ActivityForm
                mode="create"
                activity={activity}
                onSave={handleSave}
              />
            )}
          </Box>
        </Grid>
      </Grid>
      <Backdrop open={saving} style={{ zIndex: 2000 }} className="flex-col">
        <CircularProgressWithLabel value={progress} />
        <Typography variant="h6" className="text-white">
          uploading
        </Typography>
      </Backdrop>
    </MainLayout>
  );
};

export default withAuth(
  withRoles(CreateActivity, [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER]),
  "/login"
);
