import React, { FunctionComponent, useState, useEffect } from 'react';
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { Brand, CampaignFormData } from 'src/@types';
import AutocompleteController from 'src/components/common/AutocompleteController';
import { ROLE } from 'src/enums/role';
import BrandService from 'src/services/brand.service';
import { User } from 'src/@types/user';
import UserService from 'src/services/user.service';
import FileUploadButton from 'src/components/common/FileUploadButton';
import { joiResolver } from '@hookform/resolvers/joi';
import { campaignSchema } from 'src/validation/campaign.schema';
import { useDebounce } from 'src/hooks/useDebounce';
import { Alert } from '@material-ui/lab';
import { UPLOAD_EXCEL_DATA_FORMAT } from 'src/config/upload-activity.constant';
import styled from 'styled-components';

const StyledTable = styled(Table)`
  td {
    border: 1px solid rgba(224, 224, 224, 1);
  }
`;
export interface CampaignFormProps {
  mode: 'create' | 'edit';
  campaign: CampaignFormData;
  onSave?: (data: CampaignFormData) => void;
  saving?: boolean;
}

const CampaignForm: FunctionComponent<CampaignFormProps> = ({ mode, campaign, onSave, saving }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm<CampaignFormData>({
    defaultValues: campaign,
    resolver: joiResolver(campaignSchema),
  });

  const [brands, setBrands] = useState<Brand[]>([]);
  const [managers, setManagers] = useState<User[]>([]);

  const [openHint, setOpenHint] = useState(false);

  const file = watch('file', null);

  const getAllBrand = async (query: string = '') => {
    const { brands } = await BrandService.getAllBrand({
      filterType: 'all',
      page: 1,
      size: 25,
      query,
    });

    if (brands) {
      setBrands(brands);
    }
  };

  const getAllManagers = async (query: string = '') => {
    const { users } = await UserService.getAllUser({
      page: 1,
      size: 25,
      query,
      roles: [ROLE.MANAGER],
    });

    if (users) {
      setManagers(users);
    }
  };

  useEffect(() => {
    getAllBrand();
    getAllManagers();
  }, []);

  const handleBrandInputChange = useDebounce((event: React.ChangeEvent<{}>, value: string) => {
    getAllBrand(value);
  });

  const handleManagerInputChange = useDebounce((event: React.ChangeEvent<{}>, value: string) => {
    getAllManagers(value);
  });

  const onSubmit = handleSubmit(async (data) => {
    onSave?.(data);
  });

  console.log(errors);
  return (
    <Card component="form" onSubmit={onSubmit}>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  label="Name"
                  className="w-full"
                  id="name"
                  error={!!errors.name}
                  helperText={errors.name?.message}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  label="Description"
                  className="w-full"
                  id="description"
                  error={!!errors.description}
                  helperText={errors.description?.message}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <AutocompleteController
              options={brands}
              getOptionLabel={(brand) => brand.name}
              getOptionSelected={(option, value) => option.id === value.id}
              label="Brand"
              placeholder="Brand"
              className="w-full"
              control={control}
              name="brand"
              id="brand"
              onInputChange={handleBrandInputChange}
              error={!!errors.brand}
              helperText={(errors?.brand as FieldError | undefined)?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <AutocompleteController
              options={managers}
              getOptionLabel={(manager) => manager.firstName}
              getOptionSelected={(option, value) => option.id === value.id}
              label="Manager"
              placeholder="Manager"
              className="w-full"
              control={control}
              name="manager"
              id="manager"
              onInputChange={handleManagerInputChange}
              error={!!errors.brand}
              helperText={(errors?.manager as FieldError | undefined)?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <Alert severity="info" className="mb-3">
              To see the valid data format for the upload excel. Please{' '}
              <Link component="button" type="button" onClick={(event) => setOpenHint(true)}>
                click here
              </Link>
            </Alert>
            {file && (
              <div className="mb-2">
                <div className="font-medium mb-2">Selected file</div>
                <div>{file.name}</div>
              </div>
            )}
            <Controller
              control={control}
              name="file"
              render={({ field }) => (
                <FileUploadButton
                  variant="contained"
                  disableElevation
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  id="file"
                  onChange={(event) => {
                    setValue('file', event.target.files?.[0] ?? null);
                  }}
                  onBlur={field.onBlur}
                  name={field.name}
                  ref={field.ref}
                >
                  Browse file
                </FileUploadButton>
              )}
            />
            <FormHelperText error={!!errors?.file}>{errors?.file?.message}</FormHelperText>
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={saving}
              disableElevation
            >
              Upload
            </Button>
          </Grid>
        </Grid>
        <Dialog onClose={() => setOpenHint(false)} open={openHint} maxWidth="md">
          <DialogTitle id="simple-dialog-title">Data format</DialogTitle>
          <DialogContent>
            <Paper>
              <StyledTable size="small">
                <TableHead>
                  <TableRow>
                    <TableCell scope="th">Field</TableCell>
                    <TableCell scope="th">Optional</TableCell>
                    <TableCell scope="th">Value</TableCell>
                    <TableCell scope="th">Example</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {UPLOAD_EXCEL_DATA_FORMAT.map((data) => (
                    <TableRow key={data.field}>
                      <TableCell>{data.field}</TableCell>
                      <TableCell>{data.optional ? 'Yes' : 'No'}</TableCell>
                      <TableCell>{data.value}</TableCell>
                      <TableCell>{data.example}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </StyledTable>
            </Paper>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenHint(false)} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </CardContent>
    </Card>
  );
};

export default CampaignForm;
