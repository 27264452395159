import toast from "react-hot-toast";
import React, { useCallback, useEffect, useState } from "react";
import { MaterialType } from "src/@types";
import MaterialTypeService from "src/services/material-type.service";
import MaterialTypeFormDialog from "./MaterialTypeFormDialog";

interface EditMaterialTypeDialogProps {
  open: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
  materialTypeId: string;
}

const EditMaterialTypeDialog: React.FC<EditMaterialTypeDialogProps> = ({
  open,
  onClose,
  onSuccess,
  materialTypeId,
}) => {
  const [materialType, setMaterialType] = useState<MaterialType | null>(null);

  const handleSave = useCallback(async (data: MaterialType) => {
    const { message, error } = await MaterialTypeService.updateMaterialType({
      ...data,
      id: materialTypeId,
    });
    if (error) {
      toast.error(error);
    } else if (message) {
      toast.success(message);
      onSuccess?.();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const getMaterialTypeById = async () => {
      const { materialType } = await MaterialTypeService.getMaterialTypeById(
        materialTypeId
      );
      if (materialType) {
        setMaterialType(materialType);
      }
    };
    getMaterialTypeById();
    // eslint-disable-next-line
  }, [materialTypeId]);

  return (
    <>
      {materialType && (
        <MaterialTypeFormDialog
          mode="edit"
          materialType={materialType}
          open={open}
          onClose={onClose}
          onSave={handleSave}
        />
      )}
    </>
  );
};

export default EditMaterialTypeDialog;
