import qs from 'qs';

// Launch google map and point a location
// https://developers.google.com/maps/documentation/urls/get-started
//https://stackoverflow.com/questions/18739436/how-to-create-a-link-for-all-mobile-devices-that-opens-google-maps-with-a-route

export function createGoogleMapLink(lat: number, lng: number) {
  // return `https://www.google.com/maps/@${lat},${lng},15z`;
  const quertString = qs.stringify({
    api: 1,
    travelmode: 'driving',
    layer: 'traffic',
    destination: `${lat},${lng}`,
  });
  return `https://www.google.com/maps/dir/?${quertString}`;
}
