import {
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import Breadcrumbs, {
  BreadcrumbsLink,
} from "src/components/common/Breadcrumbs/Breadcrumbs";
import Empty from "src/components/common/Empty";
import LoadingIndicator from "src/components/common/LoadingIndicator";
import MainLayout from "src/components/MainLayout";
import { ROLE } from "src/enums/role";
import withAuth from "src/hocs/withAuth";
import withRoles from "src/hocs/withRoles";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import MaterialTypeService from "src/services/material-type.service";
import { MaterialType } from "src/@types";
import CreateMaterialTypeDialog from "src/containers/MaterialType/CreateMaterialTypeDialog";
import EditMaterialTypeDialog from "src/containers/MaterialType/EditMaterialTypeDialog";
import DeleteMaterialTypeDialog from "src/containers/MaterialType/DeleteMaterialTypeDialog";
import { MaterialTypeFilterOption } from "src/@types";
import CanView from "src/components/RoleBasedAccess/CanView";

const links: BreadcrumbsLink[] = [
  {
    label: "Dashboard",
    href: "/dashboard",
  },
  {
    label: "Branding",
    href: "/branding/material-types",
  },
  {
    label: "Material Types",
  },
];

const MaterialTypes = () => {
  const [materialTypes, setMaterialTypes] = useState<MaterialType[]>([]);
  const [loading, setLoading] = useState(false);

  const [openCreateDialog, setOpenCreateDialog] = useState(false);

  const [materialTypeId, setMaterialTypeId] = useState<string | null>(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [filterOption, setFilterOption] = useState<MaterialTypeFilterOption>({
    query: "",
    page: 1,
    size: 20,
    filterType: "query",
  });
  const rowsPerPageOptions = [10, 20, 30, 40, 50];
  const [materialTypeCount, setMaterialTypeCount] = useState(0);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setFilterOption({ ...filterOption, page: newPage + 1 });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFilterOption({
      ...filterOption,
      page: 1,
      size: parseInt(event.target.value, 10),
    });
  };

  const getAllMaterialType = async () => {
    setLoading(true);
    const {
      materialTypes = [],
      count = 0,
      error,
    } = await MaterialTypeService.getAllMaterialType(filterOption);
    setMaterialTypeCount(count);
    setLoading(false);
    if (error) {
    } else {
      setMaterialTypes(materialTypes);
    }
  };

  const handleCreateBrandinTypeSuccess = useCallback(() => {
    setOpenCreateDialog(false);
    getAllMaterialType();
    // eslint-disable-next-line
  }, []);

  const handleEditClick = useCallback(
    (materialTypeId: string) => () => {
      setMaterialTypeId(materialTypeId);
      setOpenEditDialog(true);
    },
    // eslint-disable-next-line
    []
  );

  const handleEditDialogClose = useCallback(() => {
    setOpenEditDialog(false);
    setMaterialTypeId(null);
    // eslint-disable-next-line
  }, []);

  const handleEditBrandinTypeSuccess = useCallback(() => {
    setOpenEditDialog(false);
    setMaterialTypeId(null);
    getAllMaterialType();
    // eslint-disable-next-line
  }, []);

  const handleDeleteClick = useCallback(
    (materialTypeId: string) => () => {
      setMaterialTypeId(materialTypeId);
      setOpenDeleteDialog(true);
    },
    // eslint-disable-next-line
    []
  );

  const handleDeleteDialogClose = useCallback(() => {
    setOpenDeleteDialog(false);
    setMaterialTypeId(null);
    // eslint-disable-next-line
  }, []);

  const handleDeleteBrandinTypeSuccess = useCallback(() => {
    setOpenDeleteDialog(false);
    setMaterialTypeId(null);
    getAllMaterialType();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getAllMaterialType();
    // eslint-disable-next-line
  }, [filterOption]);

  return (
    <MainLayout>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={8} xl={6}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Breadcrumbs links={links} />
              <Typography
                variant="h5"
                color="textPrimary"
                className="font-bold mt-2"
              >
                All Material Types
              </Typography>
            </Grid>
            <Grid item>
              <CanView roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN]}>
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  onClick={() => setOpenCreateDialog(true)}
                >
                  New material type
                </Button>
              </CanView>
            </Grid>
          </Grid>
          <Paper className="mt-5" elevation={1}>
            <LoadingIndicator isLoading={loading} imgClassName="w-4/12">
              <Empty isEmpty={materialTypes.length === 0} imgClassName="w-4/12">
                <TableContainer className="mt-2">
                  <Table aria-label="simple table" size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Description</TableCell>
                        <CanView roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN]}>
                          <TableCell align="center">Actions</TableCell>
                        </CanView>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {materialTypes.map((materialType, index) => (
                        <TableRow key={materialType.id}>
                          <TableCell component="th" scope="row">
                            {index + 1}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {materialType.name}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {materialType.description}
                          </TableCell>
                          <CanView roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN]}>
                            <TableCell className="font-bold" align="center">
                              <IconButton
                                onClick={handleEditClick(materialType.id)}
                              >
                                <EditIcon />
                              </IconButton>
                              <IconButton
                                onClick={handleDeleteClick(materialType.id)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </CanView>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={rowsPerPageOptions}
                  component="div"
                  count={materialTypeCount}
                  rowsPerPage={filterOption.size}
                  page={filterOption.page - 1}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Empty>
            </LoadingIndicator>
          </Paper>
        </Grid>
      </Grid>
      <CreateMaterialTypeDialog
        open={openCreateDialog}
        onClose={() => setOpenCreateDialog(false)}
        onSuccess={handleCreateBrandinTypeSuccess}
      />
      {materialTypeId && (
        <EditMaterialTypeDialog
          open={openEditDialog}
          onClose={handleEditDialogClose}
          onSuccess={handleEditBrandinTypeSuccess}
          materialTypeId={materialTypeId}
        />
      )}
      {materialTypeId && (
        <DeleteMaterialTypeDialog
          open={openDeleteDialog}
          onClose={handleDeleteDialogClose}
          onSuccess={handleDeleteBrandinTypeSuccess}
          materialTypeId={materialTypeId}
        />
      )}
    </MainLayout>
  );
};

export default withAuth(
  withRoles(MaterialTypes, [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER]),
  "/login"
);
