import {
  Card,
  CardHeader,
  Typography,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import React from "react";
import { Activity } from "src/@types";
import DisplayDate from "src/components/DisplayDate";
import CanView from "src/components/RoleBasedAccess/CanView";
import { ROLE } from "src/enums/role";
import { formatDateTime } from "src/util/datetime";

interface ActivityInfoProps {
  activity: Activity;
}
const ActivityInfo: React.FunctionComponent<ActivityInfoProps> = ({
  activity,
}) => {
  return (
    <Card>
      <CardHeader title={<Typography variant="h6">Activity Info</Typography>} />
      <Divider />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell className="font-medium capitalize">Campaign</TableCell>
            <TableCell className="capitalize">
              {activity.campaign?.name}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="font-medium capitalize">
              Upload Date
            </TableCell>
            <TableCell className="capitalize cursor-pointer" colSpan={2}>
              {activity.uploadDate && (
                <Tooltip
                  title={formatDateTime(activity.uploadDate)}
                  placement="top"
                >
                  <span>
                    <DisplayDate date={activity.uploadDate} />
                  </span>
                </Tooltip>
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="font-medium capitalize">
              Activity code
            </TableCell>
            <TableCell className="capitalize" colSpan={2}>
              {activity.code}
            </TableCell>
          </TableRow>

          <CanView roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER]}>
            <TableRow>
              <TableCell className="font-medium capitalize">Status</TableCell>
              <TableCell className="capitalize" colSpan={2}>
                {activity.status?.name}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="font-medium capitalize">Brand</TableCell>
              <TableCell className="capitalize" colSpan={2}>
                {activity.campaign?.brand?.name}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="font-medium capitalize">Manager</TableCell>
              <TableCell className="capitalize" colSpan={2}>
                {activity.campaign?.manager?.firstName}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="font-medium capitalize">
                Recee done by promoter
              </TableCell>
              <TableCell className="capitalize" colSpan={2}>
                {activity.receePromoter?.firstName}
              </TableCell>
            </TableRow>
          </CanView>
          <TableRow>
            <TableCell className="font-medium capitalize">Recee Date</TableCell>
            <TableCell className="capitalize cursor-pointer" colSpan={2}>
              {activity.receeDate && (
                <Tooltip
                  title={formatDateTime(activity.receeDate)}
                  placement="top"
                >
                  <span>
                    <DisplayDate date={activity.receeDate} />
                  </span>
                </Tooltip>
              )}
            </TableCell>
          </TableRow>
          <CanView roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MANAGER]}>
            <TableRow>
              <TableCell className="font-medium capitalize">
                Installation done by promoter
              </TableCell>
              <TableCell className="capitalize" colSpan={2}>
                {activity.installationPromoter?.firstName}
              </TableCell>
            </TableRow>
          </CanView>
          <TableRow>
            <TableCell className="font-medium capitalize">
              Installation Date
            </TableCell>
            <TableCell className="capitalize cursor-pointer" colSpan={2}>
              {activity.installationDate && (
                <Tooltip
                  title={formatDateTime(activity.installationDate)}
                  placement="top"
                >
                  <span>
                    <DisplayDate date={activity.installationDate} />
                  </span>
                </Tooltip>
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Card>
  );
};

export default ActivityInfo;
